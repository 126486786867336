import React from 'react'
import Desktop from '../Desktop';
export const DesktopContainer = () => {
  if (window.innerWidth>600)
  {
    window.location.href='/Desktop';
  }
  return (
      <div style={{ marginLeft: '10vw', marginTop: '5vh' }} className='desktop-container'>
          <h1>This site is only optimized for mobile viewing</h1>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <img src='/assets/gemqr.png' alt='gem qr' style={{ width: '500px', height: '450px' }} />
              <h2 style={{ marginLeft: '10px' }}>Scan here to start collecting points</h2>
          </div>
      </div>
  )
}
