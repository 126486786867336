import { createTheme } from '@mui/material/styles';

export function getTheme(){
        const theme = createTheme({
            palette: {
            button: {
                light: '#50abcb',
                main: '#899DD6',
                dark: '#196985',
                contrastText: '#fff',
            }
            },
        });
        return theme;
}
