import { DirectionsRenderer, GoogleMap, useJsApiLoader ,Marker } from '@react-google-maps/api';
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import React, { useLayoutEffect,useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import getUrl from './LambdaUrls';
import { BackButton } from './components/Buttons/BackButton';
import { RankButton } from "./components/Buttons/RankButton";
import { FeedbackButton } from './components/Buttons/FeedbackButton';
import Scoreboard from './components/Scoreboard';
/*global google*/
import './map.css';

const containerStyle = {
  width: '100vw',
  height: '90vh'
};


function MapAll() {
  const navigate = useNavigate();
  const { placeID } = useParams();
  const [places, setPlaces] = useState(0);
  const [cplace, setPlace] = useState(0);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDjbVgoqH2yW8dWMRW3GOli40Kyiyyc54o"
  })
  const [map, setMap] = React.useState(null);
  const [pos, setPos] = React.useState(null);
  const [directions, setDirections] = React.useState(null);
  const [gps, setGPS] = React.useState(false);


  const myNavigate = (lat, lng,pos) => {
	console.log('navigate to',lat,lng,pos);
    let travelMode = localStorage.getItem('TRANSIT');
    if (!travelMode) { travelMode = google.maps.TravelMode.DRIVING; localStorage.setItem('TRANSIT', travelMode); }
    const service = new google.maps.DirectionsService();
    service.route(
      { destination: { lat: +lat, lng: +lng }, origin: { lat: +pos.lat, lng: +pos.lng }, travelMode: travelMode },
      (result, status) => {
        console.log('route', status, result);
        if (status==='OK' && result) setDirections(result);
      }
    );
  }

  const fetchData = (map,pos) => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
		};
    fetch(getUrl("getAllPlaces"), requestOptions)
      .then(response => response.text())
      .then(result => { 
		    const places=JSON.parse(result);
		    setPlaces(places);console.log('places',pos,places);


		const markers = places.map(place => {

		const marker = new google.maps.Marker({
				position: {lat:+place.latitude, lng: +place.longitude},
				map,
				icon : {url:'https://cluez.ca/assets/logo3blue.png',scaledSize: new google.maps.Size(60, 60),anchor: new google.maps.Point(30, 30)},
				label: '', });	
		
		marker.addListener("click", () => {
      const encodePlaceName = (place.placename).replace(/['"?]+/g, '');
		    const infowindow = new google.maps.InfoWindow({
			content: `<h3>${place.placename}</h3>${place.address}<p>
				<p>${place.pdescription}</p><p>${place.suggestion}</p>
				<button 
				 onClick='window.location.href="/map/${encodePlaceName}/${place.placeid}"'
				className='transportation-blue'>Navigate</button></p>`,
			}).open({anchor:marker,map});
			
			//myNavigate(place.latitude,place.longitude,pos);
		});
		return marker;
	  });

	  // Add a marker clusterer to manage the markers.
	  const markerCluster = new MarkerClusterer({ map, markers });
	  console.log('cluster', markerCluster,markers);
	

			
		})
      .catch(error => console.log('error', error));

	
  }



  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
	console.log('map',map);	
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  navigator.geolocation.getCurrentPosition(function (position) {
    //console.log('getCurrentPosition', position);
    setPos({lat: +position.coords.latitude, lng: +position.coords.longitude});
	if (!gps && map!==null) {
		map.panTo( new google.maps.LatLng({lat: +position.coords.latitude, lng: +position.coords.longitude}) );
		map.setZoom(12);
		setGPS(true);
		console.log('GPS true',position);
		fetchData(map,{lat: +position.coords.latitude, lng: +position.coords.longitude});
    new google.maps.Marker({
      position: { lat: +position.coords.latitude, lng: +position.coords.longitude },
      map,
      icon: { url: '/assets/duck.gif', scaledSize: new google.maps.Size(90, 90), anchor: new google.maps.Point(45, 45) },
      label: '',
    });  
	}
    	
  });
  //const DirectionsService = new google.maps.DirectionsService();

  return isLoaded ? (
    <div>
      <div className="newsfeed-TG">
        <BackButton onClick={() => navigate(-1)} />
        <Scoreboard onClick={() => navigate('/redeem')} />
        <FeedbackButton onClick={() => navigate('/newsfeed')} />
      </div>

      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={17}
        onLoad={onLoad}
        onUnmount={onUnmount}
        //center = {{ lat: +pos.coords.latitude, lng: +pos.coords.longitude }}
        options={{ disableDefaultUI: true, clickableIcons: true }}
      >

        { /* Child components, such as markers, info windows, etc. */}
        { /* directions && <DirectionsRenderer directions={directions} options={{suppressMarkers: true,suppressBicyclingLayer:true}} /> */}

	{/*<Marker position={{lat:+cplace.latitude, lng:+cplace.longitude}} label={''} opacity={0.92} icon={{url:'https://cluez.ca/assets/logo3blue.png',scaledSize: new google.maps.Size(60, 60),anchor: new google.maps.Point(30, 30)}}	  		/>  */}

      </GoogleMap>


    </div>
  ) : <></>
}
export default React.memo(MapAll)
