import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { DesktopContainer } from './components/DesktopContainer';
import { BackButton } from './components/Buttons/BackButton';
import './eventpage.css'
import getUrl from './LambdaUrls';

function EventPage() {
    const [events, setEvents] = useState([]);
    let navigate = useNavigate();

    useEffect(() => {
        fetchEvents();
        return () => {
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to the top of the page when the component is mounted
    }, []);

    const fetchEvents = () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
        };

        fetch(getUrl('events'), requestOptions)
            .then((response) => response.json())
            .then((result) => {
                const sortedEvents = result.sort((a, b) => {
                    const dateA = new Date(`${a[2]}T${a[3]}`);
                    const dateB = new Date(`${b[2]}T${b[3]}`);
                    return dateA - dateB;
                });
                setEvents(sortedEvents);
            })
            .catch((error) => console.log('error', error));
    };

    // console.log(events);

    const { eventName, eventDate } = useParams();
    console.log('EVENTNAME', String(eventName).trim());

    // Find the event with the matching eventName
    const event = events.find((event) => String(event[0]).trim() === String(eventName).trim());

    if (!event) {
        return (
            <div>
            </div>
        );
    }

    const startTime = event[3];
    const endTime = event[4];
    const eventDetails = event[5];
    const eventPlace = event[1];
    const directionLink = event[7];
    const buyLink = event[8];
    const eventType = event[9];

    const handleBuyButtonClick = () => {
        if (buyLink) {
            if (eventType === 'Free' || eventType === 'Performer') {
                window.open(buyLink, '_blank');
            } else if (eventType === 'Pay') {
                window.open(buyLink, '_blank');
            }
        }
    };

    const handleDirectionButtonClick = () => {
        if (directionLink) {
            window.open(directionLink, '_blank');
        }
    };

    const convertTo12HourFormat = (time) => {
        const [hours, minutes] = time.split(':');
        let formattedTime = '';
        let period = '';

        if (parseInt(hours) === 12) {
            formattedTime = hours;
            period = 'PM';
        } else if (parseInt(hours) > 12) {
            formattedTime = (parseInt(hours) - 12).toString();
            period = 'PM';
        } else {
            formattedTime = hours;
            period = 'AM';
        }

        return `${formattedTime}:${minutes} ${period}`;
    };

    // Helper function to check if the event name is longer than three words
    const isLongEventName = (eventName) => {
        const words = eventName.split(' ');
        return words.length > 3;
    };

    return (
        <div>
            <DesktopContainer />
            <div className='mobile-container event-page'>
                <BackButton onClick={() => navigate("/events")} style={{ marginTop: '37px', marginLeft: '19px' }} />
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {/* <div className='questmode'>
                        <ListItemButton
                            className="list-item-btn"
                            sx={{
                                marginTop: '15px',
                                marginBottom: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '10px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                textAlign: 'center',
                                color: 'black',
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                marginTop: '25%',
                                // boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                border: 'solid black 1px',
                                transition: 'transform 0.3s ease',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                },
                            }}
                            onClick={() => window.location.href = 'https://hiddengemz.ca/products/meet-up-with-hidden-gemz-activities-food-and-drink-covered-18-25-yr'}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div>AUG 12</div>
                                <div>QUEST MODE</div>
                                <div>$30</div>
                            </div>
                        </ListItemButton>
                    </div> */}
                    <div className='main-body' >
                        {/* Add word-wrap: break-word, max-width, and marginLeft for long event names */}
                        <h2 className='event-name-EP'>{eventName}</h2>
                        <h3 className='event-place-EP'>{eventPlace}</h3>
                        {/* Update the style of the <p> element */}
                        <div className='icon-container-EP' style={{
                            backgroundColor: '#EB6660',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginLeft: '1vw',
                            boxShadow: '0px 6px 4px rgba(186, 128, 33, 0.4), 0px -5px 0px rgba(0, 0, 0, 0.2) inset',
                            borderRadius: '10px',
                            cursor: 'pointer',
                            border: '3px solid white',
                            marginTop: '-2vh'
                        }}>
                            <CalendarMonthIcon style={{ transform: 'scale(1.4)', color: 'white' }} />
                        </div>
                        <p style={{ width: '80vw', maxHeight: '60vh', paddingTop: '5px', paddingBottom: '15%' }}>{eventDetails}</p>
                        <div className='date-time-container'>
                            <div style={{ marginBottom: '10px' }}>
                                <span className='date-EP' style={{ fontWeight: '400' }}>Date: </span>
                                <span className='date-EP-date'>{eventDate}</span>
                            </div>
                            <div>
                                <span className='time-EP' style={{fontWeight:'400' }}>Time: </span>
                                <span className='time-EP-time'>{convertTo12HourFormat(startTime)} - {convertTo12HourFormat(endTime)}</span>
                            </div>
                        </div>
                        <div class="button-container">
                            {eventType === 'Free' || eventType === 'Performer' ? (
                                <button onClick={handleBuyButtonClick} class="website-button">Website</button>
                            ) : eventType === 'Pay' ? (
                                    <button onClick={handleBuyButtonClick} class="buy-button">Buy</button>
                            ) : null}
                            <button onClick={handleDirectionButtonClick} class="direction-button">Direction</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );

}

export default EventPage;
