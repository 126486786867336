import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CloseButton } from "./Buttons/CloseButton";
import { HamburgerButton } from './Buttons/HamburgerButton';
import './Navbar.css';
import SignInNavbar from './SignIn-Navbar';
import getUrl from '../LambdaUrls';

const Navbar = ({ onSignIn }) => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [admin, setAdmin] = useState(false);
    const navigate = useNavigate();

    //scoreboard
    const [score, setScore] = useState(0);
    const [isUserSignedIn, setIsUserSignedIn] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/plain");

        var raw = "{\r\n  \"email\": \"" + String(localStorage.getItem('email')) + "\"\r\n}";

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        var Userscore = 0
        fetch(getUrl("postScore"), requestOptions)
            .then(response => response.text())
            .then(result => setScore(JSON.parse(result)[0][0]))
            .catch(error => console.log('error', error));
        checkUserSignedIn();
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    //______________

    useEffect(() => {
        checkUserSignedIn();
        checkUser();
    }, []);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    const checkUser = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const allowedEmails = [
                'thydoraemon13@gmail.com',
                'ranmac3@hotmail.com',
                'goldrunnercode123@gmail.com',
                'hieuvpn@gmail.com'
            ];
            if (allowedEmails.includes(user.attributes.email)) {
                setAdmin(true);
            } else {
                setAdmin(false);
            }
        } catch (error) {
            setAdmin(false);
        }
    };
    const handleSignInClick = () => {
        setMenuOpen(false);
        navigate('/signin');
    };
    // const handleBucketClick = () => {
    //     setMenuOpen(false);
    //     navigate('/bucket');
    // };

    // const handleApprovalPlacesClick = () => {
    //     setMenuOpen(false);
    //     navigate('/places-app');
    // };

    // const handleUserSuggestedPlaceClick = () => {
    //     setMenuOpen(false);
    //     navigate('/submit-location');
    // };

    const checkUserSignedIn = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            console.log('User:', user);
            setUserEmail(user.attributes.email);
            setIsUserSignedIn(true);
        } catch (error) {
            console.log('Error:', error);
            setIsUserSignedIn(false);
        }
    };

    //sign out
    const [error, setError] = useState('');

    const handleSignOut = async () => {
        try {
            await Auth.signOut();
            setIsUserSignedIn(false);
            localStorage.clear();
            window.location.href = '/';
        } catch (error) {
            setError(error.message);
        }
    };

    // console.log('isUserSignedIn:', isUserSignedIn);
    // console.log('userEmail:', userEmail);

    const handleUserButtonClick = () => {
        if (isUserSignedIn) {
            navigate('/user');
        } else {
            navigate('/signin')
        }
    };



    return (
        <nav className={`navbar ${isMenuOpen ? 'open' : ''}`}>
            <img src="../assets/logo2.png" alt="logo" className='logo-HP' style={{ width: '15vw' }} />
            <h2 className='logo-name'>Hidden Gemz</h2>
            <div className="navbar__right">
                {isMenuOpen ? (
                    <CloseButton
                        className="navbar__close-icon navbar__icon"
                        onClick={toggleMenu}
                    />
                ) : (
                    <HamburgerButton
                        className="navbar__menu-icon navbar__icon"
                        onClick={toggleMenu}
                    />
                )}
                <div
                    className={`navbar__overlay ${isMenuOpen ? 'open' : ''}`}
                    onClick={toggleMenu}
                ></div>
                <ul className={`navbar__links ${isMenuOpen ? 'open' : ''}`}>
                    <li className="navbar__link navbar__button-container">
                        <div>
                            {/* {admin && <button className="navbar__button" onClick={handleBucketClick}>Approval Pictures</button>}
                            {admin && <button className="navbar__button" onClick={handleApprovalPlacesClick}>Approval Places</button>} */}
                            {/* {admin && <button className="navbar__button" onClick={handleUserSuggestedPlaceClick}>User Suggested Locations</button>} */}
                        </div>
                    </li>
                    {!isUserSignedIn ? (
                        <SignInNavbar onSignIn={checkUserSignedIn} />
                    ) : (
                        <>
                            <li className="navbar__link navbar__button-container">
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    {userEmail && (
                                        <li className="navbar__link users-info">
                                            Signed in as: <br />
                                            <span
                                                onClick={handleUserButtonClick}
                                                style={{ textDecoration: 'underline', fontWeight: '300' }}
                                            >
                                                {userEmail}
                                            </span>
                                        </li>
                                    )}
                                    <div style={{ width: '28vw' }}></div>
                                </div>
                            </li>
                            <li className="navbar__link navbar__button-container">
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '-2vh' }}>
                                    {userEmail && <div className='score-navbar'>Score: {score.toLocaleString()} PTS</div>}
                                    {userEmail && <button className='redeem-navbar' onClick={() => navigate('/redeem')}>Redeem</button>}
                                </div>
                            </li>
                            {userEmail && (
                                <li className="navbar__link navbar__button-container">
                                    <button
                                        style={{
                                            backgroundColor: '#879fcf',
                                            color: 'white',
                                            border: '3px solid white',
                                            borderRadius: '35px',
                                            height: '6vh',
                                            width: '60vw',
                                            boxShadow: '0px 4px 3px 1px rgba(220, 154, 87, 0.6)',
                                            marginBottom: '5vh',
                                        }}
                                        onClick={handleSignOut}
                                    >
                                        Sign Out
                                    </button>
                                </li>
                            )}
                        </>
                    )}
                </ul>
            </div>
        </nav>
    );

};

export default Navbar;
