import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import getUrl from './LambdaUrls';
import { BackButton } from './components/Buttons/BackButton';
import { DesktopContainer } from './components/DesktopContainer';
import Scoreboard from './components/Scoreboard';
import './leaderboard.css';

const Leaderboard = () => {
    const navigate = useNavigate();
    const [isUserSignedIn, setIsUserSignedIn] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [fullname, setFullname] = useState('');
    const [score, setScore] = useState(0);
    const [users, setUsers] = useState([]);


    useEffect(() => {
        checkUserSignedIn();
        fetchData();
        getUser();
    }, []);

    //account auth + check if user is signed in
    const checkUserSignedIn = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            setIsUserSignedIn(true)
            setUserEmail(user.attributes.email);
        } catch (error) {
            console.log('error:', error);
            //navigate('/signin')
        }
    };

    //get name
    const fetchData = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", String(localStorage.getItem('accessToken')));
        myHeaders.append("Content-type", "text/plain");
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        }
        fetch(getUrl('getFullname'), requestOptions)
            .then(response => response.text())
            .then(result => {
                setFullname(JSON.parse(result).fullname)
            })
            .catch(error => console.log('error', error));
    }

    // get score
    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/plain");

        var raw = "{\r\n  \"email\": \"" + String(localStorage.getItem('email')) + "\"\r\n}";

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        var Userscore = 0
        fetch(getUrl('postScore'), requestOptions)
            .then(response => response.text())
            .then(result => setScore(JSON.parse(result)[0][0]))
            .catch(error => console.log('error', error));
        checkUserSignedIn();
    }, []);

    //get name + score + fav for each user and then map over users to display each person - highest score showin top
    const getUser = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-type", "text/plain");
        myHeaders.append("Authorization", String(localStorage.getItem('accessToken')));
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        }
        fetch(getUrl('getLeaderBoard'), requestOptions)
            .then(response => response.text())
            .then(result => {
                const user = JSON.parse(result)
                console.log('USER', user)
                setUsers(user)
                console.log('USER')
            })
            .catch(error => {
                var myHeaders = new Headers();
                myHeaders.append("Content-type", "text/plain");
                var requestOptions = {
                    method: 'POST',
                    redirect: 'follow',
                    headers: myHeaders
                }
                fetch(getUrl('postLeaderBoard'), requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        const user = JSON.parse(result)
                        console.log('USER', user)
                        setUsers(user)
                        console.log('USER')
                    })
            });
    }





    return (
        <div>
            <DesktopContainer />
            <div className='mobile-container leaderboard-page'>
                <div className='top-group leader'>
                    <BackButton onClick={() => navigate('/suggestion')} />
                    <Scoreboard onClick={() => navigate('/redeem')} />
                    <div></div>
                </div>
                <h3 className='fullname'>
                    {fullname}
                </h3>
                <div className='points-redeem'>
                    <div className='total-points'>
                        Score: {score.toLocaleString()} PTS
                    </div>
                    <button
                        className='redeem-btn'
                        onClick={() => navigate('/redeem')}
                    >
                        Redeem
                    </button>
                </div>
                <div className='leaderboard'>
                    Leader board
                </div>
                <div className='top-score-users'>
                    {/* set users in getUsers then map over users  */}
                    {users.map((user, index) => (
                        <div className='user-info' key={index}>
                            <div className='ranking' >#{index + 1}</div>
                            <div className='user-top'
                                onClick={() => {
                                    if (user.fullname !== null) {
                                        navigate(`/user/${user.fullname}`);
                                    }
                                }}
                            >
                                <div className='name-and-pic'>
                                    <div className='profile-pic'></div>
                                    <div className='name'>{user.fullname ? user.fullname : "Anonymous"}</div>
                                </div>
                                <div>{user.score.toLocaleString()} PTS</div>
                            </div>
                            <div className={`user-middle ${user.fav[0] === null ? 'no-fav' : ''}`}>
                                Favourites: {user.fav.join(', ')}
                            </div>


                            <button
                                className={fullname === user.fullname ? 'hide-add-friend' : 'add-friend'}
                                onClick={() => {
                                    localStorage.setItem('isfriend', user.isfriend);
                                    navigate(`/user/${user.fullname}`);
                                }}
                            >
                                {user.isfriend === true ? 'View Profile' : 'Add Friend'}
                            </button>
                        </div>
                    ))}
                </div>

            </div>

        </div>
    )
}

export default Leaderboard
