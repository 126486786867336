import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import RedeemIcon from '@mui/icons-material/Redeem';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DesktopContainer } from './components/DesktopContainer';
import Navbar from './components/Navbar';
import './homepage.css';

function HomePage() {
  // Navigation
  let navigate = useNavigate();
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const slides = [
    {
      title: 'Quickly Get Directions To The Best Spots In The City',
      image: '../assets/map.png',
    },
    {
      title: 'Claim Discounts and Perks By Showing Our Screen',
      image: '../assets/deals.png',
    },
    {
      title: 'Plan The Perfect Date Or Time With Friends',
      image: '../assets/plans.png',
    },
    {
      title: 'Choose Which Places to Add As Hidden Gemz',
      image: '../assets/gemz.png',
    },
  ];

  useEffect(() => {
    // track scroll position
    window.addEventListener('scroll', handleScroll);

    // auto transition to the next slide after 2 seconds
    const interval = setInterval(() => {
      nextSlide();
    }, 3000);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearInterval(interval);
    };
  }, []);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const showThreshold = 200;

    if (scrollY > showThreshold) {
      setShowScrollToTop(true);
    } else {
      setShowScrollToTop(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const nextSlide = () => {
    setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const previousSlide = () => {
    setCurrentSlideIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };

  const handleGetStarted = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      // user is signed in, navigate to Suggestion page
      navigate('/suggestion');
    } catch (error) {
      // User is not signed in, navigate to SignInPage
      navigate('/signin');
    }
  };

  return (
    <div>
      <DesktopContainer />
      <div className="homepage-container mobile-container">
        <Navbar className='navbar' />

        <div className='info'>
          <h1>Start exploring the city with Cluez</h1>
          <p>Find new friends on your exciting and adventurous journey as you explore the vibrant city of Calgary.
            <br />
            <br />
            Earn points for each Hidden Gem you find</p>
          {/* <div className="gif-container">
            <video className="gif" src="../assets/HG-intro.mp4" alt="intro-video" controls></video>
          </div> */}
          <button className="centered-button" onClick={() => navigate('/suggestion')}>
            Get Started
            <TrendingFlatIcon className="button-icon" />
          </button>
        </div>

        {/* DESCRIPTION */}
        <div className='descriptionB' id='description'>
          <h2 className='h2-des'>{slides[currentSlideIndex].title}</h2>
          <div className="carousel-container">
            <ArrowBackIosIcon className="carousel-icon left" onClick={previousSlide} style={{ transform: 'scale(1.2)' }} />
            <img src={slides[currentSlideIndex].image} alt={`pic${currentSlideIndex + 1}`} />
            <ArrowForwardIosIcon className="carousel-icon right" onClick={nextSlide} style={{ transform: 'scale(1.2)' }} />
          </div>
        </div>

        {/* FOOTER */}
        <footer className='footer-container' id="footer">
          <div className='footer-logo'><img src='../assets/logo2.png' alt='logo' style={{width:'12vw'}}/> Hidden Gemz</div>
          <div className='footer-icons'>
            <InstagramIcon onClick={() => window.location.href = 'https://www.instagram.com/cluez.in.calgary/?igshid=OGQ5ZDc2ODk2ZA%3D%3D'} style={{ fontSize: '2rem' }} />
            <RedeemIcon onClick={() => window.location.href = 'https://hiddengemzcards.etsy.com/'} style={{ fontSize: '2rem' }} />
            <LinkedInIcon onClick={() => window.location.href = 'https://www.linkedin.com/company/hidden-gemz-yyc/'} style={{ fontSize: '2rem' }} />
          </div>
        </footer>
        <div className={`scroll-to-top ${showScrollToTop ? 'show' : ''}`} onClick={scrollToTop}>
          <ArrowUpwardIcon style={{ fontSize: '2.3rem' }} />
        </div>
      </div>
    </div>
  );

}

export default HomePage;
