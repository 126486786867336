import CloseIcon from '@mui/icons-material/Close';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { Auth } from 'aws-amplify';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Shake } from 'reshake';
import getUrl from './LambdaUrls';
import { BackButton } from './components/Buttons/BackButton';
import { RankButton } from './components/Buttons/RankButton';
import { DesktopContainer } from './components/DesktopContainer';
import './redeem.css';

function Redeem() {
    let navigate = useNavigate();
    const redeemPageRef = useRef(null);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const [selectedItems, setSelectedItems] = useState([]);
    const [score, setScore] = useState(0);
    const [isUserSignedIn, setIsUserSignedIn] = useState(false);
    const [isRedeemModalOpen, setIsRedeemModalOpen] = useState(false);
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [coupons, setCoupons] = useState([]);
    const [redeemStatus, setRedeemStatus] = useState(null);

    const selectedPlaces = [
        { PlaceName: 'VRCORE', Points: 20000 },
        { PlaceName: 'Rated Ultra Lounge', Points: 30000 },
        { PlaceName: 'Gyu Kaku', Points: 42000 },
        { PlaceName: 'Namsan Korean', Points: 65000 },
    ];

    const couponIdeas = [
        '1 Hour game play',
        'Get a pair of event tickets: DJ Hatiras',
        '50% off your order',
        'Dinner for 2',
    ];

    const values = [
        '$28',
        '$46',
        '$35',
        '$80',
    ];

    const logo = [
        './assets/vrcore.png',
        './assets/namsan.png',
        './assets/gyu.png'
    ];

    const handleItemClick = (coupon) => {
        const selectedItemPoints = coupon.point;
        if (selectedItems.includes(coupon)) {
            setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== coupon));
            setScore(score + selectedItemPoints);
        } else if (score >= selectedItemPoints) {
            setSelectedItems([...selectedItems, coupon]);
            setScore(score - selectedItemPoints);
        }
    };

    useEffect(() => {
        checkUserSignedIn();
    }, []);

    const checkUserSignedIn = async () => {
        try {
            await Auth.currentAuthenticatedUser();
            setIsUserSignedIn(true);
            console.log("signed in");
        } catch (error) {
            setIsUserSignedIn(false);
            console.log("not signed in");
        }
    };

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/plain");

        var raw = "{\r\n  \"email\": \"" + String(localStorage.getItem('email')) + "\"\r\n}";

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        var Userscore = 0
        fetch(getUrl("postScore"), requestOptions)
            .then(response => response.text())
            .then(result => setScore(JSON.parse(result)[0][0]))
            .catch(error => console.log('error', error));
        checkUserSignedIn();
    }, []);

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/plain");

        var raw = "{\r\n  \"email\": \"" + String(localStorage.getItem('email')) + "\"\r\n}";

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            //body: raw,
            redirect: 'follow'
        };
        var Userscore = 0
        fetch(getUrl("coupon"), requestOptions)
            .then(response => response.text())
            .then(result => {
                setCoupons(JSON.parse(result));
            })
            .catch(error => console.log('error', error));
        checkUserSignedIn();
    }, []);

    const handleRedeemClick = () => {
        setIsRedeemModalOpen(true);
        setIsOverlayVisible(true); // show the grey overlay when the modal is opened
        getUserPhone();
    };

    const handleModalClose = () => {
        setIsRedeemModalOpen(false);
        setIsOverlayVisible(false); // hide the grey overlay when the modal is closed
        redeemStatus(null);
    };

    const handleClickOutside = (event) => {
        if (redeemPageRef.current && !redeemPageRef.current.contains(event.target)) {
            setIsRedeemModalOpen(false);
            setIsOverlayVisible(false); // hide the grey overlay if clicked outside the modal
        }
    };

    // redeem form
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isEmailChecked, setIsEmailChecked] = useState(false);
    const [isPhoneChecked, setIsPhoneChecked] = useState(false);

    const getUserPhone = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/plain");
        myHeaders.append('Authorization', localStorage.getItem('accessToken'))
        var raw = JSON.stringify({
            email: localStorage.getItem('email')
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };
        fetch(getUrl('getuserinfo'), requestOptions)
            .then(response => response.text())
            .then(result => {
                setPhoneNumber(JSON.parse(result).phone)
            })
            .catch(error => console.log('error', error));
    };

    const handleEmailChange = (e) => {
        if (!isPhoneChecked) {
            setEmail(e.target.value);
        }
    };

    const handlePhoneNumberChange = (e) => {
        if (!isEmailChecked) {
            setPhoneNumber(e.target.value);
        }
    };

    const handleEmailCheckboxChange = () => {
        setIsEmailChecked(!isEmailChecked);
        setIsPhoneChecked(false);
        setPhoneNumber('');
    };

    const handlePhoneCheckboxChange = () => {
        setIsPhoneChecked(!isPhoneChecked);
        setIsEmailChecked(false);
        setEmail('');
    };

    const renderCoupon = (coupon, index) => {
        return (
            <div style={{ width: '80vw', position: 'relative' }}>
                <Shake h={0} v={0} r={`${selectedItems.includes(coupon) ? '3' : '0'}`} dur={400} int={50} max={100} fixed={false} fixedStop={true} freez={false}>
                    <div
                        key={coupon.id}
                        className={`scrollable-item ${selectedItems.includes(coupon) ? 'selected' : ''} ${(!isUserSignedIn || (coupon.Point > score && !selectedItems.includes(coupon))) ? 'disabled' : ''}`}
                        onClick={() => handleItemClick(coupon)}
                    >
                        <div className='available-cp'>x{coupon.available}</div>
                        <div className="middle">
                            <div className="coupon">
                                {coupon.description}
                            </div>
                            <div className="placename">{coupon.placename}</div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignContent: 'center', marginTop: "5px" }}>
                                <FmdGoodIcon style={{ marginRight: '6px', marginLeft: '-5px', color: "#EB6660" }} />
                                <div className="values">Value: ${coupon.value}</div>
                            </div>
                        </div>
                        <div className="section">
                            {coupon.point.toLocaleString()}
                            <br />
                            points
                        </div>
                    </div>
                </Shake>
            </div>
        );
    };


    const handleOnClickRedeem = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/plain");
        myHeaders.append('Authorization', localStorage.getItem('accessToken'))
        var raw = JSON.stringify({
            email: localStorage.getItem('email'),
            phone: phoneNumber,
            coupons: selectedItems,
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,

        };
        fetch(getUrl('coupon'), requestOptions)
            .then(response => {
                if (response.ok) {
                    setRedeemStatus('success');
                } else {
                    setRedeemStatus('fail');
                }
            })
            .catch(error => {
                console.log('error', error);
                setRedeemStatus('fail');
            });
    }

    const handleBackToRedeemClick = () => {
        window.location.reload(); // Reload the page
        navigate('/redeem'); // Navigate to the "redeem" page
    };
    return (
        <div>
            <DesktopContainer />
            <div ref={redeemPageRef} className="redeem-container mobile-container">
                {isOverlayVisible && <div className="overlay" />}
                <div className="top-options">
                    <BackButton onClick={() => navigate("/suggestion")} style={{ marginTop: '3vh' }} />
                    <div></div>
                    <RankButton onClick={() => navigate("/leaderboard")} style={{ marginTop: '3vh' }} />
                </div>
                <div className="top-div center">
                    {!isUserSignedIn && (
                        <p style={{ fontSize: '18px' }}><span onClick={() => navigate('/signin')} style={{ textDecoration: 'underline', color: '#1bb5b5' }}>Sign in</span> to start redeeming points</p>
                    )}
                    {isUserSignedIn && (
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
                            <div className='total-points'>Score: {score.toLocaleString()} PTS</div>
                            <div>
                                <button className="redeem-btn" disabled={selectedItems.length === 0} onClick={handleRedeemClick}>
                                    Redeem
                                </button>
                            </div>
                        </div>
                    )}

                </div>
                <div className="bottom-div">

                    <p className='instruction-pts'>Use points to try something new</p>
                    <div className="scrollable-container">
                        <div className="scrollable-content">
                            {coupons.map((coupon, index) => renderCoupon(coupon, index))}
                        </div>
                    </div>
                </div>
                {selectedItems.length > 0 && (
                    <div style={{ marginTop: '10px', marginLeft: '10vw', fontSize: '1.1rem', display: '' }}>
                        Selected items: {selectedItems.map((item) => (
                            <span style={{ fontStyle: 'italic', fontSize: '12px', color: 'rgb(235, 102, 96)' }} key={item.id} > <ul>- {item.description} at {item.placename} {item.point.toLocaleString()} points </ul></span>
                        ))}
                    </div>
                )}
                {/* open redeemmodal */}
                {isRedeemModalOpen && (
                    <div className="fullscreen-modal">
                        {/* CloseIcon in the top right corner */}
                        <div className="close-icon">
                            <CloseIcon onClick={handleModalClose} style={{ color: 'black' }} />
                        </div>
                        {redeemStatus === null ? (<div>  <h2 className='h2-modal'>You are redeeming:</h2>
                            <p className="redeem-modal">
                                {selectedItems.map((item) => (
                                    <span style={{ fontStyle: 'italic', fontSize: '12px', color: 'rgb(235, 102, 96)' }} key={item.id} > <ul>- {item.description} at {item.placename} {item.point.toLocaleString()} points </ul></span>
                                ))}
                            </p>
                            <p>Remaining Points: <span style={{ fontWeight: 800 }}>{score.toLocaleString()} points </span></p>
                            <form className="redeeming-form">
                                <div>
                                    <label>
                                        <input type="checkbox" checked={isPhoneChecked} onChange={handlePhoneCheckboxChange} disabled={isEmailChecked} />
                                        Get prize texted
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input type="checkbox" checked={isEmailChecked} onChange={handleEmailCheckboxChange} disabled={isPhoneChecked} />
                                        Get prize emailed
                                    </label>
                                </div>
                                {isEmailChecked && (
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label htmlFor="email" style={{ opacity: isPhoneChecked ? 0.5 : 1, pointerEvents: isPhoneChecked ? 'none' : 'auto' }}>
                                            Email:
                                        </label>
                                        <input type="email" id="email" value={email} onChange={handleEmailChange} disabled={isPhoneChecked} style={{ width: '70vw' }} />
                                    </div>
                                )}
                                {isPhoneChecked && (
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label htmlFor="phoneNumber" style={{ opacity: isEmailChecked ? 0.5 : 1, pointerEvents: isEmailChecked ? 'none' : 'auto' }}>
                                            Phone Number:
                                        </label>
                                        <input type="tel" id="phoneNumber" value={phoneNumber} onChange={handlePhoneNumberChange} disabled={isEmailChecked} style={{ width: '70vw' }} />
                                    </div>
                                )}
                            </form>
                            <button className='centered-button' onClick={handleOnClickRedeem}>Redeem</button>
                        </div>) : redeemStatus === 'success' ?
                            (<div style={{ marginTop: '10vh' }}><p>Your request has been received. We will contact you with the voucher shortly.</p> <button className='centered-button' onClick={handleBackToRedeemClick}>Back To Redeem</button></div>) :
                            (<div style={{ marginTop: '10vh' }}><p>Sorry, please try again later.</p>
                                <button onClick={handleBackToRedeemClick} className='centered-button'>Back To Redeem</button></div>)}
                    </div>
                )}
            </div>
        </div>
    );
}

export default Redeem;
