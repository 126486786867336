import React from 'react'
import { useNavigate } from "react-router-dom";

export const UserButton = ({ onClick, style }) => {
    let navigate = useNavigate();
    const styling = {
        cursor: 'pointer',
        borderRadius: '10px',
        color: 'white',
        border: ' 3px solid white',
        backgroundColor: '#879fcf',
        boxShadow: '0px 4px 3px 1px rgba(220, 154, 87, 0.6), 0px -5px 0px 0px rgba(0, 0, 0, 0.08) inset',
        width: '45px',
        height: '45px'

    }

    const mergedStyles = {
        ...styling,
        ...style, // merge the provided style prop
    };
    return (
        <div
            style={mergedStyles}
            onClick={onClick}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                style={{transform:'scale(0.87)'}}

            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                />
            </svg>
        </div>
    )
}


