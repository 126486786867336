import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BackButton } from './components/Buttons/BackButton';
import { DesktopContainer } from './components/DesktopContainer';
import Scoreboard from './components/Scoreboard';
import './interests.css';
import getUrl from './LambdaUrls';

const Interests = () => {
    const navigate = useNavigate();

    const [userEmail, setUserEmail] = useState('');
    const [score, setScore] = useState(0);
    const [showMore, setShowMore] = useState(false);
    const itemsPerPage = 9;
    const [namesIconsData2, setNamesIconsData] = useState([]);
    const [filteredNamesIconsData, setFilteredNamesIconsData] = useState([]);
    const [boxClicked, setBoxClicked] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);
    const [preferenceSaved, setPreferenceSaved] = useState(false);


    useEffect(() => {
        checkUserSignedIn();
        fetchData();
        fetchScore();
    }, []);

    const checkUserSignedIn = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            setUserEmail(user.attributes.email);
        } catch (error) {
            console.log('Error:', error);
        }
    };

    const fetchScore = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/plain");

        var raw = "{\r\n  \"email\": \"" + String(localStorage.getItem('email')) + "\"\r\n}";

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(getUrl('postScore'), requestOptions)
            .then(response => response.text())
            .then(result => setScore(JSON.parse(result)[0][0]))
            .catch(error => console.log('error', error));
    };

    const fetchData = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/plain");
        myHeaders.append("Authorization", String(localStorage.getItem('accessToken')));

        var raw = "{\r\n    \"PlaceID\": " + localStorage.getItem("place") + ", \r\n \"email\": \"" + String(localStorage.getItem('email')) + "\"\r\n}";

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            //body: raw,
            redirect: 'follow'
        };

        fetch(getUrl('getTags'), requestOptions)
            .then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                console.log('DATA', data)
                const parsedData = data.map(item => ({
                    ...item,
                    checked: item.checked === 'true',
                }));
                console.log('PARSED', parsedData)
                setNamesIconsData(data);
                const filteredData = data.filter(({ name, svg }) => name && svg);
                setFilteredNamesIconsData(filteredData);
                setBoxClicked(Array(filteredData.length).fill(false));
                //check for which is interest is checked = true
                const dbCheckedItems = data.map(item => item.checked === 'true');
                setCheckedItems(dbCheckedItems);

                console.log('taglist', data);
            })
            .catch(error => console.log('error', error));
    };

    const itemsToShow = showMore ? filteredNamesIconsData : filteredNamesIconsData.slice(0, itemsPerPage);

    const updateCheckedStatus = (id, isChecked) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", String(localStorage.getItem('accessToken')));

        const requestBody = {
            id: id,
            checked: isChecked ? 'true' : 'false',
        };

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(requestBody),
            redirect: 'follow',
        };

        fetch(getUrl('getTags'), requestOptions)
            .then(response => {
                if (response.status === 200) {
                    console.log('checked success', 'ID', id);
                } else {
                    console.error('failed checked');
                }
            })
            .catch(error => console.log('error', error));
    };

    const handleBoxClick = (index, id) => {
        console.log('MATRIX-BOX index:', index, 'ID:', id);

        const newCheckedItems = [...checkedItems];
        newCheckedItems[index] = !newCheckedItems[index];
        setCheckedItems(newCheckedItems);
        console.log('UPDATE CHECKEDITEMS:', newCheckedItems);

        updateCheckedStatus(id, newCheckedItems[index]);
    };


    const toggleShowMore = () => {
        setShowMore(!showMore);
    };

    // number of rows based on the number of itemsToShow
    // const numRows = Math.ceil(itemsToShow.length / 3);

    // grid-template-rows property based on the number of rows
    const [gridTemplateRows, setGridTemplateRows] = useState(''); // State for gridTemplateRows
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth === 375);

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth === 375);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); //  effect runs only once

    // number of rows based on itemsToShow
    const numRows = Math.ceil(itemsToShow.length / 3);

    const rowHeight = isSmallScreen ? '16vh' : '13vh';

    const newGridTemplateRows = `repeat(${numRows}, ${rowHeight})`;

    // update gridTemplateRows when needed
    if (gridTemplateRows !== newGridTemplateRows) {
        setGridTemplateRows(newGridTemplateRows);
    }


    const handleSaveClick = () => {
        // setPreferenceSaved = true , display the message
        setPreferenceSaved(true);

        // reset after 3s
        setTimeout(() => {
            setPreferenceSaved(false);
        }, 2000);
    };

    return (
        <div>
            <DesktopContainer />
            <div className="mobile-container interests-page" style={{ fontSize: '1.3rem', fontWeight: '300' }}>
                <div className="top-group">
                    <BackButton onClick={() => navigate("/user")} />
                    <Scoreboard onClick={() => navigate('/redeem')} />
                    <div></div>
                </div>
                {userEmail && <div style={{ textAlign: 'center' }} className='I-email'>{userEmail}</div>}
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
                    <div className='total-points'>Score: {score.toLocaleString()} PTS</div>
                </div>
                <div className='column-divide'>
                    <div className='left-container interest-IP'><p>Interest</p></div>
                </div>
                <div className='all-interests'>
                    <div className="matrix-container">
                        <div className="matrix-board" style={{ gridTemplateRows }}>
                            {itemsToShow.map(({ id, name, svg }, index) => (
                                <div
                                    key={index}
                                    className="matrix-box"
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        backgroundColor: checkedItems[index] ? '#96d381' : 'white',
                                    }}
                                    onClick={() => {
                                        handleBoxClick(index, id);
                                        console.log('CHECKEDITEMS', checkedItems[index]);
                                    }}
                                >
                                    <p className='name-boxes'>{name}</p>
                                    <img src={`data:image/svg+xml;utf8,${encodeURIComponent(svg)}`} alt={name} style={{ width: '10vw' }} />
                                </div>
                            ))}

                            <p className="show-hide-button" onClick={toggleShowMore}>
                                {showMore ? 'Hide' : 'Show more'}
                            </p>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '5vh' }}>
                                <button className='save-button' onClick={handleSaveClick}>Save</button>
                                {preferenceSaved && <p style={{ width: '100vw', textAlign: 'center', fontSize: '0.95rem', color: '#db6e65' }}>Your preferences have been successfully saved.</p>}
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Interests;
