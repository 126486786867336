import React from 'react'
import { useNavigate } from "react-router-dom";
import '../../suggestions.css';

export const MapButton = ({ onClick, style, className }) => {
    let navigate = useNavigate();

    const styling = {
        cursor: 'pointer',
        borderRadius: '10px',
        color: 'white',
        backgroundColor: '#879fcf',
        border: ' 3px solid white',
        boxShadow: '0px 4px 3px 1px rgba(220, 154, 87, 0.6), 0px -5px 0px 0px rgba(0, 0, 0, 0.08) inset',
        width: '45px',
        height: '45px'
    }

    const mergedStyles = {
        ...styling,
        ...style, // merge the provided style prop
    };

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 35 35"
            strokeWidth={0.5}
            stroke="currentColor"
            style={mergedStyles}
            onClick={onClick}
            className={className}
        >
            <path fill="currentColor" d="m16 24l-6.09-8.6A8.14 8.14 0 0 1 16 2a8.08 8.08 0 0 1 8 8.13a8.2 8.2 0 0 1-1.8 5.13Zm0-20a6.07 6.07 0 0 0-6 6.13a6.19 6.19 0 0 0 1.49 4L16 20.52L20.63 14A6.24 6.24 0 0 0 22 10.13A6.07 6.07 0 0 0 16 4Z" />
            <circle cx="16" cy="9" r="2" fill="currentColor" />
            <path fill="currentColor" d="M28 12h-2v2h2v14H4V14h2v-2H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h24a2 2 0 0 0 2-2V14a2 2 0 0 0-2-2Z" />
        </svg>
    )
}
