import { Auth } from 'aws-amplify';
import User from './User';
import OtherUser from './OtherUser';
import { useParams } from 'react-router-dom';

import { useState, useEffect } from 'react';
import getUrl from './LambdaUrls';

const UserOrOtherUser = () => {
    const { pfullname } = useParams();
    const [userComponent, setUserComponent] = useState(null);

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/plain");
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };

        fetch(getUrl('friend')  + pfullname, requestOptions)
            .then(response => response.text())
            .then(result => {
                var j = JSON.parse(result);
                if (j.email === localStorage.getItem('email')) {
                    setUserComponent(<User />);
                } else {
                    setUserComponent(<OtherUser />);
                }
            })
            .catch(error => console.log('error', error));
    }, [pfullname]);

    return userComponent;
};

export default UserOrOtherUser;
