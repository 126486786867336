const Urls = {
	'cognito': [
		'DAIR',
		'PROD',
	],
	'getLeaderBoard': [
		'https://omdffm9noe.execute-api.ca-central-1.amazonaws.com/Dev/leaderboard',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/leaderboard'
	],
	'postLeaderBoard': [
		'https://omdffm9noe.execute-api.ca-central-1.amazonaws.com/Dev/leaderboard',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/leaderboard'
	],
	'getFullname': [
		'https://omdffm9noe.execute-api.ca-central-1.amazonaws.com/Dev/fullname',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/fullname'
	],
	'postScore': [
		'https://omdffm9noe.execute-api.ca-central-1.amazonaws.com/Dev/getscore',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/getscore'
	],
	'friend': [
		'https://x2rkh7jsu8.execute-api.ca-central-1.amazonaws.com/Dev/',
		'https://ygxvbicbci.execute-api.ca-central-1.amazonaws.com/DAIR/'
	],
	'getFav': [
		'https://lk7xh26yn9.execute-api.ca-central-1.amazonaws.com/Dev/fav',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/fav'
	],
	'getCheckin': [
		'https://lk7xh26yn9.execute-api.ca-central-1.amazonaws.com/Dev/checkin',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/checkin'
	],
	'getTags': [
		'https://91k7tamkl6.execute-api.ca-central-1.amazonaws.com/Dev',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/tags'
	],
	'getPics': [
		'https://95w87novv9.execute-api.ca-central-1.amazonaws.com/Dev/pictures',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/pictures'
	],
	'getAllPlaces': [
		'https://lk7xh26yn9.execute-api.ca-central-1.amazonaws.com/Dev',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/places'
	],
	'postPlace': [
		'https://lk7xh26yn9.execute-api.ca-central-1.amazonaws.com/Dev/getplace',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/place'
	],
	'deletePlace': [
		'https://lk7xh26yn9.execute-api.ca-central-1.amazonaws.com/Dev/deleteplace',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/places'
	],
	'approvePlace': [
		'https://lk7xh26yn9.execute-api.ca-central-1.amazonaws.com/Dev/approvalPlace',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/adminplace'
	],
	'addLocation': [
		'https://lk7xh26yn9.execute-api.ca-central-1.amazonaws.com/Dev',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/places'
	],
	'updateScore': [
		'https://lk7xh26yn9.execute-api.ca-central-1.amazonaws.com/Dev/upload',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/upload'
	],
	'addClick': [
		'https://lk7xh26yn9.execute-api.ca-central-1.amazonaws.com/Dev/addclick',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/addclick'
	],
	's3upload': [
		'https://av5lwdb4og.execute-api.ca-central-1.amazonaws.com/Dev/image-upload-hg/',
		'https://32840hoe9k.execute-api.ca-central-1.amazonaws.com/DAIR/image-upload-dair/'
	],
	'postLocation': [
		'https://zptamw2ct6.execute-api.ca-central-1.amazonaws.com/Dev',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/location'
	],
	'getLocation': [
		'https://zptamw2ct6.execute-api.ca-central-1.amazonaws.com/Dev',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/location'
	],
	'deleteLocation': [
		'https://zptamw2ct6.execute-api.ca-central-1.amazonaws.com/Dev',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/location'
	],
	'postVote': [
		'https://lk7xh26yn9.execute-api.ca-central-1.amazonaws.com/Dev/votting',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/voting'
	],
	'events': [
		'https://bjc3xuox61.execute-api.ca-central-1.amazonaws.com/Dev/',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/events'
	],
	'addEmail': [
		'https://omdffm9noe.execute-api.ca-central-1.amazonaws.com/Dev/addemail',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/signup'
	],
	'pubicProfile': [
		'https://omdffm9noe.execute-api.ca-central-1.amazonaws.com/Dev/public',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/publicprofile'
	],
	'deleteImage': [
		'https://lk7xh26yn9.execute-api.ca-central-1.amazonaws.com/Dev/upload/',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/upload'
	],
	'putImagePath': [
		'https://lk7xh26yn9.execute-api.ca-central-1.amazonaws.com/Dev/addimagepath',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/upload'
	],
	's3imagePath': [
		'https://dev.cluez.ca',
		'https://cluez.ca',
	],
	'signature': [
		'.OoO.',
		'Score',
	],
	'getNewsfeed': [
		'https://lk7xh26yn9.execute-api.ca-central-1.amazonaws.com/Dev/newsfeed',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/newsfeed'
	],
	'newsfeedWS': [
		'wss://ebl4yvcdid.execute-api.ca-central-1.amazonaws.com/Dev',
		'wss://kbz4q5p7bb.execute-api.ca-central-1.amazonaws.com/DAIR'
	],
	'message': [
		'https://lk7xh26yn9.execute-api.ca-central-1.amazonaws.com/Dev/message',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/message'
	],
	'profileFriends': [
		'https://95w87novv9.execute-api.ca-central-1.amazonaws.com/Dev/friends',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/profile/friends'
	],
	'postuserbirthdate': [
		'https://95w87novv9.execute-api.ca-central-1.amazonaws.com/Dev/postbirthdate',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/postuserbirthdate'
	],
	'postusergender': [
		'https://95w87novv9.execute-api.ca-central-1.amazonaws.com/Dev/postgender',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/postusergender'
	],
	'postuserworktype': [
		'https://95w87novv9.execute-api.ca-central-1.amazonaws.com/Dev/postuserworktype',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/postuserworktype'
	],
	'postusertransportation': [
		'https://95w87novv9.execute-api.ca-central-1.amazonaws.com/Dev/posttransportation',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/postusertransportation'
	],
	'getuserinfo': [
		'https://95w87novv9.execute-api.ca-central-1.amazonaws.com/Dev/getuserinfo',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/getuserinfo'
	],
	'userpendingpoints': [
		'https://omdffm9noe.execute-api.ca-central-1.amazonaws.com/Dev/pendingpoints',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/pendingpoints'
	],
	'coupon': [
		'https://omdffm9noe.execute-api.ca-central-1.amazonaws.com/Dev/coupon',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/coupon'
	],
	'qotd': [
		'https://lk7xh26yn9.execute-api.ca-central-1.amazonaws.com/Dev/qotd',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/qotd'
	],
	'cities': [
		'https://lk7xh26yn9.execute-api.ca-central-1.amazonaws.com/Dev/cities',
		'https://2q57dzumma.execute-api.ca-central-1.amazonaws.com/DAIR/cities'
	],
}

const getUrl = (fn) => {
	if (window.location.origin !== 'https://cluez.ca') // swap this condition to test with PRODUCTION data
		return Urls[fn][0];
	else
		return Urls[fn][1];

}

export default getUrl;


