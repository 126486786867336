import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import CloseIcon from '@mui/icons-material/Close';
import { Auth } from 'aws-amplify';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AddFriendButton } from "./components/Buttons/AddFriendButton";
import { BackButton } from './components/Buttons/BackButton';
import { DesktopContainer } from './components/DesktopContainer';
import Scoreboard from './components/Scoreboard';
import './otheruser.css';
import getUrl from './LambdaUrls';

const OtherUser = () => {
    const [userEmail, setUserEmail] = useState('');
    const [isUserSignedIn, setIsUserSignedIn] = useState(false);
    const [score, setScore] = useState('');
    const [isPlaceModalOpen, setIsPlaceModalOpen] = useState(false);
    const [interests, setInterests] = useState([]);
    const [fullname, setFullname] = useState('')
    const navigate = useNavigate();
    //get value from input text
    const inputRef = useRef("");
    const [isEditingName, setIsEditingName] = useState(false);
    const [place, setPlace] = useState([])
    const [cplace, setcPlace] = useState([])
    const [placez, setPlacez] = useState([])
    const [placeid, setplaceid] = useState([])
    const [addfriend, setAddFriend] = useState(false);


    const { pfullname } = useParams();

    const checkUserSignedIn = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            console.log('User:', user);
            setIsUserSignedIn(true)
            setUserEmail(user.attributes.email);
        } catch (error) {
            console.log('Error:', error);
            //navigate('/signin')
        }
    };

    useEffect(() => {
		
        checkUserSignedIn();
		setFullname(pfullname);
        fetchEvents();
        fetchDataPOST();
		
		
    }, []);

    const fetchEvents = () => {
        var myHeaders = new Headers();
        //myHeaders.append("Authorization", String(localStorage.getItem('accessToken')));
        myHeaders.append("Content-Type", "text/plain");
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };

        fetch(getUrl('friend') + pfullname + "/fav", requestOptions)
            .then(response => response.text())
            .then(result => setPlace(JSON.parse(result)))
            .catch(error => console.log('error', error));
        fetch(getUrl('friend') + pfullname + "/tag", requestOptions)
            .then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                setInterests(data);
            })
            .catch(error => console.log('error', error));
        fetch(getUrl('friend') + pfullname + "/checkin", requestOptions)
            .then(response => response.text())
            .then(result => setcPlace(JSON.parse(result)))
            .catch(error => console.log('error', error));
		fetch(getUrl('friend') + pfullname, requestOptions)
            .then(response => response.text())
            .then(result => {
                var j = JSON.parse(result);
                // if (j.email == localStorage.getItem('email')) navigate('/user');
                setScore(j.score);
				setAddFriend(!j.isfriend)
            })
            .catch(error => console.log('error', error));
		requestOptions.headers.append("Authorization", String(localStorage.getItem('accessToken')));
        fetch(getUrl('friend') + pfullname+'/isfriend', requestOptions)
            .then(response => response.text())
            .then(result => {
                var j = JSON.parse(result);
                // if (j.email == localStorage.getItem('email')) navigate('/user');
                setScore(j.score);
				setAddFriend(!j.isfriend)
            })
            .catch(error => console.log('error', error));
    };

    const [error, setError] = useState('');

    const handleSignOut = async () => {
        try {
            await Auth.signOut();
            setIsUserSignedIn(false);
            window.location.href = '/';
        } catch (error) {
            setError(error.message);
        }
    };

    const [isOverlayVisible, setIsOverlayVisible] = useState(false);

    const handleModalClose = () => {
        setIsPlaceModalOpen(false);
        setIsOverlayVisible(false); // hide the grey overlay when the modal is closed
    };



    const [images, setImages] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [uploadStatus, setUploadStatus] = useState(null);

    console.log(images, 'images')


    const handleNext = () => {
        let nextIndex = currentIndex + 1;
        if (nextIndex === images.length) {
            nextIndex = 0
        }
        setCurrentIndex(nextIndex);
    };
    const handlePrev = () => {
        let previousIndex = currentIndex - 1;
        if (previousIndex < 0) {
            previousIndex = images.length - 1;
        }
        setCurrentIndex(previousIndex);
    };
    // const isLastImage = currentIndex === images.length - 1;
    const isLastImage = currentIndex === images.length - 1;
    const renderUploadButton = isLastImage && isUserSignedIn;




    const fetchDataPOST = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/plain");
        //myHeaders.append("Authorization", String(localStorage.getItem('accessToken')));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            // body: raw,
            redirect: 'follow'
        };

        fetch(getUrl('friend') + pfullname + "/pic", requestOptions)
            .then(response => response.text())
            .then(result => {
                try {
                    const parsedResult = JSON.parse(result);
                    var imagez = []
                    var placenamez = []
                    var placeidz = []
                    if (Array.isArray(parsedResult)) {
                        parsedResult.map((placez) => {
                            imagez.push(placez.imagepath);
                            placenamez.push(placez.placename);
                            placeidz.push(placez.placeid)
                        });
                        imagez.push("https://placehold.co/150x150/FDF5EA/FDF5EA");
                        placenamez.push("")
                        setplaceid(placeidz)
                        setImages(imagez);
                        setPlacez(placenamez);
                    } else {
                        console.error('parsedResult', parsedResult);
                    }
                } catch (error) {
                    console.error('error', error);
                }
            })
            .catch(error => console.error(' error:', error));
    }

    const navigateToPlaceView = () => {
        localStorage.setItem('place', placeid[currentIndex]);
        navigate('/placeView/' + placez[currentIndex]+'/'+placeid[currentIndex]);
    };

    //add friend

    const handleAddFriend = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", String(localStorage.getItem('accessToken')));
        myHeaders.append("Content-Type", "text/plain");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(getUrl('friend') + fullname, requestOptions)
            .then(response => response.text())
            .then(result => {
                const returnResult = JSON.parse(result)
                console.log(returnResult);

                if (returnResult.approver === fullname) {
                    localStorage.setItem('isfriend', true)
                    setAddFriend(false);
                } else {
                    setAddFriend(true)
                }
            })
            .catch(error => console.error(' error:', error));
    }
    return (
        <div>
            <DesktopContainer />
            <div className=" mobile-container user-page" style={{ fontSize: '1.3rem', fontWeight: '300' }}>
                {isOverlayVisible && <div className="overlaypage" />}
                <div className="top-group" >
                    <BackButton onClick={() => navigate("/leaderboard")} />
                    <Scoreboard onClick={() => navigate('/redeem')} />
                    <AddFriendButton
                        onClick={handleAddFriend}
                        className={!addfriend ? 'disable-add-friend' : 'add-friend-button'}
                    />
          
                </div>
                <div style={{ marginTop: '20px', marginBottom: "20px", display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }} className='email-USER'>
                    <input
                        ref={inputRef}
                        type='text'
                        placeholder='Friends name'
                        value={fullname}
                        // change class base on condition if iseditting or not
                        className={`input-name ${isEditingName ? 'editing' : ''}`}
                        disabled={true}
                    />
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
                    <div className='total-points-OTHERUSER'>Score: {score.toLocaleString()} PTS</div>
                </div>
                {/* image carousel */}
                <div className="image-container">

                    {images.map((imageUrl, index) => (

                        <img
                            src={imageUrl.charAt(0) === '/' ? getUrl('s3imagePath')  + imageUrl : imageUrl}
                            alt="Gallery"
                            className="image-con"
                            style={{
                                display: imageUrl === images[currentIndex] ? "block" : "none",
                                objectFit: "cover",
                                borderRadius: "15px",
                                border: '5px solid white',
                                boxShadow: '0px 4px 3px 1px rgba(220, 154, 87, 0.6)',
                                marginBottom: '10px'
                            }}
                        />

                    ))}

                    {renderUploadButton ? (
                        <>
                            <label htmlFor="fileInput">
                                <button
                                    style={{
                                        height: "70%",
                                        width: '60vw',
                                        cursor: "pointer",
                                        fontSize: '16px',
                                        border: 'none',
                                        borderRadius: '10px',
                                        // boxShadow: '0px -5px 0px 0px rgba(0, 0, 0, 0.2) inset',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%',
                                        zIndex: 10,
                                        // backgroundColor: 'rgb(135, 159, 207)',
                                        backgroundColor: "#fdf5ea",
                                        color: 'rgb(135, 159, 207)',
                                        display: 'block'
                                    }}

                                    onClick={() => { document.getElementById('fileInput').click() }}
                                >
                                    <img src="/assets/picture.png" alt="picture-icon" style={{ width: '18vw' }} />
                                    <h3 style={{ fontSize: '5vw', fontWeight: '500' }}>Upload Pictures In The Venues</h3>
                                </button>
                            </label>

                        </>
                    ) : (
                        <>
                            {isLastImage && (

                                <button
                                    style={{
                                        height: "9vh",
                                        width: '50vw',
                                        cursor: "pointer",
                                        fontSize: '14px',
                                        border: '3px solid white',
                                        borderRadius: '10px',
                                        boxShadow: '0px -5px 0px 0px rgba(0, 0, 0, 0.2) inset',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%',
                                        zIndex: 10,
                                        color: 'white',
                                        backgroundColor: '#879fcf',
                                        fontSize: '16px'
                                    }}

                                    onClick={() => { navigate("/signin") }}
                                >
                                    <span style={{ textDecoration: 'underline', color: 'white' }}>Sign In</span> to upload images
                                </button>
                            )}

                        </>
                    )}
                </div>

                <button onClick={(handlePrev)} className="icons-OTHERUSER left" >
                    <ChevronLeft style={{ transform: 'scale(2, 2.5)' }} />
                </button>
                <button onClick={handleNext} className="icons-OTHERUSER right" >
                    <ChevronRight style={{ transform: 'scale(2, 2.5)' }} />
                </button>


                <div className="dots">
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "0.5rem", alignContent: 'center' }}>
                        {images.map((slide, image) => (
                            <div className="image-dots"
                                style={{
                                    transition: "all 0.2s",
                                    width: "0.75rem",
                                    height: "0.75rem",
                                    backgroundColor: currentIndex === image ? "white" : "#77320A",
                                    borderRadius: "50%",
                                    padding: currentIndex === image ? "0.25rem" : "0",
                                    opacity: currentIndex === image ? "1" : "0.5",
                                    cursor: 'pointer',
                                    marginBottom: '2vh',
                                    boxShadow: currentIndex === image ? "0px 3px 3px 1px rgba(198, 126, 40, 0.6)" : "none"
                                }}
                                onClick={() => setCurrentIndex(image)}
                            />
                        ))}

                    </div>
                </div>

                {/* return places according to pictures */}
                <p style={{ textAlign: 'center' }} onClick={navigateToPlaceView} className="place-name-images">{placez[currentIndex]}</p>

                <div className='bottom-holder'>
                    <div className='column-divide'>
                        <div className='left-container fav-place'><p>Favorite</p></div>
                        <ul className='right-container' style={{ listStyle: 'none', padding: 0, display: 'flex', flexDirection: 'column' }}>
                            {place.map((item, index) => (
                                <li className='list-places' key={index}>
                                    {item.placename}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className='column-divide'>
                        <div className='left-container interest'><p>Interest</p></div>
                        <ul className='right-container' style={{ listStyle: 'none', padding: 0, display: 'flex', flexDirection: 'column' }}>
                            {interests
                                .map((interest, index) => (
                                    <li className='list-places' key={index}>
                                        {interest.name}
                                    </li>
                                ))}
                        </ul>
                    </div>
                    <div className='column-divide'>
                        <div className='left-container checkins'><p >Check ins</p></div>
                        <ul className='right-container' style={{ listStyle: 'none', padding: 0, display: 'flex', flexDirection: 'column' }}>
                            {cplace.map((item, index) => (
                                <li className='list-places' key={index}>
                                    {item.placename}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div style={{ width: '100vw', display: 'flex', justifyContent: 'center' }}>
                    <button
                        style={{
                            backgroundColor: '#879fcf',
                            color: 'white',
                            border: '3px solid white',
                            borderRadius: '35px',
                            height: '5vh',
                            width: '30vw',
                            boxShadow: '0px 4px 3px 1px rgba(220, 154, 87, 0.6)',
                            marginBottom: '5vh',
                            fontSize: '1.1rem',
                            marginLeft: '5vw'
                        }}
                        onClick={handleSignOut}
                    >
                        Sign Out
                    </button>
                </div>
            </div>

            {/* Modal */}
            {isOverlayVisible && (
                <div className="modal-overlay">
                    <div className="modal-USER">
                        <div className="close-icon">
                            <CloseIcon onClick={handleModalClose} style={{ color: 'white' }} />
                        </div>
                        <h3 style={{ fontWeight: '500' }}>Who do you want to see this?</h3>
                        <form>
                            <label style={{ display: 'flex', alignItems: 'center', gridGap: '30vw', fontSize: '1.2rem', fontWeight: '300' }}>
                                Private
                                <input type="radio" id="private" name="visibility" value="private" />
                            </label><br />

                            <label style={{ display: 'flex', alignItems: 'center', gridGap: '31vw', fontSize: '1.2rem', fontWeight: '300' }}>
                                Public
                                <input type="radio" id="public" name="visibility" value="public" />
                            </label><br />

                            <button type="submit" className='update-privacy'>Update</button>
                        </form>



                    </div>
                </div>
            )}
        </div>
    );
};

export default OtherUser;
