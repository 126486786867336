import CloseIcon from '@mui/icons-material/Close';
import { MenuItem, Select, TextareaAutosize } from '@mui/material';
import React, { useState, Fragment, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import lscache from 'lscache';
import { useGeolocated } from 'react-geolocated';
import getUrl from './LambdaUrls';
import './addlocationusers.css';
import { BackButton } from './components/Buttons/BackButton';
import { DesktopContainer } from './components/DesktopContainer';


function calculateDistance(lat1, lon1, lat2, lon2) {
  // Radius of the Earth in kilometers
  const radius = 6371;

  // Convert degrees to radians
  const lat1Rad = lat1 * Math.PI / 180;
  const lon1Rad = lon1 * Math.PI / 180;
  const lat2Rad = lat2 * Math.PI / 180;
  const lon2Rad = lon2 * Math.PI / 180;

  // Haversine formula
  const dLat = lat2Rad - lat1Rad;
  const dLon = lon2Rad - lon1Rad;
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1Rad) * Math.cos(lat2Rad) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Distance in kilometers
  const distance = radius * c;

  return distance;
}

function getNearestCity(coords,citiesO) {
  if (!coords || citiesO.length == 0) return 'Canada';
  let minDistance=999999999999;
  let minCity='Canada';
  citiesO.map((c)=>{	 
     let minD=999999999999;
	 if (c.latitude!=0) minD=calculateDistance(coords.latitude,coords.longitude,c.latitude,c.longitude);
	 if (minD<minDistance) {
	   minDistance=minD;
	   minCity=c.cname;
	 }
  });
  console.log('distance',minCity,minDistance);
  if (minDistance<80) return minCity;
  else return 'Canada';
}

const AddLocationUsers = () => {
    const navigate = useNavigate();
    const [images, setImages] = useState([]);
    const [venueName, setVenueName] = useState('');
    const [proTip, setProTip] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState(localStorage.getItem('search'));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);

	const [cities, setCities] = useState([])
	const [citiesO, setCitiesO] = useState([])
	const [city, setCity] = useState('')

	const [showmore, setShowmore] = useState(false)
	const [userInput, setUserInput] = useState('')
	const inputRef = useRef("");


	const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });
  
  useEffect(() => {
	let c=lscache.get('coords');
	console.log('getcoords',coords,c,citiesO.length);
    if (coords && c === null ) {
	  if (citiesO.length>0) lscache.set('coords',{'lat':coords.latitude,'lon':coords.longitude},1);

	  let ucity=lscache.get('city');	
	  console.log('ucity',ucity);
	  if (ucity != null) { setCity(ucity); console.log('ucity',ucity); inputRef.current.placeholder=ucity;}
	  else {
		    console.log('getNearestCity');
		    let nearestcity = getNearestCity(coords,citiesO);
			if (nearestcity!=='Canada') {
		      setCity(nearestcity); 
			  inputRef.current.placeholder=nearestcity;
		      lscache.set('city',nearestcity,1); // refresh current city every 1min 
			}
		  }
	}
  }, [coords,citiesO]);

	useEffect(() => {
        fetchCities();
    }, []);

	const fetchCities = () => {
		var requestOptions = {
		  method: 'GET',
		  redirect: 'follow'
		};
		fetch(getUrl("cities"), requestOptions)
		  .then(response => response.text())
		  .then(result => { let c=JSON.parse(result);  
				setCitiesO(c);
				setCities(c.map(city=>{return(city.cname)}));  
				} )
		  .catch(error => console.log('error', error));
		let ucity=lscache.get('city');
		
		if (ucity != null) { setCity(ucity); console.log('ucity',ucity); inputRef.current.placeholder=ucity; }
	  };

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = {
            venueName,
            images,
            proTip,
            description,
            category,
        };

        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "text/plain");
            myHeaders.append('Authorization', localStorage.getItem('accessToken'))

			const raw = JSON.stringify({
            "PlaceName": formData.venueName,
            "Suggestion": formData.proTip,
            "Pdescription": formData.description,
            "PType": formData.category,
			"City" : city,
			});

            

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(getUrl('postLocation'), requestOptions)
                .then(response => response.text())
                .then(result => {
                    console.log('submit success');
                    setVenueName('');
                    setImages([]);
                    setProTip('');
                    setDescription('');
                    setCategory('');
					handleSubmitClick();
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleImageUpload = (event) => {
        const uploadedImages = Array.from(event.target.files);
        setImages([...images, ...uploadedImages]);
    };

    const saveFormData = (data) => {
        // check if data already exists in localStorage
        const existingData = localStorage.getItem('formData');
        if (existingData) {
            const parsedData = JSON.parse(existingData);
            // append new form data to existing data
            const updatedData = [...parsedData, data];
            localStorage.setItem('formData', JSON.stringify(updatedData));
        } else {
            // create a new array with the form data
            const newData = [data];
            localStorage.setItem('formData', JSON.stringify(newData));
        }
    };

    const handleSubmitClick = () => {
        setIsModalOpen(true);
        setIsOverlayVisible(true);
    };
    const handleModalClose = () => {
        setIsModalOpen(false);
        setIsOverlayVisible(false);
    };

    const onSChange = (e) => {
    const suggestions = cities;
    const userInput = e.currentTarget.value;    
    setUserInput(userInput);

  };
  const onSClick = (e) => {
      console.log('onSClick',e.target.childNodes[0].nodeValue);
      if (e.target.childNodes[0].nodeValue=='more') setShowmore(true);
      else if (e.target.childNodes[0].nodeValue=='less') setShowmore(false);
      else {
        setCity(e.target.childNodes[0].nodeValue);
        lscache.set('city',e.target.childNodes[0].nodeValue,5); // keep selected city for 5 min
        setUserInput('');  
		inputRef.current.placeholder=e.target.childNodes[0].nodeValue;
      }
  };
  const suggestionsListComponent = (e,showmore) => {  
	  console.log('e',e);
     let renderSuggestions = [];
     if (!userInput) return (<div></div>);
    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = cities.filter((suggestion) =>
      suggestion.toLowerCase().startsWith(userInput.toLowerCase())
    );
	if (filteredSuggestions.length==1) { //auto complete
		setCity(filteredSuggestions[0]);
        lscache.set('city',filteredSuggestions[0],5); // keep selected city for 5 min
        setUserInput('');  
		inputRef.current.placeholder=filteredSuggestions[0];
		return (<div></div>);
	}
    if (filteredSuggestions.length > 3 && showmore) {
         renderSuggestions = filteredSuggestions
    } else renderSuggestions = filteredSuggestions.slice(0,3);
    return (<div style={{ display: "flex", "flex-wrap": "wrap", width: "95vw", 'margin-left':'4vw' }}>
		<div className="suggestion-active" style={{'background-color':'#d3a6e5'}}onClick={onSClick} >Canada</div>
        {renderSuggestions.map((suggestion, index) => {
              let className;
              className = "suggestion-active";
              return (
                <div className={className} key={suggestion} onClick={onSClick}>
                  {suggestion}
                </div>
              );
            })} 
          {filteredSuggestions.length>3 && !showmore ? 
              <div className="suggestion-active" style={{'background-color':'#f1c40f'}}onClick={onSClick} >more</div> 
              :
              filteredSuggestions.length>3 ? <div className="suggestion-active" style={{'background-color':'#f1c40f'}}onClick={onSClick} >less</div> :''
          }     
    
       </div>);
  };

    return (
        <div>
            <DesktopContainer />
            <div className="mobile-container" style={{ overflow: 'hidden' }}>
                <BackButton
                    onClick={() => navigate('/listview')}
                    style={{ marginTop: '4vh', marginLeft: '3vw' }}
                />
                <form className="formContainer-USERS" onSubmit={handleSubmit}>
                    <div className="formGroup-USERS">
                        <label htmlFor="category-USERS" className="formLabel-USERS">
                            Category:
                        </label>
                        <Select
                            id="category-USERS"
                            className="formInput-USERS"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            displayEmpty
                            fullWidth
                            style={{ height: '6vh', width: '82vw' }}
                        >
                            <MenuItem value="" disabled>
                                Select Category
                            </MenuItem>
                            <MenuItem value="entertainment">Entertainment</MenuItem>
                            <MenuItem value="food">Food</MenuItem>
                        </Select>
                    </div>
                    <div className="formGroup-USERS">
                        <label htmlFor="venueName-USERS" className="formLabel-USERS">
                            Venue Name:
                        </label>
                        <input
                            type="text"
                            id="venueName-USERS"
                            className="formInput-USERS"
                            value={venueName}
                            onChange={(e) => setVenueName(e.target.value)}
                            required
                        />
                    </div>

					<div className="formGroup-USERS">
                        <label htmlFor="venueName-USERS" className="formLabel-USERS">
                            City:
                        </label>
                        <Fragment>
							<div style={{display:'-webkit-box', 'padding-left':'0vw'}}>
							<input className="formInput-USERS"
							  type="text"
							  onChange={onSChange}          
							  value={userInput}
							  style={{ height:'5vh',width: '82vw', padding:'10px','border-radius':'4px',display:'block'}}
							  placeholder=" 🔍 Select a City"
							  ref={inputRef}
							/>
							<div style={{
									position: 'relative',
									right: '25vw',
									top: '1.1vh',
									display: 'flex',
									'background-color': '#9ff196',
									width: '23vw',
									height: '7vw',
									'border-radius': '8px',
									'justify-content': 'center',
								}}><p style={{'align-self':'center','font-size':'small'}}>{city}</p></div>
							</div>
							{suggestionsListComponent(userInput,showmore)}	    
					  </Fragment>
                    </div>

                    <div className="imagePreviewContainer-USERS">
                        {images.map((image, index) => (
                            <img
                                key={index}
                                src={URL.createObjectURL(image)}
                                alt={`Image ${index + 1}`}
                                className="formImagePreview-USERS"
                            />
                        ))}
                    </div>
                    
                    <div className="formGroup-USERS">
                        <label htmlFor="description-USERS" className="formLabel-USERS">
                            Describe the Place:
                        </label>
                        <TextareaAutosize
                            id="description-USERS"
                            rows="10"
                            className="formTextArea-USERS"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            style={{ height: '12vh', width: '82vw' }}
                        />
                    </div>
					<div className="formGroup-USERS">
                        <label htmlFor="proTip-USERS" className="formLabel-USERS">
                            <img
                                src="../assets/logo.png"
                                alt="logo"
                                style={{ display:'none',height: '30px', width: '38px', marginRight: '10px' }}
                            />
                            Recommendation:
                        </label>
                        <TextareaAutosize
                            id="proTip-USERS"
                            rows="5"
                            className="formTextArea-USERS"
                            value={proTip}
                            onChange={(e) => setProTip(e.target.value)}
                            style={{ height: '8vh', width: '82vw' }}
                        />
                    </div>
                    <button type="submit" className="submitButton-USERS" on_Click={handleSubmitClick}>
                        Submit
                    </button>
                </form>

                {/* open redeemmodal */}
                {isModalOpen && (
                    <div className="modal-container">
                        <div className="fullscreen-modal-USERS">
                            <div className="close-icon">
                                <CloseIcon onClick={handleModalClose} style={{ color: 'black', zIndex: '999' }} />

                            </div>
                            <h2 className='h2-modal-USERS'> +2000pts</h2>
                            <img src='../assets/gem2.gif' alt='gem' className='gem-USERS' />
                            <p className='thankyou-USERS'>Thank you for suggesting a place. Check in 1 hour to see if it's up for voting.</p>
                            <button className='navigate-btn' onClick={() => navigate('/suggestion')}>Navigate To Homepage</button>

                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default AddLocationUsers;
