import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Auth } from 'aws-amplify';
import getUrl from '../LambdaUrls';

const Scoreboard = ({ style, onClick, className, refresh }) => {
    let navigate = useNavigate();

    const [score, setScore] = useState(0);
	const [envSignature, setSignature] = useState('Score');
    const [isUserSignedIn, setIsUserSignedIn] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
	setSignature(getUrl('signature'));
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/plain");

        var raw = "{\r\n  \"email\": \"" + String(localStorage.getItem('email')) + "\"\r\n}";

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        var Userscore = 0
        fetch(getUrl('postScore'), requestOptions)
            .then(response => response.text())
            .then(result => setScore(JSON.parse(result)[0][0]))
            .catch(error => console.log('error', error));
        checkUserSignedIn();
    }, [refresh]);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
		
    }, []);

    const checkUserSignedIn = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            setIsUserSignedIn(true);
            localStorage.setItem('accessToken',user.signInUserSession.idToken.jwtToken);
            console.log("signed in")
        } catch (error) {
            setIsUserSignedIn(false);
            console.log("notsignedin")
        }
    };

    const styling = {
        backgroundColor: '#879fcf',
        color: 'white',
        fontWeight: 400,
        fontSize: screenWidth < 300 ? '17px' : '24px',
        width: '50vw',
        height: screenWidth <= 375 ? '6vh' : '5vh',
        borderRadius: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    const mergedStyles = {
        ...styling,
        ...style, // merge the provided style prop
    };

    return (
        <h2 onClick={onClick} className={className}  style={mergedStyles}>{envSignature} {score.toLocaleString()}</h2>
    )
}

export default Scoreboard;
