import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from "react";
import { DesktopContainer } from './components/DesktopContainer';
import './suggestions.css';
// import ModelViewer from './ModelViewer';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import getUrl from './LambdaUrls';
import { CloseButton } from "./components/Buttons/CloseButton";
import { FeedbackButton } from './components/Buttons/FeedbackButton';
import { HomeButton } from "./components/Buttons/HomeButton";
import { MapButton } from "./components/Buttons/MapButton";
import { InfoButton } from "./components/Buttons/InfoButton";
import { UserButton } from "./components/Buttons/UserButton";
import Scoreboard from "./components/Scoreboard";
import { getUserInfo } from './helper/profileAPIHelper';
export default function Suggestion() {
  const [score, setScore] = useState(0);
  const [isUserSignedIn, setIsUserSignedIn] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPendingPoints, setPendingPoints] = useState(false);
  const [pending, setPending] = useState({ 'fullname': '', 'point': 0, 'place': 0, 'voter': 0 });
  const [activeModalIndex, setActiveModalIndex] = useState(0);
  const [neverShowAgain, setNeverShowAgain] = useState(false);
  const [birthdateExists, setBirthdateExists] = useState(false);
  const [modals, setModals] = useState([

    (
      <div className="modal-content-holder">
        <img src="/assets/picture.png" alt="Image for Modal 2" className="image-modal" />
        <p className="modal-header">Add Pictures</p>
        <div className="points-holder">500 Points</div>
        <p className="modal-instruction">Each time you add a picture at a Hidden Gem you earn points</p>
      </div>
    ),
    (
      <div className="modal-content-holder">
        <img src="/assets/map-2.png" alt="Image for Modal 2" className="image-modal" />
        <p className="modal-header">Add Your Favourite Place</p>
        <div className="points-holder">2000 Points</div>
        <p className="modal-instruction">Build up the platform by adding  your favourite places</p>
      </div>
    ),
    (
      <div className="modal-content-holder">
        <img src="/assets/vote.png" alt="Image for Modal 2" className="image-modal" />
        <p className="modal-header">Vote on New Places</p>
        <div className="points-holder">2000 Points</div>
        <p className="modal-instruction">We give you the power to decide which places become certified Hidden Gemz</p>
      </div>
    ),
    (
      <div className="modal-content-holder instructions">
        <p style={{ fontSize: '1.3rem' }}>Perks for Voting</p>
        <p style={{ marginTop: '-2vh', fontWeight: '300', marginLeft: '12vw' }}>1. 1000 points per picture
          <br />
          2. 2000 points to vote
          <br />
          3. With each vote <span style={{ fontWeight: '400' }}>$1</span> goes to charity</p>
        <div className="empty-space">
          <p style={{ fontSize: '1.1rem' }}>POWERED BY</p>
          <img src='./assets/voto.png' alt='votologo' style={{ width: '90px', height: '20px', marginLeft: '10px' }} />
        </div>
      </div>
    ),
    (
      <div className="modal-content-holder">
        <img src="/assets/redeempts.png" alt="Image for Modal 2" className="image-modal im-HG" />
        <p className="modal-header">Redeem your Points</p>
        <div className="points-holder">Free Gifts</div>
        <p className="modal-instruction">Use the points you've collected to get free things in the City, on us!</p>
      </div>
    ),
    (
      <div className="modal-content-holder">
        <img src="/assets/logo2.png" alt="Image for Modal 2" className="image-modal im-HG" />
        <p className="modal-header">Get a Gem Card</p>
        <div className="points-holder">DISCOUNTS</div>
        <p className="modal-instruction">Show your gem card for exclusive perks and discounts. Find them online and in stores</p>
      </div>
    ),
  ]);

  const handleInfoButtonClick = () => {
    setActiveModalIndex(0); // reset to the first modal 
    setIsModalOpen(true);
  };

  const handleSkip = () => {
    if (activeModalIndex < modals.length - 1) {
      setActiveModalIndex(activeModalIndex + 1);
    } else {
      if (neverShowAgain) {
        // save the users pref not to show the modal again
        localStorage.setItem('neverShowModalAgain', 'true');
        console.log('Preference saved:', localStorage.getItem('neverShowModalAgain'));
      }
      setIsModalOpen(false);
    }
  };


  useEffect(() => {
    // Check user's pending points
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");
    myHeaders.append("Authorization", String(localStorage.getItem('accessToken')));
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      //body: 
      redirect: 'follow',
    };
    fetch(getUrl('userpendingpoints'), requestOptions)
      .then(response => response.text())
      .then((result) => {
        console.log(result);
        setPending(JSON.parse(result));
        if (JSON.parse(result).pending_points > 0) setPendingPoints(true);
      })
      .catch(error => console.log('error', error));
  }, []);

  useEffect(() => {
    getUserInfo().then(data => {
      const birthdateFromApi = data['birthdate']; // replace 'birthdate' with the actual column name
      const [year, month, day] = birthdateFromApi.split('-');
      const birthdate = new Date(year, month - 1, day); // month is zero-based

      if (!birthdateFromApi) {
        // birthdate is empty, set a state variable to false
        setBirthdateExists(false);
      } else {
        setBirthdateExists(true);
      }

      console.log(birthdate);
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");

    var raw = "{\r\n  \"email\": \"" + String(localStorage.getItem('email')) + "\"\r\n}";

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    var Userscore = 0
    fetch(getUrl("postScore"), requestOptions)
      .then(response => response.text())
      .then(result => setScore(JSON.parse(result)[0][0]))
      .catch(error => console.log('error', error));
    checkUserSignedIn();
  }, []);

  const checkUserSignedIn = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      setIsUserSignedIn(true);
      if (!birthdateExists) {
        if (!localStorage.getItem('toast')) {
          console.log("we should have displayed")
          toast.info(
            <div>
              Welcome back! Go to <a href="/settings">Profile Settings</a> to add important information for your profile!
            </div>,
            {
              position: toast.POSITION.BOTTOM_LEFT
            }
          );
          localStorage.setItem('toast', 'true')
        }
      }
      console.log("signed in")
    } catch (error) {
      setIsUserSignedIn(false);
      console.log("notsignedin")
    }
  };

  let navigate = useNavigate();

  const generateRandomString = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+=-';
    let randomString = '';
    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters.charAt(randomIndex);
    }
    return randomString;
  };

  const handleUserButtonClick = () => {
    const randomNumbers = generateRandomString();
    navigate(`/user`);
  };

  const handleOnPendingAccept = () => {
    // Accept pending points
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");
    myHeaders.append("Authorization", String(localStorage.getItem('accessToken')));
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      //body: 
      redirect: 'follow',
    };
    fetch(getUrl('userpendingpoints'), requestOptions)
      .then(response => response.text())
      .then((result) => {
        console.log(result);
        window.location.reload();
      })
      .catch(error => console.log('error', error));
  }

  return (
    <div>
      <DesktopContainer />
      <div className="Suggestions mobile-container">
        <div className="top-group tg-s">
          <HomeButton onClick={() => navigate('/')} />
          <Scoreboard onClick={() => navigate('/redeem')} />
          <UserButton onClick={handleUserButtonClick} />

        </div>
        <img className="map-il" src="./assets/Map-illustration.png" alt="" />
        <h4 className='QA-message' >Which hidden gem do you want to find first?</h4>

        <div className="buttonWrapper">
          <button
            className="SuggestionsBtn bluebtn"
            onClick={() => {
              window.location.href = "/PlaceToEat"
              localStorage.setItem('search', 'food')
            }}
          >
            <IconButton><SearchIcon style={{ color: 'white' }} className="search" /></IconButton>
            Places to Eat
          </button>
          <button
            className="SuggestionsBtn yellowbtn"
            onClick={() => {
              window.location.href = "/FunThingsToDo"
              localStorage.setItem('search', 'entertainment')
            }}
          >
            <IconButton><SearchIcon style={{ color: 'white' }} className="search" /></IconButton>
            Fun Things to Do
          </button>
          <button
            className="SuggestionsBtn redbtn"
            onClick={() => {
              window.location.href = "/events"
              localStorage.setItem('search', 'entertainment')
            }}
          >
            <IconButton><SearchIcon style={{ color: 'white' }} className="search" /></IconButton>
            Upcoming
          </button>
          <button
            className="SuggestionsBtn greenbtn"
            onClick={() => {
              window.location.href = "/voting"
              localStorage.setItem('search', 'entertainment')
            }}
          >
            <IconButton><SearchIcon style={{ color: 'white' }} className="search" /></IconButton>
            Voting
          </button>
        </div>
        <InfoButton
          className="info-button"
          onClick={handleInfoButtonClick}
        />
        <FeedbackButton onClick={() => navigate('/newsfeed')} className='rank-button' />
        <MapButton onClick={() => navigate('/mapAll')} className='map-button' />
        {/* display modal */}
        {isModalOpen && (
          <div className="modal-container">
            <div className="modal">
              <CloseButton onClick={() => setIsModalOpen(false)} className='close-button' />
              <div className="modal-content">
                <p>{modals[activeModalIndex]}</p>
                {/* skip to the next modal */}
                {activeModalIndex <= modals.length - 1 && (
                  <div
                    className="bottom-div-holder"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <label className="never-show-again" >
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setNeverShowAgain(e.target.checked);
                          if (e.target.checked) {
                            setIsModalOpen(false);
                          }
                        }}
                      />
                      Never show again

                    </label>
                    <div
                      className="next-div"
                      style={{ display: 'flex', justifyContent: 'end', textAlign: 'center', cursor: 'pointer' }}
                      onClick={handleSkip}
                    >
                      Next
                      <KeyboardDoubleArrowRightIcon style={{ transform: 'scale(1.2)' }} />
                    </div>
                  </div>

                )}
              </div>
            </div>
          </div>
        )}


        {/* display pending points  */}
        {isPendingPoints && (
          <div className="modal-container" style={{ 'font-size': '18px' }}>
            <div className="modal" style={{ 'height': '70vw' }} >
              <CloseButton onClick={() => setPendingPoints(false)} className='close-button' />
              <div className="modal-content">
                <p style={{ 'font-weight': 'bold' }}>Congrats {pending.fullname}! </p>
                <p>{pending.voter} people</p><p>voted on a place you added in</p>
                <p>you just earned +{pending.pending_points} pts</p>
                <button onClick={handleOnPendingAccept} >Accept</button>
              </div>
            </div>
          </div>
        )}


      </div>
      {/* <ModelViewer className='background' /> */}
    </div>
  );
}
