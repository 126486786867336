import { DirectionsRenderer, GoogleMap, useJsApiLoader ,Marker} from '@react-google-maps/api';
import React, { useLayoutEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import getUrl from './LambdaUrls';
import { BackButton } from './components/Buttons/BackButton';
import { RankButton } from "./components/Buttons/RankButton";
import { FeedbackButton } from './components/Buttons/FeedbackButton';
import Scoreboard from './components/Scoreboard';
/*global google*/
import './map.css';

const containerStyle = {
  width: '100vw',
  height: '65vh'
};


function Map() {
  const navigate = useNavigate();
  const { placeID } = useParams();
  const [cplace, setPlace] = useState(0);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDjbVgoqH2yW8dWMRW3GOli40Kyiyyc54o"
  })
  const [map, setMap] = React.useState(null);
  const [pos, setPos] = React.useState(null);
  const [directions, setDirections] = React.useState(null);
  const [gps, setGPS] = React.useState(false);

  useLayoutEffect(() => {
    fetchData();
  }, [map, gps]);

  const fetchData = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");
    var raw = JSON.stringify({ "PlaceID": placeID, "email": localStorage.getItem('email') });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch(getUrl("postPlace"), requestOptions)
      .then(response => response.text())
      .then(result => {
        let tmp = JSON.parse(result);
        setPlace(tmp);
        console.log('CPLACE', tmp);
        map?.panTo({ lat: +tmp.latitude, lng: +tmp.longitude });
        if (gps) { myNavigate(tmp.latitude, tmp.longitude); }
      })
      .catch(error => console.log('error', error));
  }

  const myNavigate = (lat, lng) => {
    let travelMode = localStorage.getItem('TRANSIT');
    if (!travelMode) { travelMode = google.maps.TravelMode.DRIVING; localStorage.setItem('TRANSIT', travelMode); }
    console.log('TRANSIT', travelMode);
    const service = new google.maps.DirectionsService();
    service.route(
      { destination: { lat: +lat, lng: +lng }, origin: { lat: +pos.coords.latitude, lng: +pos.coords.longitude }, travelMode: travelMode },
      (result, status) => {
        console.log('route', status, result);
        if (status==='OK' && result) setDirections(result);
      }
    );
  }

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);

  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  navigator.geolocation.getCurrentPosition(function (position) {
    //console.log('getCurrentPosition', position);
    setPos(position);
    setGPS(true);
  });
  //const DirectionsService = new google.maps.DirectionsService();

  return isLoaded ? (
    <div>
      <div className="newsfeed-TG">
        <BackButton onClick={() => navigate(-1)} />
        <Scoreboard onClick={() => navigate('/redeem')} />
        <FeedbackButton onClick={() => navigate('/newsfeed')} />
      </div>

      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={17}
        onLoad={onLoad}
        onUnmount={onUnmount}
        //center = {{lat:51.05209657516054, lng: -114.07212608997408}}
        options={{ disableDefaultUI: true, clickableIcons: true }}
      >

        { /* Child components, such as markers, info windows, etc. */}
        {directions && <DirectionsRenderer directions={directions} options={{suppressMarkers: true,suppressBicyclingLayer:true}} />}

        <Marker position={{lat:+cplace.latitude, lng:+cplace.longitude}} label={''} opacity={0.92} icon={{url:'https://cluez.ca/assets/logo3blue.png',scaledSize: new google.maps.Size(60, 60),anchor: new google.maps.Point(30, 30)}}	  		/> 

      </GoogleMap>

	
      <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around', marginTop:'20px'}}>
        <button className={gps?localStorage.getItem('TRANSIT')==='WALKING'?'transportation':'transportation-blue':'transportation-gray'} onClick={() => { localStorage.setItem('TRANSIT', google.maps.TravelMode.WALKING); myNavigate(cplace.latitude, cplace.longitude); }}>Walk</button>
        <button className={gps?localStorage.getItem('TRANSIT')==='BICYCLING'?'transportation':'transportation-blue':'transportation-gray'}  onClick={() => { localStorage.setItem('TRANSIT', google.maps.TravelMode.BICYCLING); myNavigate(cplace.latitude, cplace.longitude); }}>Bike</button>
        <button className={gps?localStorage.getItem('TRANSIT')==='DRIVING'?'transportation':'transportation-blue':'transportation-gray'}  onClick={() => { localStorage.setItem('TRANSIT', google.maps.TravelMode.DRIVING); myNavigate(cplace.latitude, cplace.longitude); }}>Car</button>
        <button className={gps?localStorage.getItem('TRANSIT')==='TRANSIT'?'transportation':'transportation-blue':'transportation-gray'} onClick={() => { localStorage.setItem('TRANSIT', google.maps.TravelMode.TRANSIT); myNavigate(cplace.latitude, cplace.longitude); }}>Bus</button>
      </div>
	 {gps && (<p>Distance : {directions?.routes[0].legs[0].distance.text}  -  Duration : {directions?.routes[0].legs[0].duration.text}</p>)}
	{ !gps && (<p>Enable location service to see the direction to {cplace.placename} in real-time</p>)}
	<button className='transportation' style={{width:'300px', 'margin-left':'10vw'}} 
	onClick={() => { 
			localStorage.setItem('lat', cplace.latitude); 
			localStorage.setItem('long', cplace.longitude); 
			window.location = '/ar7.html';
			}}>Tap to find using AR</button>
    </div>
  ) : <></>
}
export default React.memo(Map)
