import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import CloseIcon from '@mui/icons-material/Close';
import { Auth } from 'aws-amplify';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FacebookShareButton } from "react-share";
import getUrl from './LambdaUrls';
import { BackButton } from './components/Buttons/BackButton';
import { SettingButton } from './components/Buttons/SettingButton';
import { DesktopContainer } from './components/DesktopContainer';
import Scoreboard from './components/Scoreboard';
import './user.css';

const User = () => {
    const [userEmail, setUserEmail] = useState('');
    const [isUserSignedIn, setIsUserSignedIn] = useState(false);
    const [score, setScore] = useState(0);
    const [isPlaceModalOpen, setIsPlaceModalOpen] = useState(false);
    const [interests, setInterests] = useState([]);
    const [fullname, setFullname] = useState('')
    const navigate = useNavigate();
    //get value from input text
    const inputRef = useRef("");
    const [isEditingName, setIsEditingName] = useState(false);
    const [place, setPlace] = useState([])
    const [cplace, setcPlace] = useState([])
    const [placez, setPlacez] = useState([])
    const [placeid, setplaceid] = useState([])
    const [alreadyExist, setAlreadyExist] = useState(false)
    const [friends, setFriends] = useState([])
    const [reloadFriends, setReloadFriends] = useState(0)


    const getUserScore = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/plain");
        var raw = "{\r\n  \"email\": \"" + String(localStorage.getItem('email')) + "\"\r\n}";
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        var Userscore = 0
        fetch(getUrl('postScore'), requestOptions)
            .then(response => response.text())
            .then(result => setScore(JSON.parse(result)[0][0]))
            .catch(error => console.log('error', error));
        checkUserSignedIn();
    }

    useEffect(() => {
        checkUserSignedIn();
        fetchDataPOST();
        fetchTag();
        fetchEvents();
        getUserScore();
    }, []);

    useEffect(() => {
        fetchFriends();
    }, [reloadFriends]);

    const checkUserSignedIn = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            console.log('User:', user);
            setIsUserSignedIn(true)
            setUserEmail(user.attributes.email);
        } catch (error) {
            console.log('Error:', error);
            navigate('/signin')
        }
    };

    const fetchFriends = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", String(localStorage.getItem('accessToken')));
        myHeaders.append("Content-Type", "text/plain");
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        }
        fetch(getUrl('profileFriends'), requestOptions)
            .then(response => response.text())
            .then(result => {
                setFriends(JSON.parse(result))
                console.log('friends', JSON.parse(result));
            })
            .catch(error => console.log('error', error));
    }

    const fetchEvents = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", String(localStorage.getItem('accessToken')));
        myHeaders.append("Content-Type", "text/plain");
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
        };
        fetch(getUrl('getFullname'), requestOptions)
            .then(response => response.text())
            .then(result => {
                var tmp = JSON.parse(result);
                setFullname(tmp.fullname);
                if ((tmp.fullname !== null) && (tmp.fullname !== undefined)) {
                    navigate(`/user/${JSON.parse(result).fullname}`)
                }

                if (tmp.public) {
                    setSelectedVisibility('public');
                } else {
                    setSelectedVisibility('private');
                }
            })
            .catch(error => console.log('error', error));
        fetch(getUrl('getFav'), requestOptions)
            .then(response => response.text())
            .then(result => setPlace(JSON.parse(result)))
            .catch(error => console.log('error', error));
        fetch(getUrl('getCheckin'), requestOptions)
            .then(response => response.text())
            .then(result => setcPlace(JSON.parse(result)))
            .catch(error => console.log('error', error));
    };

    const [error, setError] = useState('');

    const handleSignOut = async () => {
        try {
            await Auth.signOut();
            setIsUserSignedIn(false);
            localStorage.clear();
            window.location.href = '/';
        } catch (error) {
            setError(error.message);
        }
    };

    const fetchTag = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/plain");
        myHeaders.append("Authorization", String(localStorage.getItem('accessToken')));

        var raw = "{\r\n    \"PlaceID\": " + localStorage.getItem("place") + ", \r\n \"email\": \"" + String(localStorage.getItem('email')) + "\"\r\n}";

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            //body: raw,
            redirect: 'follow'
        };

        fetch(getUrl("getTags"), requestOptions)
            .then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                setInterests(data);
            })
            .catch(error => console.log('error', error));
    };


    const handleEditName = () => {
        if (isEditingName) {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", String(localStorage.getItem('accessToken')));
            myHeaders.append("Content-Type", "text/plain");
            var raw = "{\r\n    \"fullname\": \"" + inputRef.current.value + "\", \r\n \"email\": \"" + String(localStorage.getItem('email')) + "\"\r\n}";
            var requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: raw,
                headers: myHeaders
            }
            fetch(getUrl('getFullname'), requestOptions)
                .then(response => {
                    if (!isEditingName) {
                        setIsEditingName(true);
                    } else {
                        if (checkForSpecialCharacters(fullname)) {
                            console.log('contains special characters')
                        } else if (response.status !== 200) {
                            setAlreadyExist(true);
                            setIsEditingName(true);
                            console.log('status not 200');
                        } else {
                            setAlreadyExist(false);
                            setIsEditingName(false);
                            console.log('status is 200');
                            return response.text();
                        }
                    }
                })
                //stay in edit mode if code != 200
                .catch(error => {
                    setAlreadyExist(true);
                    setIsEditingName(true);
                    console.log('catch error');
                });
        } else {
            // start editing
            setIsEditingName(true);
        }
    };

    //check for special characters
    const checkForSpecialCharacters = (str) => {
        const specialCharacters = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\|/]+/;
        return specialCharacters.test(str);
    }

    const [images, setImages] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [uploadStatus, setUploadStatus] = useState(null);

    console.log(images, 'images')


    const handleNext = () => {
        let nextIndex = currentIndex + 1;
        if (nextIndex === images.length) {
            nextIndex = 0
        }
        setCurrentIndex(nextIndex);
    };
    const handlePrev = () => {
        let previousIndex = currentIndex - 1;
        if (previousIndex < 0) {
            previousIndex = images.length - 1;
        }
        setCurrentIndex(previousIndex);
    };
    // const isLastImage = currentIndex === images.length - 1;
    const isLastImage = currentIndex === images.length - 1;
    const renderUploadButton = isLastImage && isUserSignedIn;


    const fetchDataPOST = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/plain");
        myHeaders.append("Authorization", String(localStorage.getItem('accessToken')));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            // body: raw,
            redirect: 'follow'
        };

        fetch(getUrl('getPics'), requestOptions)
            .then(response => response.text())
            .then(result => {
                try {
                    const parsedResult = JSON.parse(result);
                    var imagez = []
                    var placenamez = []
                    var placeidz = []
                    if (Array.isArray(parsedResult)) {
                        parsedResult.map((placez) => {
                            imagez.push(placez.imagepath);
                            placenamez.push(placez.placename);
                            placeidz.push(placez.placeid);
                        });
                        imagez.push("https://placehold.co/150x150/FDF5EA/FDF5EA");
                        placenamez.push("")
                        setplaceid(placeidz)
                        setImages(imagez);
                        setPlacez(placenamez);
                    } else {
                        console.error('parsedResult', parsedResult);
                    }
                } catch (error) {
                    console.error('error', error);
                }
            })
            .catch(error => console.error(' error:', error));
    }

    const navigateToPlaceView = () => {
        localStorage.setItem('place', placeid[currentIndex]);
        navigate('/placeView/' + placez[currentIndex]+'/'+placeid[currentIndex]);
    };

    //selected visibility - private or public for checkins
    const [selectedVisibility, setSelectedVisibility] = useState('private');

    function handlePrivateOrPublic(visibility) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/plain");
        myHeaders.append('Authorization', localStorage.getItem('accessToken'))
        var raw = JSON.stringify({ "visibility": visibility });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(getUrl("pubicProfile"), requestOptions)
            .then(response => response.text())
            .then(data => { console.log('PUBLIC/PRIVATE', data); })
            .catch(error => console.log('error', error));
    }

    const handleFriendProfile = (friend) => {
        navigate('/user/' + friend);
    }
    const handleUnfriend = (friend_email) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/plain");
        myHeaders.append('Authorization', localStorage.getItem('accessToken'))
        var raw = JSON.stringify({ "friend_email": friend_email });
        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(getUrl("profileFriends"), requestOptions)
            .then(response => response.text())
            .then(data => { setReloadFriends(reloadFriends + 1) })
            .catch(error => console.log('error', error));
    }

    const handleAcceptFriend = (friend_email) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/plain");
        myHeaders.append('Authorization', localStorage.getItem('accessToken'))
        var raw = JSON.stringify({ "friend_email": friend_email });
        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(getUrl("profileFriends"), requestOptions)
            .then(response => response.text())
            .then(data => { setReloadFriends(reloadFriends + 1) })
            .catch(error => console.log('error', error));
    }

    //modal to see private or public
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);

    const handleModalClose = () => {
        setIsPlaceModalOpen(false);
        setIsOverlayVisible(false); // hide the grey overlay when the modal is closed

        if (selectedVisibility === 'private') {
            handlePrivateOrPublic('private');
        } else {
            handlePrivateOrPublic('public');
        }
    };

    const handleVisibilityChange = (event) => {
        setSelectedVisibility(event.target.value);
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        handleModalClose();
    }

    return (

        <div>
            <DesktopContainer />
            <div className=" mobile-container user-page" style={{ fontSize: '1.3rem', fontWeight: '300' }}>
                {isOverlayVisible && <div className="overlaypage" />}
                <div className="top-group">
                    <BackButton onClick={() => navigate("/suggestion")} />
                    <Scoreboard onClick={() => navigate('/redeem')} />
                    <SettingButton />
                </div>
                <div style={{ marginTop: '20px', marginBottom: "20px", display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }} className='email-USER'>
                    <input
                        ref={inputRef}
                        type='text'
                        placeholder='enter your name'
                        value={fullname}
                        // change class base on condition if iseditting or not
                        className={`input-name ${isEditingName ? 'editing' : ''}`}
                        onChange={(e) => {
                            setFullname(e.target.value);
                            setAlreadyExist(false);
                        }}
                        disabled={!isEditingName}
                    />
                    <button type='button' className='edit-button edit-name' onClick={handleEditName}>
                        {isEditingName ? 'Save' : 'Edit'}
                    </button>
                </div>
                {alreadyExist && (
                    <p>Name already exists, please use a different name.</p>
                )}
                {checkForSpecialCharacters(fullname) && (
                    <p>Name should not contain special characters.</p>
                )}


                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
                    <div className='total-points'>Score: {score.toLocaleString()} PTS</div>
                    <div>
                        <button className="redeem-btn" onClick={() => navigate('/redeem')}>
                            Redeem
                        </button>
                    </div>
                </div>
                {/* share to facebook */}
                <div style={{ display: 'flex', justifyContent: 'center', width: '100vw' }}>
                    <FacebookShareButton url={'https://cluez.ca/user/' + fullname} >
                        <img style={{ width: '30px' }} src='data:image/svg+xml;utf8,<svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z" fill="navy"></path><path d="M13.893 12.89l.443-2.89h-2.774V8.124c0-.79.388-1.562 1.63-1.562h1.261v-2.46s-1.144-.196-2.238-.196c-2.285 0-3.777 1.385-3.777 3.89V10h-2.54v2.89h2.54v6.988a10.073 10.073 0 003.124 0v-6.987h2.33z" full="red"></path></svg>' />
                    </FacebookShareButton>
                </div>
                {/* image carousel */}
                <div className="image-container">

                    {images.map((imageUrl, index) => (

                        <img
                            src={imageUrl.charAt(0) === '/' ? getUrl('s3imagePath') + imageUrl : imageUrl}
                            alt="Gallery"
                            className="image-con"
                            style={{
                                display: imageUrl === images[currentIndex] ? "block" : "none",
                                objectFit: "cover",
                                borderRadius: "15px",
                                border: '5px solid white',
                                boxShadow: '0px 4px 3px 1px rgba(220, 154, 87, 0.6)',
                                marginBottom: '10px'
                            }}
                        />

                    ))}



                    {renderUploadButton ? (
                        <>
                            <label htmlFor="fileInput">
                                <button
                                    style={{
                                        height: "70%",
                                        width: '60vw',
                                        cursor: "pointer",
                                        fontSize: '16px',
                                        border: 'none',
                                        borderRadius: '10px',
                                        // boxShadow: '0px -5px 0px 0px rgba(0, 0, 0, 0.2) inset',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%',
                                        zIndex: 10,
                                        // backgroundColor: 'rgb(135, 159, 207)',
                                        backgroundColor: "#fdf5ea",
                                        color: 'rgb(135, 159, 207)',
                                        display: 'block'
                                    }}

                                    onClick={() => { document.getElementById('fileInput').click() }}
                                >
                                    <img src="/assets/picture.png" alt="picture-icon" style={{ width: '18vw' }} />
                                    <h3 style={{ fontSize: '5vw', fontWeight: '500' }}>Upload Pictures In The Venues</h3>
                                </button>
                            </label>

                        </>
                    ) : (
                        <>
                            {isLastImage && (

                                <button
                                    style={{
                                        height: "9vh",
                                        width: '50vw',
                                        cursor: "pointer",
                                        fontSize: '14px',
                                        border: '3px solid white',
                                        borderRadius: '10px',
                                        boxShadow: '0px -5px 0px 0px rgba(0, 0, 0, 0.2) inset',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%',
                                        zIndex: 10,
                                        color: 'white',
                                        backgroundColor: '#879fcf',
                                        fontSize: '16px'
                                    }}

                                    onClick={() => { navigate("/signin") }}
                                >
                                    <span style={{ textDecoration: 'underline', color: 'white' }}>Sign In</span> to upload images
                                </button>
                            )}

                        </>
                    )}
                </div>
                <button onClick={(handlePrev)} className="icons-USER left" >
                    <ChevronLeft style={{ transform: 'scale(2, 2.5)' }} />
                </button>
                <button onClick={handleNext} className="icons-USER right" >
                    <ChevronRight style={{ transform: 'scale(2, 2.5)' }} />
                </button>


                <div className="dots">
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "0.5rem", alignContent: 'center' }}>
                        {images.map((slide, image) => (
                            <div className="image-dots"
                                style={{
                                    transition: "all 0.2s",
                                    width: "0.75rem",
                                    height: "0.75rem",
                                    backgroundColor: currentIndex === image ? "white" : "#77320A",
                                    borderRadius: "50%",
                                    padding: currentIndex === image ? "0.25rem" : "0",
                                    opacity: currentIndex === image ? "1" : "0.5",
                                    cursor: 'pointer',
                                    marginBottom: '2vh',
                                    boxShadow: currentIndex === image ? "0px 3px 3px 1px rgba(198, 126, 40, 0.6)" : "none"
                                }}
                                onClick={() => setCurrentIndex(image)}
                            />
                        ))}

                    </div>
                </div>

                {/* return places according to pictures */}
                <p
                    style={{ textAlign: 'center' }}
                    onClick={navigateToPlaceView}
                    className="place-name-images"
                >
                    {placez[currentIndex]}
                </p>


                <div className='bottom-holder'>
                    <div className='column-divide'>
                        <div className='left-container fav-place'><p>Favorite</p></div>
                        <ul className='right-container' style={{ listStyle: 'none', padding: 0, display: 'flex', flexDirection: 'column' }}>
                            {place.map((item, index) => (
                                <li className='list-places' key={index}>
                                    {item.placename}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className='column-divide'>
                        <div className='left-container interest'><p>Interest</p></div>
                        <ul className='right-container' style={{ listStyle: 'none', padding: 0, display: 'flex', flexDirection: 'column' }}>
                            {interests
                                .filter((interest) => interest.checked === 'true')
                                .map((interest, index) => (
                                    <li className='list-places' key={index}>
                                        {interest.name}
                                    </li>
                                ))}
                        </ul>
                        <button onClick={() => navigate('/interests')} className='edit-button'>Edit</button>
                    </div>
                    <div className='column-divide'>
                        <div className='left-container checkins'><p >Check ins</p></div>
                        <ul className='right-container' style={{ listStyle: 'none', padding: 0, display: 'flex', flexDirection: 'column' }}>
                            {cplace.map((item, index) => (
                                <li className='list-places' key={index}>
                                    {item.placename}
                                </li>
                            ))}
                        </ul>
                        <button
                            className='edit-button privacy'
                            onClick={() => {
                                setIsPlaceModalOpen(true);
                                setIsOverlayVisible(true);
                            }}
                        >
                            Edit
                        </button>
                    </div>
                    <div style={{ 'padding-left': '20px' }}>
                        <div className='left-container'><p >Friends list</p></div>
                        <div >
                            {friends.filter(friend => { return friend.isfriend === 'yes' }).map((item, index) => (
                                <div className="column-divide2">
                                    <div onClick={() => { handleFriendProfile(item.friend) }}>{item.friend}</div>
                                    <div ><button className='edit-button' onClick={() => handleUnfriend(item.friend_email)} > Unfriend </button></div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div style={{ 'padding-left': '20px' }}>
                        <div className='left-container'><p >Pending</p></div>
                        <div>
                            {friends.filter(friend => { return friend.isfriend === 'no' }).map((item, index) => (
                                <div className="column-divide2">
                                    <div className='list-places' key={index}>
                                        {item.friend}
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '180px' }}>
                                        <div ><button className='edit-button' onClick={() => handleAcceptFriend(item.friend_email)} > Add </button></div>
                                        <div ><button className='edit-button' onClick={() => handleUnfriend(item.friend_email)} > Decline </button></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div style={{ width: '100vw', display: 'flex', justifyContent: 'center' }}>
                    <button
                        style={{
                            backgroundColor: '#879fcf',
                            color: 'white',
                            border: '3px solid white',
                            borderRadius: '35px',
                            height: '5vh',
                            width: '30vw',
                            boxShadow: '0px 4px 3px 1px rgba(220, 154, 87, 0.6)',
                            marginBottom: '5vh',
                            fontSize: '1.1rem',
                            marginLeft: '5vw',
                            marginTop: '20px'
                        }}
                        onClick={handleSignOut}
                    >
                        Sign Out
                    </button>
                </div>
            </div>
            {/* Modal */}
            {isOverlayVisible && (
                <div className="modal-overlay">
                    <div className="modal-USER">
                        <div className="close-icon">
                            <CloseIcon onClick={handleModalClose} style={{ color: 'white' }} />
                        </div>
                        <h3 style={{ fontWeight: '500' }}>Who do you want to see this?</h3>
                        <form onSubmit={handleFormSubmit}>
                            <label style={{ display: 'flex', alignItems: 'center', gridGap: '30vw', fontSize: '1.2rem', fontWeight: '300' }}>
                                Private
                                <input type="radio" id="private" name="visibility" value="private" checked={selectedVisibility === 'private'} onChange={handleVisibilityChange} />

                            </label><br />

                            <label style={{ display: 'flex', alignItems: 'center', gridGap: '31vw', fontSize: '1.2rem', fontWeight: '300' }}>
                                Public
                                <input type="radio" id="public" name="visibility" value="public" checked={selectedVisibility === 'public'} onChange={handleVisibilityChange} />

                            </label><br />

                            <button type="submit" className='update-privacy'>Update</button>
                        </form>



                    </div>
                </div>
            )}
        </div>

    );
};

export default User;
