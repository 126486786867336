import ListItemButton from '@mui/material/ListItemButton';
import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import getUrl from './LambdaUrls';
import { BackButton } from './components/Buttons/BackButton';
import { FeedbackButton } from './components/Buttons/FeedbackButton';
import { DesktopContainer } from './components/DesktopContainer';
import Scoreboard from './components/Scoreboard';
import './voting.css';

export default function Voting() {
    const [place, setPlace] = useState([]);
    const [isUserSignedIn, setIsUserSignedIn] = useState(false);
    const [admin, setAdmin] = useState(false);
    const [counter, setCounter] = useState(0);
    let navigate = useNavigate();

    const checkUser = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const allowedEmails = [
                'thydo@gmail.com',
                'ran@hotmail.com',
                'gold@gmail.com',
                'hi@gmail.com'
            ];
            if (allowedEmails.includes(user.attributes.email)) {
                setAdmin(true);
            } else {
                setAdmin(false);
            }
        } catch (error) {
            setAdmin(false);
        }
    };

    const checkUserSignedIn = async () => {
        try {
            await Auth.currentAuthenticatedUser();
            setIsUserSignedIn(true);
            console.log("signed in");
        } catch (error) {
            setIsUserSignedIn(false);
            console.log("not signed in");
        }
    };

    useEffect(() => {
        checkUserSignedIn();
        checkUser();
        fetchEvents();
    }, []);


    // const deletePlace = (placeId) => {
    //     // send a DELETE request to the API to remove the place
    //     var myHeaders = new Headers();
    //     myHeaders.append("Authorization", String(localStorage.getItem('accessToken')));
    //     myHeaders.append("Content-Type", "text/plain");

    //     var raw = "{\r\n    \"PlaceID\" : " + placeId + "\r\n}";
    //     console.log(raw);

    //     var requestOptions = {
    //         method: 'DELETE',
    //         headers: myHeaders,
    //         body: raw,
    //         redirect: 'follow'
    //     };

    //     fetch(getUrl("deletePlace"), requestOptions)
    //         .then(response => response.text())
    //         .then(result => console.log(result))
    //         .catch(error => console.log('error', error));
    // };

    const fetchEvents = () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(getUrl("getAllPlaces"), requestOptions)
            .then(response => response.text())
            .then(result => setPlace(JSON.parse(result)))
            .catch(error => console.log('error', error));
    };

    const navigateToPlaceView = (placeName, placeid) => {
        localStorage.setItem('place', placeid);
        navigate(`/voting-placeview/${placeName}/${placeid}`);
    };
    const navigateToPendingView = (placeName, placeid) => {
        localStorage.setItem('place', placeid);
        navigate(`/placeview/${placeName}/${placeid}`);
    };

    const renderTimeLeft = (sec) => {
        let secleft = 21 * 86400 - sec;
        let days = Math.floor(secleft / 86400);
        secleft = secleft % (86400);
        let hours = Math.floor(secleft / 3600);
        secleft = secleft % (3600);
        let mins = Math.floor(secleft / 60);
        secleft = secleft % (60);
        if (days > 0) return (<div>{days} days {hours} hours</div>);
        else if (hours > 0) return (<div>{hours} hours {mins} mins</div>);
        else return (<div>{mins} mins {secleft} secs</div>);
    }

    useEffect(() => {
        // check for news entries every 1 seconds
        const timer = setInterval(() => {
            place.map((plc) => {
                plc.seconds = plc.seconds + 1;
            });
            setCounter(counter => counter + 1);
            if (place.length > 0) {
                setPlace(place => place);
            }

        }, 1000);
        return () => clearInterval(timer);
    }, [place]);

    const renderPlaces = (places) => {
        const voting = places.filter((place) => { return ((!place.approval_status) && (place.seconds < 86400 * 21) && (place.yes_votes + place.no_votes < 25)) })
        return voting.map((place) => (
            <div
                key={place.placename}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '20px',
                    position: 'relative',
                }}
            >
                <ListItemButton
                    sx={{
                        backgroundColor: 'white',
                        width: '100%',
                        height: '10vh',
                        margin: '0 auto',
                        borderRadius: '10px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                        color: 'black',
                        fontSize: '1rem',
                        fontWeight: '400',
                        boxShadow: '0px 4px 3px 1px rgba(220, 154, 87, 0.4);',
                        transition: 'transform 0.3s ease',
                        '&:hover': {
                            transform: 'scale(1.05)',
                        },
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                    onClick={() => {
                        navigateToPlaceView(place.placename, place.placeid);
                    }}
                >
                    <button
                        onClick={() => {
                            console.log(`Voted for ${place.placename}`);
                        }}
                        className="vote-button"
                    >
                        Vote

                    </button>
                    <div className="placename-voting">
                        {place.placename.split(' ').map((word, index) => (
                            <span key={index}>
                                {index > 0 ? ' ' : ''}
                                {word}
                                {index === 1 ? <br /> : null}
                            </span>
                        ))}
                    </div>

                    <div className='countdown-time'>
                        {renderTimeLeft(place.seconds)}
                    </div>
                </ListItemButton>
                {/* {
                    admin && // If user is admin, render the delete button
                    <button
                        onClick={() => deletePlace(place.placeid)}
                        className="deleteButton"
                    >
                        Delete
                    </button>
                } */}
            </div>
        ));
    };

    const renderPendingPlaces = (places) => {
        const voting = places.filter((place) => { return ((!place.approval_status) && (place.yes_votes + place.no_votes > 0) && ((place.seconds >= 86400 * 21) || (place.yes_votes + place.no_votes >= 25))) })
        return voting.map((place) => (
            <div
                key={place.placename}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '20px',
                    position: 'relative',
                }}
            >
                <ListItemButton
                    sx={{
                        backgroundColor: 'white',
                        width: '100%',
                        height: '10vh',
                        margin: '0 auto',
                        borderRadius: '10px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                        color: 'black',
                        fontSize: '1rem',
                        fontWeight: '400',
                        boxShadow: '0px 4px 3px 1px rgba(220, 154, 87, 0.4);',
                        transition: 'transform 0.3s ease',
                        '&:hover': {
                            transform: 'scale(1.05)',
                        },
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                    onClick={() => {
                        navigateToPendingView(place.placename, place.placeid);
                    }}
                >
                    <div></div>

                    <div className="placename-voting">
                        {place.placename.split(' ').map((word, index) => (
                            <span key={index}>
                                {index > 0 ? ' ' : ''}
                                {word}
                                {index === 1 ? <br /> : null}
                            </span>
                        ))}
                    </div>
                    <div className='yes-no-counts'>
                        <p>{place.yes_votes} Yes </p>
                        <p style={{ marginTop: '-5px' }}>{place.no_votes} No</p>
                    </div>


                </ListItemButton>
                {/* {
                    admin && // If user is admin, render the delete button
                    <button
                        onClick={() => deletePlace(place.placeid)}
                        className="deleteButton"
                    >
                        Delete
                    </button>
                } */}
            </div>
        ));
    };

    return (
        <div>
            <DesktopContainer />
            <div className="mobile-container voting-container" style={{ overflow: 'hidden' }}>
                <div className='top-option-voting' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '2vh', width: '90vw', marginLeft: '3vw' }}>
                    <BackButton onClick={() => navigate('/suggestion')} />
                    <Scoreboard onClick={() => navigate('/redeem')} />
                    <FeedbackButton onClick={() => navigate('/newsfeed')} />
                </div>

                <ol style={{ padding: '12px', margin: 0, marginTop: '3vh', display: 'flex', flexDirection: 'column' }}>
                    {renderPlaces(place)}
                </ol>

                <ol style={{ padding: '12px', margin: 0, marginTop: '3vh', display: 'flex', flexDirection: 'column' }}>
                    <h3 className='voting-closed'>Pending Approval - Voting Closed</h3>
                    {renderPendingPlaces(place)}
                </ol>

                <div className='voto-con'>
                    <p style={{ paddingTop: '3px' }}>POWERED BY</p>
                    <img src='/assets/voto.png' alt='voto-logo' style={{ width: '90px', marginLeft: '10px' }} />
                </div>
            </div>
        </div>
    );
}
