import React from 'react'
import { useNavigate } from "react-router-dom";

export const HomeButton = ({ onClick, style }) => {
    let navigate = useNavigate();
    const styling = {
        cursor: 'pointer',
        borderRadius: '10px',
        color: 'white',
        border: ' 3px solid white',
        backgroundColor: '#879fcf',
        boxShadow: '0px 4px 3px 1px rgba(220, 154, 87, 0.6), 0px -5px 0px 0px rgba(0, 0, 0, 0.08) inset',
        width: '45px',
        height: '45px'
    }

    const mergedStyles = {
        ...styling,
        ...style, // merge the provided style prop
    };

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            style={mergedStyles}
            onClick={onClick}
        >

            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
            />
        </svg>
    )
}
