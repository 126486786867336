import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CloseIcon from '@mui/icons-material/Close';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ListItemButton from '@mui/material/ListItemButton';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import getUrl from './LambdaUrls';
import './newsfeed.css';
import { BackButton } from './components/Buttons/BackButton';
import { RankButton } from "./components/Buttons/RankButton";
import { DesktopContainer } from './components/DesktopContainer';
import Scoreboard from './components/Scoreboard';

import { Auth } from 'aws-amplify';


const Newsfeed = () => {
    const navigate = useNavigate();
    const [newsfeeds, setNewsfeeds] = useState([])
    const [messageInput, setMessageInput] = useState('');
    const [fullname, setFullname] = useState('');
    const [counter, setCounter] = useState(0);
    const inputRef = useRef("");
    const [replyToMessage, setReplyToMessage] = useState(null);
    const [isReplying, setIsReplying] = useState(false);
    const [isLike, setIsLike] = useState(false);
    const [qotd, setQOTD] = useState(0);
    const [showQOTD, setShowQOTD] = useState(false);
    const messageBoxQOTDRef = useRef(null);
    const [showQuestionQOTD, setShowQuestionQOTD] = useState(false);
    const QOTDinputRef = useRef("");
    const [isUserSignedIn, setIsUserSignedIn] = useState(false);


    let lastId = 0;

    useEffect(() => {
        checkUserSignedIn();
    }, []);
    const checkUserSignedIn = async () => {
        try {
            await Auth.currentAuthenticatedUser();
            setIsUserSignedIn(true);
        } catch (error) {
            setIsUserSignedIn(false);
        }
    };

    const fetchNews = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/plain");
        //myHeaders.append("Authorization", String(localStorage.getItem('accessToken')));
        const raw = JSON.stringify({ email: localStorage.getItem('email') });
        var requestOptions = {
            method: 'POST',
            redirect: 'follow',
            headers: myHeaders,
            body: raw
        };
        fetch(getUrl("getNewsfeed"), requestOptions)
            .then(response => response.text())
            .then(result => {
                setNewsfeeds(JSON.parse(result));
            })
            .catch(error => console.log('error', error));
    };

    const fetchQOTD = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/plain");
        myHeaders.append("Authorization", String(localStorage.getItem('accessToken')));
        const raw = JSON.stringify({ email: localStorage.getItem('email') });
        var requestOptions = {
            method: 'DELETE',
            redirect: 'follow',
            headers: myHeaders,
            body: raw
        };
        fetch(getUrl("qotd"), requestOptions)
            .then(response => response.text())
            .then(result => {
                const tmp = JSON.parse(result);
                setQOTD(tmp);
                setShowQuestionQOTD(localStorage.getItem('hide_qotd_' + tmp.id) ? false : true);
            })
            .catch(error => console.log('error', error));
    };

    useEffect(() => {

        fetchNews();
    }, [counter]);

    useEffect(() => {
        if (isLike)
            setIsLike(false);
        else
            inputRef.current?.scrollIntoView({ behavior: "smooth" });

    }, [newsfeeds]);

    //	useEffect(() => {
    // check for news entries every 2 seconds
    //        const timer = setInterval(() => {
    //			fetchLatestNews();
    //        }, 200000);
    //		return () => clearInterval(timer);
    //	}, []);

    useEffect(() => {
        fetchQOTD();
        let ws = new WebSocket(getUrl('newsfeedWS'));
        ws.onopen = () => console.log('ws opened');
        ws.onclose = () => console.log('ws closed');
        ws.onmessage = e => {
            setCounter((prevCounter) => prevCounter + 1);
            console.log('websocket data', e.data);
        };
        return () => {
            ws.close();
        }
    }, []);


    function convertTime(dateTimeString) {
        const date = new Date(dateTimeString);
        let currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0); // Set the time part of the current date to 00:00:00
        //console.log('CURRENT', currentDate);
        //console.log('DATETIME', date);
        //have to find 1 day in milliseconds which is 86400000; have to find this format because in javascript, dates are in unix timestamps (number of milliseconds)
        const oneDay = 24 * 60 * 60 * 1000
        const isToday = date.getTime() > currentDate.getTime();
        const isYesterday = (date.getTime() >= currentDate.getTime() - oneDay) && (date.getTime() < currentDate.getTime());
        const isSameWeek = Math.floor((currentDate - date) / (6 * oneDay)) === 0;
        let displayDateName = '';

        switch (true) {
            case isToday:
                displayDateName = 'Today';
                break;
            case isYesterday:
                displayDateName = 'Yesterday';
                break;
            case isSameWeek:
                const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
                const dayOfWeekIndex = date.getDay();
                const dayOfWeek = daysOfWeek[dayOfWeekIndex];
                displayDateName = dayOfWeek;
                break;
            default:
                const options = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };
                displayDateName = date.toLocaleDateString(undefined, options);
                break;
        }

        const hours = date.getHours();
        const minutes = date.getMinutes();
        const amORpm = hours >= 12 ? 'PM' : 'AM';

        let formattedHours = hours % 12;
        formattedHours = formattedHours || 12;

        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        return ` ${displayDateName} at ${formattedHours}:${formattedMinutes} ${amORpm}`;
    }

    const renderNewsfeeds = (post) => {
        switch (post.pointtype) {
            case 'User post a new message': //equivalent to if (post.pointtype === 'user post...') {}
                return (
                    <div key={post.id} style={{ marginLeft: '25px' }}>
                        <p className='submitted-date'>{convertTime(post.submitdate)}</p>

                        {post.who === 'other' ? (
                            <div className='news-frame' style={{ display: 'flex', flexDirection: 'row', }}>
                                <div className='profile-picture'></div>
                                <div className='new-message-post-content' style={{ marginLeft: '10px', width: '75vw' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '30px' }}>
                                        <p className='fullname-display' onClick={post.fullname !== 'Anonymous' ? () => navigate(`/user/${post.fullname}`) : null}>{post.fullname}</p>

                                    </div>
                                    <div>
                                        <p className='message-content'>{post.message}</p>
                                    </div>
                                    <div className='message-response' style={{ marginLeft:'18vw '}}>
                                        <div className='city-tag' onClick={() => handleReply(post)}>Reply</div>
                                        <div className='del-point' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <FavoriteOutlinedIcon
                                                style={{
                                                    // color: 'rgb(235, 102, 96)',
                                                    color: post.ilikeit === 'yes' ? 'rgb(235, 102, 96)' : 'rgb(135, 159, 207)',
                                                    cursor: 'pointer',
                                                    marginLeft: '2px',
                                                    marginRight: '4px',
													width: '3.5vw',
													marginTop: '-0.5vh',
													marginBottom: '-0.5vh',
                                                }}
                                                onClick={() => handleLike(post)}
                                            />
                                            <p style={{ margin:'0px'}}>{post.likecount}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) :
                            (
                                <div className='news-frame' style={{ display: 'flex', flexDirection: 'row', marginRight: '20px' }}>
                                    <div className='new-message-post-content-ME' style={{ marginLeft: '10px', width: '75vw' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '30px' }}>
                                            <p className='fullname-display' onClick={post.fullname !== 'Anonymous' ? () => navigate(`/user/${post.fullname}`) : null}>{post.fullname}</p>

                                        </div>
                                        <div>
                                            <p className='message-content'>{post.message}</p>
                                        </div>
                                        <div className='message-response' style={{ marginRight:'12vw '}}>
                                            <div className='city-tag' onClick={() => handleReply(post)}>Reply</div>
                                            <div className='del-point' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <FavoriteOutlinedIcon
                                                    style={{
                                                        // color: 'rgb(235, 102, 96)',
                                                        color: post.ilikeit === 'yes' ? 'rgb(235, 102, 96)' : 'rgb(135, 159, 207)',
                                                        cursor: 'pointer',
														marginLeft: '2px',
														marginRight: '4px',
														width: '3.5vw',
														marginTop: '-0.5vh',
														marginBottom: '-0.5vh',
                                                    }}
                                                    onClick={() => handleLike(post)}
                                                />
                                                <p style={{ margin:'0px'}}>{post.likecount}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='profile-picture'></div>
                                </div>
                            )}
                    </div>
                );
                break;
            case 'User reply to a message':
                return (
                    <div key={post.id} style={{ marginLeft: '25px' }}>
                        <p className='submitted-date'>{convertTime(post.submitdate)}</p>

                        {post.who === 'other' ?
                            (<div>
                                <p className='replyingto-message-OTHER'>Replying to {post.inreplyto}: {post.messagereplyto}</p>
                                <div className='news-frame' style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div className='profile-picture'></div>
                                    <div className='new-message-post-content' style={{ marginLeft: '10px', width: '75vw' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '30px' }}>
                                            <p className='fullname-display' onClick={post.fullname !== 'Anonymous' ? () => navigate(`/user/${post.fullname}`) : null}>{post.fullname}</p>

                                        </div>
                                        <div>
                                            <p className='message-content'>{post.message}</p>
                                        </div>
                                        <div className='message-response' style={{ marginLeft:'18vw '}}>
                                        <div className='city-tag' onClick={() => handleReply(post)}>Reply</div>
                                        <div className='del-point' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <FavoriteOutlinedIcon
                                                style={{
                                                    // color: 'rgb(235, 102, 96)',
                                                    color: post.ilikeit === 'yes' ? 'rgb(235, 102, 96)' : 'rgb(135, 159, 207)',
                                                    cursor: 'pointer',
                                                    marginLeft: '2px',
                                                    marginRight: '4px',
													width: '3.5vw',
													marginTop: '-0.5vh',
													marginBottom: '-0.5vh',
                                                }}
                                                onClick={() => handleLike(post)}
                                            />
                                            <p style={{ margin:'0px'}}>{post.likecount}</p>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            ) :
                            (
                                <div>
                                    <p className='replyingto-message-ME'>Replying to {post.inreplyto}: {post.messagereplyto}</p>
                                    <div className='news-frame' style={{ display: 'flex', flexDirection: 'row', marginRight: '20px' }}>
                                        <div className='new-message-post-content-ME' style={{ marginLeft: '10px', width: '75vw' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '30px' }}>
                                                <p className='fullname-display' onClick={post.fullname !== 'Anonymous' ? () => navigate(`/user/${post.fullname}`) : null}>{post.fullname}</p>

                                            </div>
                                            <div>
                                                <p className='message-content'>{post.message}</p>
                                            </div>
                                            <div className='message-response' style={{ marginRight:'12vw '}}>
                                            <div className='city-tag' onClick={() => handleReply(post)}>Reply</div>
                                            <div className='del-point' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <FavoriteOutlinedIcon
                                                    style={{
                                                        // color: 'rgb(235, 102, 96)',
                                                        color: post.ilikeit === 'yes' ? 'rgb(235, 102, 96)' : 'rgb(135, 159, 207)',
                                                        cursor: 'pointer',
														marginLeft: '2px',
														marginRight: '4px',
														width: '3.5vw',
														marginTop: '-0.5vh',
														marginBottom: '-0.5vh',
                                                    }}
                                                    onClick={() => handleLike(post)}
                                                />
                                                <p style={{ margin:'0px'}}>{post.likecount}</p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className='profile-picture'></div>
                                    </div>
                                </div>
                            )}


                    </div>
                )
                break;
            case 'User reply to qotd':
                return (
                    <div key={post.id} style={{ marginLeft: '25px' }}>
                        <p className='submitted-date'>{convertTime(post.submitdate)}</p>
                        {post.who === 'other' ?
                            (<div style={{ width: '90vw' }}>
                                <p className='replyingto-message-OTHER' >Question of the day: {post.question}</p>
                                <div className='news-frame' style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div className='profile-picture'></div>
                                    <div className='new-message-post-content' style={{ marginLeft: '10px', width: '75vw' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: '30px', paddingRight: '20px' }}>
                                            <p className='fullname-display' onClick={post.fullname !== 'Anonymous' ? () => navigate(`/user/${post.fullname}`) : null}>{post.fullname}</p>
                                            <p className='add-point'>+{post.point} pts</p>
                                        </div>
                                        <div>
                                            <p className='message-content'>{post.message}</p>
                                        </div>
                                        <div className='message-response' style={{ marginLeft:'18vw '}}>
                                        <div className='city-tag' onClick={() => handleReply(post)}>Reply</div>
                                        <div className='del-point' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <FavoriteOutlinedIcon
                                                style={{
                                                    // color: 'rgb(235, 102, 96)',
                                                    color: post.ilikeit === 'yes' ? 'rgb(235, 102, 96)' : 'rgb(135, 159, 207)',
                                                    cursor: 'pointer',
                                                    marginLeft: '2px',
                                                    marginRight: '4px',
													width: '3.5vw',
													marginTop: '-0.5vh',
													marginBottom: '-0.5vh',
                                                }}
                                                onClick={() => handleLike(post)}
                                            />
                                            <p style={{ margin:'0px'}}>{post.likecount}</p>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            ) :
                            (
                                <div>
                                    <p className='replyingto-message-ME'>Question of the day: {post.question}</p>
                                    <div className='news-frame' style={{ display: 'flex', flexDirection: 'row', marginRight: '20px' }}>
                                        <div className='new-message-post-content-ME' style={{ marginLeft: '10px', width: '75vw' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: '30px', paddingRight: '0px' }}>
                                                <div style={{ width: '55vw', display:'flex' }}>
											    <p className='add-point' >+{post.point} pts</p>
												</div>
                                                <p className='fullname-display' onClick={post.fullname !== 'Anonymous' ? () => navigate(`/user/${post.fullname}`) : null}>{post.fullname}</p>
                                            </div>
                                            <div>
                                                <p className='message-content'>{post.message}</p>
                                            </div>
                                            <div className='message-response' style={{ marginRight:'12vw '}}>
                                            <div className='city-tag' onClick={() => handleReply(post)}>Reply</div>
                                            <div className='del-point' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <FavoriteOutlinedIcon
                                                    style={{
                                                        // color: 'rgb(235, 102, 96)',
                                                        color: post.ilikeit === 'yes' ? 'rgb(235, 102, 96)' : 'rgb(135, 159, 207)',
                                                        cursor: 'pointer',
														marginLeft: '2px',
														marginRight: '4px',
														width: '3.5vw',
														marginTop: '-0.5vh',
														marginBottom: '-0.5vh',
                                                    }}
                                                    onClick={() => handleLike(post)}
                                                />
                                                <p style={{ margin:'0px'}}>{post.likecount}</p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className='profile-picture'></div>
                                    </div>
                                </div>
                            )}
                    </div>
                )
                break;
            case 'User like a message':
                return (
                    <div key={post.id} style={{ marginLeft: '25px' }}>
                        <p className='submitted-date'>{convertTime(post.submitdate)}</p>
                        {post.who === 'other' ?
                            (<div>
                                <p className='replyingto-message-OTHER'>{post.inreplyto} posted: {post.messagereplyto}</p>
                                <div className='news-frame' style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div className='profile-picture'></div>
                                    <div className='new-message-post-content' style={{ marginLeft: '10px', width: '75vw' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '30px' }}>
                                            <p className='fullname-display' onClick={post.fullname !== 'Anonymous' ? () => navigate(`/user/${post.fullname}`) : null}>{post.fullname}</p>

                                        </div>
                                        <div>
                                            <p className='message-content'>Liked the message</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            ) :
                            (
                                <div>
                                    <p className='replyingto-message-ME'>{post.inreplyto} posted: {post.messagereplyto}</p>
                                    <div className='news-frame' style={{ display: 'flex', flexDirection: 'row', marginRight: '20px' }}>
                                        <div className='new-message-post-content-ME' style={{ marginLeft: '10px', width: '75vw' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '30px' }}>
                                                <p className='fullname-display' onClick={post.fullname !== 'Anonymous' ? () => navigate(`/user/${post.fullname}`) : null}>{post.fullname}</p>

                                            </div>
                                            <div>
                                                <p className='message-content'>Liked the message</p>
                                            </div>
                                        </div>
                                        <div className='profile-picture'></div>
                                    </div>
                                </div>
                            )}
                    </div>
                )
                break;
            case 'User check-in':
                if (post.publicprofile)
                    return (
                        <div style={{ marginLeft: '25px' }}>
                            <p className='submitted-date'>{convertTime(post.submitdate)}</p>
                            {post.who === 'other' ?
                                (
                                    <div className='news-frame' style={{ display: 'flex', flexDirection: 'row'}}>
                                        <div className='profile-picture'></div>
                                        <div style={{ marginLeft: '10px', width: '75vw' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: '30px', paddingRight: '2px' }}>
                                                <p className='fullname-display' onClick={post.fullname !== 'Anonymous' ? () => navigate(`/user/${post.fullname}`) : null}>{post.fullname}</p>
                                                <div style={{display:'flex'}}>
												<div className='city-tag'>{post.city}</div><div class='add-point'>+{post.point} pts</div>
												</div>
											</div>
                                            <div>
                                                <p className='check-in-message'>Just checked in at <span className='placename-checkin' onClick={() => navigate(`/placeview/${post.pname}/${post.placeid}`)}>{post.pname}</span></p>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '20px', marginRight: '30px' }}>
                                                <div className='city-tag'
                                                    onClick={() => {
                                                        localStorage.setItem('place', post.placeid);
                                                        navigate(`/placeView/${post.pname}/${post.placeid}`);
                                                    }}
                                                >
                                                    &#x1F5B9; Join
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                                :
                                (
                                    <div className='news-frame' style={{ display: 'flex', flexDirection: 'row' }}>

                                        <div style={{ marginLeft: '10px', width: '75vw' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: '30px', paddingRight: '20px' }}>
                                                <div style={{display:'flex'}}>
													<div class='add-point'>+{post.point} pts</div><div className='city-tag'>{post.city}</div>
												</div>	
                                                <p className='fullname-display' onClick={post.fullname !== 'Anonymous' ? () => navigate(`/user/${post.fullname}`) : null}>{post.fullname}</p>

                                            </div>
                                            <div>
                                                <p className='check-in-message' style={{ textAlign: 'end', marginRight: '20px' }}>Just checked in at <span onClick={() => navigate(`/placeview/${post.pname}/${post.placeid}`)} className='placename-checkin'>{post.pname}</span></p>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '20px', marginLeft: '55px' }}>
                                                <div className='city-tag'
                                                    onClick={() => {
                                                        localStorage.setItem('place', post.placeid);
                                                        navigate(`/placeView/${post.pname}/${post.placeid}`);
                                                    }}
                                                >
                                                    &#x1F5B9; Join
                                                </div>
                                            </div>
                                        </div>
                                        <div className='profile-picture'></div>
                                    </div>
                                )}
                        </div>
                    )
                break;
            case 'User suggested a new location':
                return (
                    <div style={{ marginLeft: '25px' }}>
                        <p className='submitted-date'>{convertTime(post.submitdate)}</p>

                        {post.who === 'other' ? (
                            <div className='news-frame' style={{ display: 'flex', flexDirection: 'row', }}>
                                <div className='profile-picture'></div>
                                <div style={{ marginLeft: '10px', width: '75vw' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: '30px', paddingRight: '20px' }}>
                                        <p className='fullname-display' onClick={post.fullname !== 'Anonymous' ? () => navigate(`/user/${post.fullname}`) : null}>{post.fullname}</p>

												<div style={{display:'flex'}}>
													<div className='city-tag'>{post.approved===1?post.city:post.suggested_city}</div><div class='add-point'>+{post.point} pts</div>
												</div>	
                                    </div>
                                    {post.approved === 0 ? (
                                        <div>
                                            <p className='suggest-location-message'>Wants to add a location to {post.suggested_city}'s Hidden Gemz and is waiting for approval</p>
                                        </div>
                                    ) :
                                        (
                                            <div>
                                                <div>
                                                    <p className='suggest-location-message'>Wants to add <span className='placename-checkin'
                                                        onClick={() => {
                                                            localStorage.setItem('place', post.placeid);
                                                            navigate(`/voting-placeview/${post.placename}/${post.placeid}`);
                                                        }}>{post.pname}</span> as one of {post.city}'s Hidden Gemz</p>
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '20px', marginRight: '30px' }}>
                                                    <div className='city-tag'
                                                        onClick={() => {
                                                            localStorage.setItem('place', post.placeid);
                                                            navigate(`/voting-placeview/${post.placename}/${post.placeid}`);
                                                        }}
                                                    >
                                                        &#x1F5B9; Vote
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                </div>
                            </div>
                        ) :
                            (
                                <div className='news-frame' style={{ display: 'flex', flexDirection: 'row', marginRight: '20px' }}>
                                    <div style={{ marginLeft: '10px', width: '75vw' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: '30px', paddingRight: '20px' }}>
                                            <div style={{display:'flex'}}>
													<div class='add-point'>+{post.point} pts</div><div className='city-tag'>{post.approved===1?post.city:post.suggested_city}</div>
											</div>	
                                            <p className='fullname-display' onClick={post.fullname !== 'Anonymous' ? () => navigate(`/user/${post.fullname}`) : null}>{post.fullname}</p>

                                        </div>
                                        <div style={{ display: 'flex', textAlign: 'end', marginRight: '20px' }}>
                                            {post.approved === 0 ? (
                                                <div>
                                                    <p className='suggest-location-message'>Wants to add a location to {post.suggested_city}'s Hidden Gemz and is waiting for approval</p>
                                                </div>
                                            ) :
                                                (
                                                    <div>
                                                        <div>
                                                            <p className='suggest-location-message'>Wants to add <span onClick={() => navigate(`/placeview/${post.pname}/${post.placeid}`)} className='placename-checkin'>{post.pname}</span> as one of {post.city}'s Hidden Gemz</p>
                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '20px', marginLeft: "80px" }}>
                                                            <div className='city-tag'
                                                                onClick={() => {
                                                                    localStorage.setItem('place', post.placeid);
                                                                    navigate(`/voting-placeview/${post.placename}/${post.placeid}`);
                                                                }}
                                                            >
                                                               &#x1F5B9; Vote
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                    <div className='profile-picture'></div>
                                </div>
                            )}

                    </div>
                )
                break;
            case 'User like/follow a place':
				return (
                        <div style={{ marginLeft: '25px' }}>
                            <p className='submitted-date'>{convertTime(post.submitdate)}</p>
                            {post.who === 'other' ?
                                (
                                    <div className='news-frame' style={{ display: 'flex', flexDirection: 'row'}}>
                                        <div className='profile-picture'></div>
                                        <div style={{ marginLeft: '10px', width: '75vw' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: '30px', paddingRight: '2px' }}>
                                                <p className='fullname-display' onClick={post.fullname !== 'Anonymous' ? () => navigate(`/user/${post.fullname}`) : null}>{post.fullname}</p>
                                                <div style={{display:'flex'}}>
												<div className='city-tag'>{post.city}</div>
												</div>
											</div>
                                            <div>
                                                <p className='check-in-message'>Liked and followed <span className='placename-checkin' onClick={() => navigate(`/placeview/${post.pname}/${post.placeid}`)}>{post.pname}</span></p>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '20px', marginRight: '30px' }}>
                                                <div className='city-tag'
                                                    onClick={() => {
                                                        localStorage.setItem('place', post.placeid);
                                                        navigate(`/placeView/${post.pname}/${post.placeid}`);
                                                    }}
                                                >
                                                    &#x1F5B9; Join
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                                :
                                (
                                    <div className='news-frame' style={{ display: 'flex', flexDirection: 'row' }}>

                                        <div style={{ marginLeft: '10px', width: '75vw' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: '30px', paddingRight: '20px' }}>
                                                <div style={{display:'flex'}}>
													<div className='city-tag'>{post.city}</div>
												</div>	
                                                <p className='fullname-display' onClick={post.fullname !== 'Anonymous' ? () => navigate(`/user/${post.fullname}`) : null}>{post.fullname}</p>

                                            </div>
                                            <div>
                                                <p className='check-in-message' style={{ textAlign: 'end', marginRight: '20px' }}>Liked and followed <span onClick={() => navigate(`/placeview/${post.pname}/${post.placeid}`)} className='placename-checkin'>{post.pname}</span></p>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '20px', marginLeft: '55px' }}>
                                                <div className='city-tag'
                                                    onClick={() => {
                                                        localStorage.setItem('place', post.placeid);
                                                        navigate(`/placeView/${post.pname}/${post.placeid}`);
                                                    }}
                                                >
                                                    &#x1F5B9; Join
                                                </div>
                                            </div>
                                        </div>
                                        <div className='profile-picture'></div>
                                    </div>
                                )}
                        </div>
                    )



                break;
            case 'User vote yes':
            case 'User vote no':
            case 'User re-vote yes':
            case 'User re-vote no':
                return (
                    <div key={post.id} style={{ marginLeft: '25px' }}>
                        <p className='submitted-date'>{convertTime(post.submitdate)}</p>
                        {post.who === 'other' ? (
                            <div className='news-frame' style={{ display: 'flex', flexDirection: 'row', }}>
                                <div className='profile-picture'></div>
                                <div style={{ marginLeft: '10px', width: '75vw' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: '30px', paddingRight: '20px' }}>
                                        <p className='fullname-display' onClick={post.fullname !== 'Anonymous' ? () => navigate(`/user/${post.fullname}`) : null}>{post.fullname}</p>

                                        <div style={{display:'flex'}}>
													<div className='city-tag'>{post.city}</div><div class='add-point'>+{post.point} pts</div>
												</div>	
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', marginRight: '0vw' }} onClick={() => navigate(`/placeview/${post.pname}/${post.placeid}`)} >
                                        <p className='suggest-location-message'>{post.point > 0 ? 'Voted' : 'Re-voted'} {post.vote_yes_no} on <span className='placename-checkin'>{post.pname}</span> </p>
                                    </div>

											<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '3.3vh', marginLeft: '-15vw' }}>
                                                <div className='city-tag'
                                                    onClick={() => {
                                                        localStorage.setItem('place', post.placeid);
                                                        navigate(`/voting-placeview/${post.placename}/${post.placeid}`);
                                                    }}
                                                >
                                                    &#x1F5B9; Join
                                                </div>
                                            </div>

										
                                </div>
                            </div>
                        ) :
                            (
                                <div className='news-frame' style={{ display: 'flex', flexDirection: 'row'}}>
									<div style={{ marginLeft: '10px', width: '75vw' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: '30px', paddingRight: '20px' }}>
                                                <div style={{display:'flex'}}>
													<div class='add-point'>+{post.point} pts</div><div className='city-tag'>{post.city}</div>
												</div>	
                                                <p className='fullname-display' onClick={post.fullname !== 'Anonymous' ? () => navigate(`/user/${post.fullname}`) : null}>{post.fullname}</p>

                                            </div>
                                            <div onClick={() => navigate(`/placeview/${post.pname}/${post.placeid}`)}
													style={{display: 'flex',    justifyContent: 'center'}}>
                                                <p className='message-content'>{post.point > 0 ? 'Voted' : 'Re-voted'} {post.vote_yes_no} on <span className='placename-checkin'>{post.pname}</span> </p>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '3.3vh', marginLeft: '55px' }}>
                                                <div className='city-tag'
                                                    onClick={() => {
                                                        localStorage.setItem('place', post.placeid);
                                                        navigate(`/voting-placeview/${post.placename}/${post.placeid}`);
                                                    }}
                                                >
                                                    &#x1F5B9; Join
                                                </div>
                                            </div>
                                        </div>
                                        <div className='profile-picture'></div>
                                    </div>
                                )	

						}
                    </div>
                );
                break;
            case 'User upload a picture':
            case 'User upload a picture in Voting':
                if (post.pname !== null)
                    return (
                        <div style={{ marginLeft: '25px' }}>
                            <p className='submitted-date'>{convertTime(post.submitdate)}</p>
                            {post.who === 'other' ? (
                                <div className='news-frame' style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div className='profile-picture'></div>
                                    <div style={{ marginLeft: '10px', width: '75vw' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: '30px', paddingRight: '20px' }}>
                                            <p className='fullname-display' onClick={post.fullname !== 'Anonymous' ? () => navigate(`/user/${post.fullname}`) : null}>{post.fullname}</p>

                                            <div style={{display:'flex'}}>
												<div className='city-tag'>{post.city}</div><div class='add-point'>+{post.point} pts</div>
											</div>
                                        </div>
                                        <div>
                                            <p className='upload-picture-message'>Added a picture at <span onClick={() => navigate(`/placeview/${post.pname}/${post.placeid}`)} className='placename-checkin'>{post.pname}</span></p>
                                        </div>
                                        <div >
                                            {wait4approvalpic(post)}
                                        </div>
                                    </div>
                                </div>
                            ) :
                                (
                                    <div className='news-frame' style={{ display: 'flex', flexDirection: 'row' }}>

                                        <div style={{ marginLeft: '10px', width: '75vw', display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: '30px', width: '70vw', paddingRight: '20px' }}>
                                                <div style={{display:'flex'}}>
												<div class='add-point'>+{post.point} pts</div><div className='city-tag'>{post.city}</div>
												</div>
                                                <p className='fullname-display' onClick={post.fullname !== 'Anonymous' ? () => navigate(`/user/${post.fullname}`) : null}>{post.fullname}</p>

                                            </div>
                                            <div>
                                                <p className='upload-picture-message-ME'>Added a picture at <span onClick={() => navigate(`/placeview/${post.pname}/${post.placeid}`)} className='placename-checkin'>{post.pname}</span></p>
                                            </div>
                                            <div >
                                                {wait4approvalpic(post)}
                                            </div>
                                        </div>
                                        <div className='profile-picture'></div>
                                    </div>
                                )}
                        </div>


                    );
                break;
            case 'User redeem a coupon':
                if (post.publicprofile)
                    return (
                        <div style={{ marginLeft: '25px' }}>
                            <p className='submitted-date'>{convertTime(post.submitdate)}</p>
                            {post.who === 'other' ? (
                                <div className='news-frame' style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div className='profile-picture'></div>
                                    <div style={{ marginLeft: '10px', width: '75vw' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: '30px', paddingRight: '20px' }}>
                                            <p className='fullname-display' onClick={post.fullname !== 'Anonymous' ? () => navigate(`/user/${post.fullname}`) : null}>{post.fullname}</p>

                                            <div style={{display:'flex'}}>
												<div className='city-tag'>{post.city}</div><div class='del-point'>{post.point} pts</div>
												</div>
                                        </div>
                                        <div onClick={() => navigate(`/redeem`)} >
                                            <p className='check-in-message'>Redeemed '{post.message}' at <span className='placename-checkin'>{post.placename}</span></p>
                                        </div>

										<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '3.3vh', marginLeft: '-12vw' }}>
                                                <div className='city-tag'
                                                    onClick={() => {
                                                        localStorage.setItem('place', post.placeid);
                                                        navigate(`/placeview/${post.placename}/${post.placeid}`);
                                                    }}
                                                >
                                                    &#x1F5B9; Join
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            ) : (
                                <div className='news-frame' style={{ display: 'flex', flexDirection: 'row' }}>

                                    <div style={{ marginLeft: '10px', width: '75vw' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: '30px', paddingRight: '20px' }}>
                                            <div style={{display:'flex'}}>
												<div class='del-point'>{post.point} pts</div><div className='city-tag'>{post.city}</div>
												</div>
                                            <p className='fullname-display' onClick={post.fullname !== 'Anonymous' ? () => navigate(`/user/${post.fullname}`) : null}>{post.fullname}</p>

                                        </div>
                                        <div onClick={() => navigate(`/redeem`)}>
                                            <p className='check-in-message' style={{ textAlign: 'center', marginRight: '15px' }}>Redeemed '{post.message}' at <span className='placename-checkin'>{post.placename}</span></p>
                                        </div>
										<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '3.3vh', marginLeft: '55px' }}>
                                                <div className='city-tag'
                                                    onClick={() => {
                                                        localStorage.setItem('place', post.placeid);
                                                        navigate(`/placeview/${post.placename}/${post.placeid}`);
                                                    }}
                                                >
                                                    &#x1F5B9; Join
                                                </div>
                                            </div>
                                    </div>
                                    <div className='profile-picture'></div>
                                </div>
                            )}

                        </div>
                    )
                break;
            case 'Admin approved a new place':
                

				return (
                        <div style={{ marginLeft: '25px' }}>
                            <p className='submitted-date'>{convertTime(post.submitdate)}</p>
                            {post.who === 'other' ? (
                                <div className='news-frame' style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div className='profile-picture'></div>
                                    <div style={{ marginLeft: '10px', width: '75vw' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: '30px', paddingRight: '20px' }}>
                                            <div>
											<p style={{margin: '15px 0px 0px 0px'}}className='fullname-display' onClick={post.fullname !== 'Anonymous' ? () => navigate(`/user/${post.fullname}`) : null}>{post.fullname}</p>
											<p style={{ textAlign: 'center', fontSize: '14px', fontWeight:'400', margin: '0px 0px 0px 0px' }}>Congrats!</p>
											</div>

                                            <div style={{display:'flex'}}>
												<div className='city-tag'>{post.city}</div><div class='add-point'>+2000 pts</div>
												</div>
                                        </div>

										<div style={{ marginTop: '5vh', marginLeft: '-10vw', width: '75vw' }}>
											<div>
												<p style={{ textAlign: 'center', fontSize: '17px' }}><span className='placename-checkin'>{post.pname}</span> has been succesfully added as one of {post.city}'s Hidden Gemz</p>
											</div>
											<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '80px' }}>
												<img style={{ width: '80px' }} src="/assets/logo2.png" alt='logo2'

													onClick={() => {
														localStorage.setItem('place', post.placeid);
														navigate(`/placeView/${post.pname}/${post.placeid}`);
													}}
												/>

											</div>
										</div>


                                    </div>
                                </div>
                            ) : (
                                <div className='news-frame' style={{ display: 'flex', flexDirection: 'row' }}>

                                    <div style={{ marginLeft: '10px', width: '75vw' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: '30px', paddingRight: '20px' }}>
                                            <div style={{display:'flex'}}>
												<div class='add-point'>+2000 pts</div><div className='city-tag'>{post.city}</div>
												</div>
										    <div>
                                            <p style={{margin: '15px 0px 0px 0px'}} className='fullname-display' onClick={post.fullname !== 'Anonymous' ? () => navigate(`/user/${post.fullname}`) : null}>{post.fullname}</p>
										    <p style={{ textAlign: 'center', fontSize: '14px', fontWeight:'400', margin: '0px 0px 0px 0px' }}>Congrats!</p>
											</div>

                                        </div>



										<div style={{ marginTop: '5vh' ,marginLeft: '10px', width: '75vw' }}>
											<div>
												<p style={{ textAlign: 'center', fontSize: '17px' }}><span className='placename-checkin'>{post.pname}</span> has been succesfully added as one of {post.city}'s Hidden Gemz</p>
											</div>
											<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '80px' }}>
												<img style={{ width: '80px' }} src="/assets/logo2.png" alt='logo2'

													onClick={() => {
														localStorage.setItem('place', post.placeid);
														navigate(`/placeView/${post.pname}/${post.placeid}`);
													}}
												/>

											</div>
										</div>



                                    </div>
                                    <div className='profile-picture'></div>
                                </div>
                            )}

                        </div>
                    )

                break;

            case 'New reward added':
                if (post.coupon_description)
                    return (
                        <div style={{ marginLeft: '25px' }}>
                            <p style={{ fontWeight: 'bold', fontSize: '16px' }}>New reward added!</p>
                            <div className='news-frame' onClick={() => navigate(`/redeem/`)}>

                                <div className="middle">
                                    <div className="coupon">
                                        {post.coupon_description}
                                    </div>
                                    <div className="placename">{post.placename}</div>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignContent: 'center', marginTop: "5px" }}>
                                        <FmdGoodIcon style={{ marginRight: '6px', marginLeft: '-5px', color: "#EB6660" }} />
                                        <div className="values">Value: ${post.coupon_value}</div>
                                    </div>
                                </div>
                                <div className="section">
                                    {post.coupon_point}
                                    <br />
                                    points
                                </div>
                            </div>
                        </div>
                    )
                break;

            case 'Upcoming event in 4 hours':
                return (
                    <div style={{ marginLeft: '25px' }}>
                        <p className='submitted-date'>{convertTime(post.submitdate)}</p>
                        <p style={{ fontWeight: 'bold', fontSize: '16px' }}>Next event coming up!</p>
                        <ListItemButton style={{marginLeft: '1vw'}}
                            className="news-frame"
                            onClick={() => navigate(`/eventpage/${post.event_name}/${post.event_date}`)}
                            sx={{
                                backgroundColor: 'white',
                                marginTop: '15px',

                                marginBottom: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '10px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                textAlign: 'center',
                                color: 'black',
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                boxShadow: '0px 4px 3px 1px rgba(220, 154, 87, 0.4);',
                                transition: 'transform 0.3s ease',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                },
                            }}

                        >

                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div className="event-name-place" style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div className="event-name" style={{ textAlign: 'left' , textWrap:'wrap',}}>
                                        {post.event_name}
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}>
                                        <FmdGoodIcon style={{ marginTop: '-5px', marginRight: '6px', marginLeft: '-5px', color: "#EB6660" }} />
                                        <div className="event-place">{post.place}</div>
                                    </div>
                                </div>
                                <div>
                                    
									<div style={{display:'flex', height:'27px'}}>
										<div className="city-tag">{post.citytag}</div>
										<div className="add-point">{post.price_type !== 'Free' ? '$' + post.price : 'Free'}</div>
									</div>
                                    <div className="time" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '-30px', marginTop: "15px" }}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <AccessTimeIcon style={{ transform: 'scale(0.9)', marginTop: '-4px', color: '#81A0D3', marginRight: '5px' }} />
                                            <div className="start-time">{convertTo12HourFormat(post.event_start_time)}</div>
                                        </div>
                                        <div className="end-time" style={{ marginRight: "-30px", fontWeight: '200' }}>{convertTo12HourFormat(post.event_end_time)}</div>
                                    </div>
                                </div>
                            </div>
                        </ListItemButton>
                    </div>
                )
                break;
            default:
                return null;
        }
    }

    const handleNewMessage = () => {
        if (inputRef.current.value === '') return;
        let replytoid = (replyToMessage?.id) ? replyToMessage.id : '0';

        var myHeaders = new Headers();
        myHeaders.append("Authorization", String(localStorage.getItem('accessToken')));
        myHeaders.append("Content-Type", "text/plain");
        var requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: JSON.stringify({ message: inputRef.current.value, replyto: replytoid }),
            headers: myHeaders
        }
        fetch(getUrl('message'), requestOptions)
            .then(response => {
                //console.log('res', response.text);    
                // setCounter(counter + 1);
                setReplyToMessage(null);
                setIsReplying(false);
                inputRef.current.value = '';
            })
            .catch(error => {
                console.log('catch error');
                inputRef.current.style.color = 'red';
            });

    };

    const handleReply = (post) => {
        setReplyToMessage(post);
        setIsReplying(true);
        inputRef.current?.scrollIntoView({ behavior: "smooth" })
        inputRef.current.focus();
    }
    const handleLike = (post) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", String(localStorage.getItem('accessToken')));
        myHeaders.append("Content-Type", "text/plain");
        var requestOptions = {
            method: 'PUT',
            redirect: 'follow',
            body: JSON.stringify({ liketo: post.id }),
            headers: myHeaders
        }
        fetch(getUrl('message'), requestOptions)
            .then(response => {
                //console.log('res', response.text);    
                setIsLike(true);
                // setCounter(counter + 1);
            })
            .catch(error => {
                console.log('catch error');
            });
    }
    const wait4approvalpic = (newsfeed) => {
        if (newsfeed.approved == 1) {
            return (<div><img className='uploaded-picture' src={getUrl('s3imagePath') + newsfeed.imgsrc} alt='uploadedpic' /> </div>);
        } else {
            return (<img src='https://dev.cluez.ca/images/161---500---hieuvpn@gmail.com---2023-10-30T15:51:36.413Z.jpg' className='uploaded-picture' alt='underreview' />);
        }

    }

    const convertTo12HourFormat = (time) => {
        const [hours, minutes] = time.split(':');
        let formattedTime = '';
        let period = '';

        if (parseInt(hours) === 12) {
            formattedTime = hours;
            period = 'PM';
        } else if (parseInt(hours) > 12) {
            formattedTime = (parseInt(hours) - 12).toString();
            period = 'PM';
        } else {
            formattedTime = hours;
            period = 'AM';
        }

        return `${formattedTime}:${minutes} ${period}`;
    };


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (messageBoxQOTDRef.current && !messageBoxQOTDRef.current.contains(event.target)) {
                // click outside the message-box-QOTD, hide it and show message-box
                setShowQOTD(false);
                // QOTDinputRef.current.value = '';
                // QOTDinputRef.current.style.color = 'black';
            }
        };

        document.body.addEventListener('click', handleClickOutside);

        return () => {
            document.body.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleAnswerButtonClick = () => {
        if (!isUserSignedIn) { navigate('/signin'); }
        // change between message-box and message-box-QOTD when the answer button is clicked
        setShowQOTD((prevShowQOTD) => !prevShowQOTD);
        QOTDinputRef.current?.scrollIntoView({ behavior: "smooth" })
        QOTDinputRef.inputRef.current.focus();
    };

    const handleInputClick = (event) => {
        event.stopPropagation();
    };

    const handleSendButtonClick = (event) => {

        event.stopPropagation();
        handleAnswerQOTDClick();
    };

    const handleAnswerQOTDClick = () => {
        if (QOTDinputRef.current.value === '') return;
        var myHeaders = new Headers();
        myHeaders.append("Authorization", String(localStorage.getItem('accessToken')));
        myHeaders.append("Content-Type", "text/plain");
        var requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: JSON.stringify({ message: QOTDinputRef.current.value, qotdid: qotd.id }),
            headers: myHeaders
        }
        fetch(getUrl("qotd"), requestOptions)
            .then(response => {
                setShowQOTD(false);
                QOTDinputRef.current.value = '';
                setQOTD(0);
            })
            .catch(error => {
                console.log('catch error');
                QOTDinputRef.current.style.color = 'red';
            });
    };

    const handleCloseQOTD = () => {
        setShowQuestionQOTD(false);
        localStorage.setItem('hide_qotd_' + qotd.id, true);
        setShowQOTD(false);
        QOTDinputRef.current.value = '';
    }

    return (
        <div>
            <DesktopContainer />
            <div className=" mobile-container newsfeed-page" style={{ fontSize: '1.3rem', fontWeight: '300' }}>
                <div className="scrollable-content" >
                    <div className="newsfeed-TG">
                        <BackButton onClick={() => navigate("/suggestion")} />
                        <Scoreboard onClick={() => navigate('/redeem')} refresh={qotd} />
                        <RankButton onClick={() => navigate('/leaderboard')} />
                    </div>

                    <div className='feeds-content' >
                        {newsfeeds.map(post => (
                            renderNewsfeeds(post)
                        ))}
                    </div>



                    {/* QOTD */}
                    {qotd.id > 0 && showQuestionQOTD ? (
                        <div ref={messageBoxQOTDRef} className='question-field' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                            <div >
                                <div className='closeicon-nf' onClick={handleCloseQOTD}>
                                    <CloseIcon />
                                </div>
                            </div>
                            <div className='question-content'>
                                <p>Question of the day:</p>
                                <p className='questions'>{qotd.question}</p>
                                {isUserSignedIn ? '' : (<p className='questions'>Signin to answer and get 50pts</p>)}
                                <button onClick={handleAnswerButtonClick}>{isUserSignedIn ? 'answer +50pts' : 'Signin'}</button>
                                <p> {qotd.replies} replies</p>
                            </div>
                        </div>
                    ) : ''}


                    {/* QOTD message box answer */}
                    <div ref={messageBoxQOTDRef} className='message-box-QOTD' style={{  display: showQOTD ? 'block' : 'none' }}>

                        <div className='reply-info' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            {qotd.question}
                            <div
                                style={{ display: 'flex', }}
                                onClick={() => {
                                    setIsReplying(false);
                                    setReplyToMessage({ id: 0 });
                                    QOTDinputRef.current?.scrollIntoView({ behavior: "smooth" })
                                }}>

                                < HighlightOffIcon style={{ fontSize: 'large' }} />
                            </div>
                        </div>

                        <input
                            ref={QOTDinputRef}
                            type='text'
                            placeholder='Write a message'
                            onClick={handleInputClick}
                            className='message-box-input'
                        />
                        <button className='message-box-send answer' onClick={handleSendButtonClick}>
                            Answer
                        </button>
                    </div>

                    <div className='message-box' style={{ display: showQOTD ? 'none' : 'block' }}>
                        {isReplying && replyToMessage && (
                            <div className='reply-info' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                Replying to {replyToMessage.fullname}
                                <div
                                    style={{ display: 'flex', }}
                                    onClick={() => {
                                        setIsReplying(false);
                                        setReplyToMessage({ id: 0 });
                                        inputRef.current?.scrollIntoView({ behavior: "smooth" })
                                    }}>

                                    < HighlightOffIcon style={{ fontSize: 'large' }} />
                                </div>
                            </div>
                        )}
                        <input
                            type='text'
                            placeholder='Write a message'
                            className='message-box-input'
                            ref={inputRef}
                        />
                        <button className='message-box-send' onClick={handleNewMessage}>
                            Send
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Newsfeed
