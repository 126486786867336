import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import "./profileAPIHelper.css";
import getUrl from '../LambdaUrls';
import { BackButton } from '../components/Buttons/BackButton';
import { DesktopContainer } from '../components/DesktopContainer';
import { useNavigate } from 'react-router-dom';

function postUserbirthdate(birthdate) {
  var myHeaders = new Headers();
  var data = {
    birthdate: birthdate
  };
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append('Authorization', localStorage.getItem('accessToken'));
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(data), // Convert data to JSON string
    redirect: 'follow'
  };
  fetch(getUrl('postuserbirthdate'), requestOptions)
    .then(response => response.text())
    .then(result => {
      console.log('submit success');
    })
    .catch(error => {
      console.error('Error:', error);
    });
}

function postUserGender(gender) {
  var myHeaders = new Headers();
  var data = {
    gender: gender
  };
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append('Authorization', localStorage.getItem('accessToken'));
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(data), // Convert data to JSON string
    redirect: 'follow'
  };
  fetch(getUrl('postusergender'), requestOptions)
    .then(response => response.text())
    .then(result => {
      console.log('submit success');
    })
    .catch(error => {
      console.error('Error:', error);
    });
}
function postUserWorkType(worktype) {
  var myHeaders = new Headers();
  var data = {
    workfield : worktype
  };
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append('Authorization', localStorage.getItem('accessToken'));
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(data), // Convert data to JSON string
    redirect: 'follow'
  };
  fetch(getUrl('postuserworktype'), requestOptions)
    .then(response => response.text())
    .then(result => {
      console.log('submit success');
    })
    .catch(error => {
      console.error('Error:', error);
    });
}
function postUserTransportation(transportation) {
  var myHeaders = new Headers();
  var data = {
    transportation: transportation
  };
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append('Authorization', localStorage.getItem('accessToken'));
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(data),
    redirect: 'follow'
  };
  fetch(getUrl('postusertransportation'), requestOptions)
    .then(response => response.text())
    .then(result => {
      console.log('submit success');
    })
    .catch(error => {
      console.error('Error:', error);
    });
}

export function getUserInfo() {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append('Authorization', localStorage.getItem('accessToken'));
  console.log(localStorage.getItem('accessToken'));
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify({}), // Convert data to JSON string
    redirect: 'follow'
  };

  return fetch(getUrl('getuserinfo'), requestOptions)
    .then(response => response.text())
    .then(result => {
      console.log("result " + JSON.parse(result))
      return JSON.parse(result);
    })
    .catch(error => {
      console.error('Error:', error);
    });
}


export function UserProfileSetup() {
  const navigate = useNavigate();
  const [birthdate, setBirthdate] = useState(new Date());
  const [gender, setGender] = useState('');
  const [otherGender, setOtherGender] = useState('');
  const [selectedWorkType, setSelectedWorkType] = useState('');
  const [selectedTransportation, setSelectedTransportation] = useState('');
  const genderOptions = [
    "Woman",
    "Man",
    "Two spirit",
    "Trans woman",
    "Trans man",
    "Non binary",
    "Prefer not to say",
    "Other"
  ];
  const transportationOptions = [
    "Walk",
    "Drive",
    "Transit",
    "Scooter"
  ];
  const worktypeOptions = [
    "Software Engineer",
    "Electric Engineer",
    "Mechanical Engineer",
    "Computer Engineer",
    "Data Scientist",
    "Web Developer",
    "Graphic Designer",
    "Accountant",
    "Nurse",
    "Teacher",
    "Administrative Assistant",
    "Retail Sales Representative",
    "Office Clerk",
    "Registered Nurse",
    "Cashier",
    "Waitress",
    "Police Officer",
    "Chef",
    "Marketing Manager",
    "Financial Analyst",
    "Lawyer",
    "Doctor",
    "Dentist",
    "Pharmacist",
    "Physical Therapist",
    "Plumber",
    "Carpenter",
    "Construction Worker",
    "Mechanic",
    "Hair Stylist",
    "Barista",
    "Writer",
    "Journalist",
    "Photographer",
    "Real Estate Agent",
    "Social Worker",
    "Flight Attendant",
    "Architect",
    "Event Planner",
    "Librarian",
    "Sales Manager",
    "Customer Service Representative",
    "Truck Driver",
    "Security Guard",
    "Janitor",
    "Landscaper",
    "Veterinarian",
    "Phlebotomist",
    "Electrician",
    "Interpreter/Translator",
  ];
  useEffect(() => {
    getUserInfo().then(data => {
      const birthdateFromApi = data['birthdate']; // replace 'birthdate' with the actual column name
      const gender = data['gender']; // replace 'gender' with the actual column name
      const transportation = data['transportation']; // replace 'transportation' with the actual column name
      try {
        const [year, month, day] = birthdateFromApi.split('-');
        const birthdate = new Date(year, month - 1, day); // month is zero-based
        setBirthdate(birthdate)
      } catch (error) {
        console.log(error);
      }
      
      const worktype = data['workfield']; // replace 'worktype' with the actual column name
      setSelectedWorkType(worktype);
      if (genderOptions.includes(gender)) {
        setGender(gender);
        if (gender === 'Other') {
          setOtherGender(gender);
        } else {
          setOtherGender('');
        }
      } else {
        setGender('Other');
        setOtherGender(gender);
      }
      setSelectedTransportation(transportation);
    });
  }, []);

  const handleChange = date => {
    const formattedDate = date.toISOString().split('T')[0];
    setBirthdate(date);
    console.log("FormattedDate " + formattedDate);
    // postUserbirthdate(formattedDate);
  };
  
  const handleGenderChange = e => {
    const selectedGender = e.target.value;
    setGender(selectedGender);
    // postUserGender(selectedGender);
    if (selectedGender !== 'Other') {
      setOtherGender('');
    }
  };
  const handleOtherGenderChange = e => {
    setOtherGender(e.target.value);
  };
  const handleWorkTypeChange = e => {
    setSelectedWorkType(e.target.value);
    // postUserWorkType(e.target.value);
  };
  const handleTransportationChange = e => {
    const selectedTransportation = e.target.value;
    setSelectedTransportation(selectedTransportation);
    // postUserTransportation(selectedTransportation);
  };
  const handleSave = e => {
    if(birthdate) {
      postUserbirthdate(birthdate.toISOString().split('T')[0]);
    }
    if(gender) {
      if (gender === 'Other'){
        postUserGender(otherGender)
      }else {
        postUserGender(gender);
      }
    }
    if(selectedTransportation) {
      postUserTransportation(selectedTransportation);
    }
    if(selectedWorkType) {
      postUserWorkType(selectedWorkType);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  //get name
  const [fullname, setFullname] = useState('');
  const [users, setUsers] = useState([]);
  const fetchData = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", String(localStorage.getItem('accessToken')));
    myHeaders.append("Content-type", "text/plain");
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders
    }
    fetch(getUrl('getFullname'), requestOptions)
      .then(response => response.text())
      .then(result => {
        setFullname(JSON.parse(result).fullname)
      })
      .catch(error => console.log('error', error));
  }
  const handleUserClick = (fullname) => {
    console.log('FULLNAME', fullname)
   navigate(`/user/${fullname}`);
  };

  return (
    <div>
      <DesktopContainer />
      <div className=" mobile-container newsfeed-page" style={{ fontSize: '1.3rem', fontWeight: '300' }}>
        <div className="scrollable-content">
          <div className="newsfeed-TG">
              <BackButton onClick={()=> handleUserClick(fullname)} />
            <div></div>
            <div></div>
          </div>
      <p className = 'center-text'>This information is kept private and can not be viewed
        by other users
      </p>
      <div className='container'>
        <div className='left-container fav-place'>
          <p>Birthday</p>
        </div>
        <div className='right-container'>
          <p className='right-text'>Know where to go for birthday perks</p>
        </div>
      </div>
      <DatePicker
        selected={birthdate}
        onChange={handleChange}
        yearDropdown // enable year dropdown
        showYearDropdown // show year dropdown
      />
      <div className='container'>
        <div className='left-container fav-place'>
          <p>Gender</p>
        </div>
        <div className='right-container'>
          <p className='right-text'>Optional</p>
        </div>
      </div>
      <select value={gender} onChange={handleGenderChange}>
        <option value="">Select Gender</option>
        {genderOptions.map(option => (
          <option key={option} value={option}>{option}</option>
        ))}
      </select>
      {gender === 'Other' && (
        <div>
          <input
            type="text"
            value={otherGender}
            onChange={handleOtherGenderChange}
            placeholder="Enter your gender"
          />
          <button onClick={() => postUserGender(otherGender)}>Save</button>
        </div>
      )}
    </div>
    <div className='container'>
        <div className='left-container fav-place'>
          <p>Work Field</p>
        </div>
        <div className='right-container'>
          <p className='right-text'>Optional</p>
        </div>
      </div>
      <select value={selectedWorkType} onChange={handleWorkTypeChange}>
      <option value="">Select Work Field</option>
      {worktypeOptions.map(option => (
        <option key={option} value={option}>{option}</option>
      ))}
    </select>
    </div>
    <div className='container'>
        <div className='left-container fav-place'>
          <p>Transportation</p>
        </div>
        <div className='right-container'>
          <p className='right-text'>Optional</p>
        </div>
      </div>
      <select value={selectedTransportation} onChange={handleTransportationChange}>
        <option value="">Select Transportation</option>
        {transportationOptions.map(option => (
          <option key={option} value={option}>{option}</option>
        ))}
      </select>
      <div style={{ width: '100vw', display: 'flex', justifyContent: 'center' }}>
                    <button
                        style={{
                            backgroundColor: '#879fcf',
                            color: 'white',
                            border: '3px solid white',
                            borderRadius: '35px',
                            height: '5vh',
                            width: '30vw',
                            boxShadow: '0px 4px 3px 1px rgba(220, 154, 87, 0.6)',
                            marginBottom: '5vh',
                            fontSize: '1.1rem',
                            marginLeft: '5vw',
                            marginTop: '20px'
                        }}
                        onClick={handleSave}
                    >
                        Save
                    </button>
                </div>
    </div>

  );
}





