import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import './signin.css';
import { BackButton } from './Buttons/BackButton';
import axios from 'axios';
import { DesktopContainer } from './DesktopContainer';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import getUrl from '../LambdaUrls';

const SignInPage = ({ onSignIn }) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isSigningIn, setIsSigningIn] = useState(false);
    const [isUserSignedIn, setIsUserSignedIn] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        checkUserSignedIn();
    }, []);

    const checkUserSignedIn = async () => {
        try {
            await Auth.currentAuthenticatedUser();
            setIsUserSignedIn(true);
        } catch (error) {
            setIsUserSignedIn(false);
        }
    };
    const handleSignIn = async () => {
        if (!password) {
            setError('Please enter your password.');
            return;
        }

        try {
            setIsSigningIn(true);
            await Auth.signIn(email, password);
            localStorage.setItem('email', email)
            setIsUserSignedIn(true);

            onSignIn();
            try {
                const user = await Auth.currentAuthenticatedUser();
                console.log('User:', user);
                localStorage.setItem('accessToken', user.signInUserSession.idToken.jwtToken);
                console.log("Token: ", localStorage.getItem('accessToken'))
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "text/plain");
                myHeaders.append("Authorization", String(localStorage.getItem('accessToken')));

            var raw = "{\r\n  \"email\": \"" + String(email).trim() + "\"\r\n}";

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            fetch(getUrl("addEmail"), requestOptions)
                .then(response => response.text())
                .then(result => console.log(result))
                .catch(error => console.log('error', error));
    
            } catch (error) {
                console.log('Error:', error);
            }
            navigate('/suggestion');
        } catch (error) {
            setError(error.message);
            setIsSigningIn(false);
        }
    };

    const handleSignOut = async () => {
        try {
            await Auth.signOut();
            setIsUserSignedIn(false);
            localStorage.removeItem("toast");
            window.location.href = '/signin';
        } catch (error) {
            setError(error.message);
        }
    };

    const handleSignUpClick = () => {
        navigate('/signup');
    };

    return (
        <div>
            <DesktopContainer />
            <div className='signin-container mobile-container'>
                <BackButton  onClick={() => navigate("/")} />
                <div className="top-container">
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: "column", marginTop: '-3vh' }}>
                        <div className="logo-container">
                            <img src="../assets/logo2.png" alt="logo" className='logopic' />
                        </div>
                        <h2 className="signup-heading" >Hidden Gemz</h2>
                    </div>
                </div>

                {isUserSignedIn ? (
                    <>
                        <p style={{ fontSize: '20px' }}>User is already signed in.</p>
                        <button style={{ backgroundColor: '#879fcf', color: 'white', border: '3px solid white', borderRadius: '35px', height: '6vh', width: '30vw', boxShadow: '0px 4px 3px 1px rgba(220, 154, 87, 0.6)' }} onClick={handleSignOut}>Sign Out</button>
                    </>
                ) : (
                    <>
                        <label className='form-label'>Email</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="input-field"
                        />
                        <label className='form-label'>Password</label>
                        <div className="password-field">
                            <div className="input-container">
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="input-field"
                                />
                                <RemoveRedEyeIcon onClick={() => setShowPassword(!showPassword)} className="show-password-icon" />
                            </div>
                        </div>
                        <button
                            onClick={handleSignIn}
                            disabled={isSigningIn}
                            className={`signin-button ${isSigningIn ? 'loading' : ''}`}
                        >
                            {isSigningIn ? 'Signing In...' : 'Sign In'}
                        </button>

                        {error && <p className="error-message">{error}</p>}

                        <p className='p-font'>
                            Don't have an account?{' '}
                            <span
                                className='sign-span'
                                onClick={handleSignUpClick}

                            >
                                Sign Up
                            </span>
                        </p>
                    </>
                )}
            </div>
        </div>
    );
};

export default SignInPage;
