import React from 'react';
import { useNavigate } from "react-router-dom";
import '../../suggestions.css';

export const RankButton = ({ onClick, style, className }) => {
    let navigate = useNavigate();

    const styling = {
        cursor: 'pointer',
        borderRadius: '10px',
        color: 'white',
        backgroundColor: ' #879fcf',
        border: ' 3px solid white',
        boxShadow: '0px 4px 3px 1px rgba(220, 154, 87, 0.6), 0px -5px 0px 0px rgba(0, 0, 0, 0.08) inset',
        width: '45px',
        height: '45px',
        display:'flex',
        justifyContent:'center', 
        alignItems:'center'
    }

    const mergedStyles = {
        ...styling,
        ...style, // merge the provided style prop
    };

    return (
        <div style={mergedStyles}
            onClick={onClick}
            className={className}>
            <img
                src='/assets/ranking.png'
                alt='ranking'
                style={{width:'28px', height:'28px'}}
            />
        </div>
    )
}


