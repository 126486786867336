import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import './signinnavbar.css';
import axios from 'axios';
import { DesktopContainer } from './DesktopContainer';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import getUrl from '../LambdaUrls';

const SignInNavbar = ({ onSignIn }) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isSigningIn, setIsSigningIn] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        checkUserSignedIn();
    }, []);

    const checkUserSignedIn = async () => {
        try {
            await Auth.currentAuthenticatedUser();
        } catch (error) {
            // User is not signed in
        }
    };

    const handleSignIn = async () => {
        if (!password) {
            setError('Please enter your password.');
            return;
        }

        try {
            setIsSigningIn(true);
            await Auth.signIn(email, password);
            localStorage.setItem('email', email);

            onSignIn();
            try {
                const user = await Auth.currentAuthenticatedUser();
                console.log('User:', user);
                localStorage.setItem('accessToken', user.signInUserSession.idToken.jwtToken);
                console.log("Token: ", localStorage.getItem('accessToken'))

                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "text/plain");
                myHeaders.append("Authorization", String(localStorage.getItem('accessToken')));

            var raw = "{\r\n  \"email\": \"" + String(email).trim() + "\"\r\n}";

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            fetch(getUrl("addEmail"), requestOptions)
                .then(response => response.text())
                .then(result => console.log(result))
                .catch(error => console.log('error', error));

            } catch (error) {
                console.log('Error:', error);
            }
            navigate('/suggestion');
        } catch (error) {
            setError(error.message);
            setIsSigningIn(false);
        }
    };

    const handleSignUpClick = () => {
        navigate('/signup');
    };

    return (
        <div>
            <DesktopContainer />
            <div className='signin-container-nav mobile-container'>
                <label className='form-label-nav'>Email</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="input-field-nav"
                />
                <label className='form-label-nav'>Password</label>
                <div className="password-field">
                    <div className="input-container">
                        <input
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="input-field-nav"
                        />
                        <RemoveRedEyeIcon onClick={() => setShowPassword(!showPassword)} className="show-password-icon-nav" />
                    </div>
                    <p className='forgot-pw'>Forgot password</p>
                </div>
                <button
                    onClick={handleSignIn}
                    disabled={isSigningIn}
                    className={`signin-button-nav ${isSigningIn ? 'loading' : ''}`}
                >
                    {isSigningIn ? 'Signing In...' : 'Sign In'}
                </button>

                {error && <p className="error-message">{error}</p>}

                <p className='p-font-nav'>
                    Don't have an account?{' '}
                    <span
                        className='sign-span-nav'
                        onClick={handleSignUpClick}
                    >
                        Sign Up
                    </span>
                </p>
            </div>
        </div>
    );
};

export default SignInNavbar;
