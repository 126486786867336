import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import CloseIcon from '@mui/icons-material/Close';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BackButton } from './components/Buttons/BackButton';
import { TimeButton } from "./components/Buttons/TimeButton";
import { DesktopContainer } from './components/DesktopContainer';
import Scoreboard from './components/Scoreboard';
import "./placeView.css";
import './voting.css';
import getUrl from './LambdaUrls';

function VotingPlaceView() {

    const { placeID } = useParams();
    const [cplace, setPlace] = useState({
        placename: "",
        ptype: "",
        selectiontype: "",
        address: "",
        imagepath: [],
        description: "",
        suggestion: "",
        directionslink: "",
    });
    // this helps avoid issues with undefined state

    const [score, setScore] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [images, setImages] = useState([]);
    const [isUserSignedIn, setIsUserSignedIn] = useState(false);
    const [isPlaceModalOpen, setIsPlaceModalOpen] = useState(false);
    const [uploadStatus, setUploadStatus] = useState(null);
    const [isCheckInOpen, setIsCheckInOpen] = useState(true);
    const [checkInStep, setCheckInStep] = useState('location-checkin');
    const [yesVotes, setYesVotes] = useState(0);
    const [noVotes, setNoVotes] = useState(0);
    const [lastVote, setLastVote] = useState(0);

    const initialstate = images
    //update time
    const [show, setShow] = useState(false);
    const [isOpen, setIsOpen] = useState(true);
    //handle clicking btwn carousels 
    const handleNext = () => {
        let nextIndex = currentIndex + 1;
        if (nextIndex === images.length) {
            nextIndex = 0
        }
        setCurrentIndex(nextIndex);
    };


    useEffect(() => {
        fetchData();

        return () => {
            document.body.classList.remove('placeview-page');
        };
    }, []);


    const fetchData = () => {
        console.log('TEST', localStorage.getItem("place"))
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/plain");
        var raw = JSON.stringify({ "PlaceID": placeID, "email": localStorage.getItem('email') });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(getUrl("postPlace"), requestOptions)
            .then(response => response.text())
            .then(result => {
                setPlace(JSON.parse(result));
                var tmp = JSON.parse(result);
                if (tmp.approval_status === true) { navigate(`/placeview/${tmp.placename}/${tmp.placeid}`); }
                var imagez = [...JSON.parse(result).imagepath].reverse()
                imagez.push("https://placehold.co/150x150/FDF5EA/FDF5EA")
                setYesVotes(JSON.parse(result).yes_votes);
                setNoVotes(JSON.parse(result).no_votes);
                setImages(imagez);
            })
            .catch(error => console.log('error', error));
    }
    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/plain");

        var raw = JSON.stringify({ "PlaceID": placeID, "email": localStorage.getItem('email') });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        var Userscore = 0
        fetch(getUrl("postScore"), requestOptions)
            .then(response => response.text())
            .then(result => setScore(JSON.parse(result)[0][0]))
            .catch(error => console.log('error', error));
        checkUserSignedIn();
    }, []);

    const checkUserSignedIn = async () => {
        try {
            await Auth.currentAuthenticatedUser();
            setIsUserSignedIn(true);
            console.log("signed in")
        } catch (error) {
            setIsUserSignedIn(false);
            console.log("notsignedin")
        }
    };

    const handlePrev = () => {
        let previousIndex = currentIndex - 1;
        if (previousIndex < 0) {
            previousIndex = images.length - 1;
        }
        setCurrentIndex(previousIndex);
    };

    //navigation
    let navigate = useNavigate();

    const handleIconClick = () => {
        setShow(!show);
    };

    //uploading picture option 
    const handleImageUpload = (event) => {
        if (isUserSignedIn) {
            const file = event.target.files[0];
            console.log("File type:", file.type);
            const contentType = file.type;

            // Create a FormData object to hold the image file
            const formData = new FormData();
            formData.append('image', file, file.name);
            const randomNum = Math.floor(10000000 + Math.random() * 90000000);
            const datetime = new Date();
            const dateString = datetime.toJSON();
            // Make the fetch request to upload the image
            fetch(`${getUrl('s3upload')}${placeID}---1000---${localStorage.getItem("email")}---${dateString}.jpg`, {
                method: 'PUT',
                headers: {
                    'Content-Type': contentType,
                },
                body: file,
            })
                .then((res) => {
                    console.log('RES', res);

                    setUploadStatus("Thanks for adding a picture, it's in the process of being approved");

                    setTimeout(() => {
                        setUploadStatus(null);
                    }, 60000);
                })
                .catch((err) => {
                    console.log(err)
                });

            // Update the score using fetch
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "text/plain");
            myHeaders.append("Authorization", String(localStorage.getItem('accessToken')));
            const requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: `{\r\n  \"PlaceID\": \"${placeID}\",\r\n  \"imagePath": \"/images/${placeID}---1000---${localStorage.getItem('email')}---${dateString}.jpg\"\r\n}`,
                redirect: 'follow',
            };
            fetch(getUrl('updateScore'), requestOptions)
                .then(response => response.text())
                .then((result) => {
                    console.log(result);
                    // Refresh the page after both image upload and score update
                    setScore(score + 1000)
                })
                .catch(error => console.log('error', error));
        } else {
            navigate('/signin'); // navigate to /signin if the user is not signed in
        }
    };


    // const isLastImage = currentIndex === images.length - 1;
    const isLastImage = currentIndex === images.length - 1;
    const renderUploadButton = isLastImage && isUserSignedIn;

    //click count for directions
    function handleDirectionsClick() {
        //window.location.href = cplace.directionslink;
        navigate(`/map/${cplace.placename}/${cplace.placeid}`);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/plain");
        var raw = JSON.stringify({ "PlaceID": cplace.placeid });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(getUrl("addClick"), requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }


    useEffect(() => {
        // get the current day of the week (0 for Sunday, 1 for Monday, and so on)
        const currentDayOfWeek = new Date().getDay();

        if (cplace.opening_hours && cplace.opening_hours.length > currentDayOfWeek) {
            const openingHours = cplace.opening_hours[currentDayOfWeek];
            console.log("WE WANT TO LOOK AT THIS", openingHours.open)
            setIsOpen(
                openingHours.open !== 'Closed' &&
                isTimeBetween(new Date(), timeStringToDate(openingHours.open), timeStringToDate(openingHours.close))
            );
            console.log('TEST TIME', openingHours.open)
        }
    }, [cplace]);

    function timeStringToDate(timeString) {
        const [hours, minutes] = timeString.split(':').map(Number);
        const date = new Date();
        date.setHours(hours, minutes, 0, 0);
        return date;
    }
    // helper function to convert time to 24-hour format
    function convertTo24HourFormat(time) {
        const [hours, minutes, period] = time.split(/:| /);
        let hours24 = parseInt(hours, 10);
        console.log('HOURS24', hours24);

        if (period === 'PM' && hours24 < 12) {
            hours24 += 12;
        }

        if (period === 'AM' && hours24 === 12) {
            hours24 = 0;
        }

        return `${hours24.toString().padStart(2, '0')}:${minutes}`;
    }

    // helper function to check if current time is between start and end time
    function isTimeBetween(currentTime, startTime, endTime) {
        console.log('CURRENT TIME', currentTime)
        console.log('START TIME', startTime)
        console.log('END TIME', endTime)
        if (startTime <= endTime) {
            return currentTime >= startTime && currentTime <= endTime;
        } else {
            return currentTime >= startTime || currentTime <= endTime;
        }
    }

    const [isOverlayVisible, setIsOverlayVisible] = useState(false);

    const handleModalClose = () => {
        setIsPlaceModalOpen(false);
        setIsOverlayVisible(false); // hide the grey overlay when the modal is closed
    };

    function formatTime(time) {
        const [hours, minutes] = time.split(":");
        let formattedTime = `${parseInt(hours, 10) % 12}:${minutes}`;

        if (hours >= 12) {
            formattedTime += " PM";
        } else {
            formattedTime += " AM";
        }

        return formattedTime;
    }

    function handleVote(vote) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/plain");
        myHeaders.append('Authorization', localStorage.getItem('accessToken'))
        var raw = JSON.stringify({ "PlaceID": cplace.placeid, "email": localStorage.getItem('email'), "vote": vote });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(getUrl('postVote'), requestOptions)
            .then(response => response.text())
            .then(result => { console.log(result); })
            .catch(error => console.log('error', error));
    }


    const handleYesVote = () => {
        if (!isUserSignedIn) { console.log('isUserSignedIn', isUserSignedIn); navigate('/signin'); }
		else {
			const VOTED = `hasVoted_${cplace.placeid}`;
			console.log('VOTED', VOTED)

			if (localStorage.getItem(VOTED) !== 'yes') { //localstorage does not contain VOTED = user has not voted
				handleVote("yes"); //submit yes vote
				// 0 = no vote ; 1 = YES ; 2 = NO
				if (localStorage.getItem(VOTED) === 'no') { //check if user has previously voted 'NO' - make sure to update in localstorage
					setLastVote(1); // adjust the yes vote because the user change from NO to YES
					setYesVotes(yesVotes + 1); // update the yes to 1
					setNoVotes(noVotes - 1); // update the no to -1 because user revote to YES
				} else if (lastVote === 0) { // user has not vote
					setLastVote(1); // set to YES vote
					setYesVotes(yesVotes + 1); // update
				}
				// set a flag in localstorage shows user voted
				localStorage.setItem(VOTED, 'yes'); // set the VOTED to the to 'yes'
			} else {
				console.log("You have already voted");
			}
		}
    };

    const handleNoVote = () => {
        if (!isUserSignedIn) { console.log('isUserSignedIn', isUserSignedIn); navigate('/signin'); }
        else {
			const VOTED = `hasVoted_${cplace.placeid}`;

			if (localStorage.getItem(VOTED) !== 'no') {
				handleVote('no');
				if (localStorage.getItem(VOTED) === 'yes') {
					setLastVote(2);
					setYesVotes(yesVotes - 1);
					setNoVotes(noVotes + 1);
				} else if (lastVote === 0) {
					setLastVote(2);
					setNoVotes(noVotes + 1);
				}
				localStorage.setItem(VOTED, 'no');
			} else {
				console.log("You have already voted");
			}
		}
    }


    return (
        <div>
            <DesktopContainer />
            <div className="page-container mobile-container">
                {isOverlayVisible && <div className="overlaypage" />}
                {uploadStatus && <div style={{ paddingTop: '5px' }}>{uploadStatus}</div>}
                {/* the top where shows time and score */}
                <div className="top-option-voting" >
                    {/* back to home*/}
                    <BackButton onClick={() => navigate("/voting")} />
                    <Scoreboard onClick={() => navigate('/redeem')} />
                    {/* <AccessTimeIcon className={`icon ${isOpen ? 'green' : 'red'}`} onClick={handleIconClick} /> */}
                    <TimeButton style={{ backgroundColor: isOpen ? 'rgb(141, 173, 89)' : 'rgb(219, 110, 101)' }} onClick={handleIconClick} />

                </div>

                {show && (
                    <div className="time-table">
                        <div className="time-table-content">
                            <BackButton style={{ position: 'absolute', top: '40px', left: '30px' }} className='close-time-table' onClick={() => setShow(false)} />
                            <table className="date-time-table">
                                <thead>
                                    <tr className="date-time-tr">
                                        <th>Day</th>
                                        <th>Time</th>
                                    </tr>
                                </thead>
                                <tbody className="date-time-tbody">
                                    {cplace.opening_hours.map((dayHours) => (
                                        <tr key={dayHours.day}>
                                            <td>{dayHours.day}</td>
                                            <td>
                                                {dayHours.open === "" || dayHours.close === ""
                                                    ? "Closed"
                                                    : `${formatTime(dayHours.open)} - ${formatTime(dayHours.close)}`}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}


                {/* place info */}


                <h1 className={`h1-tag h1-voting${cplace.placename.length > 15 ? ' height-adjust' : ''}`} >{cplace.placename}</h1>
                <h2 className="h2-types h2-voting">{cplace.selectiontype.charAt(0).toUpperCase() + cplace.selectiontype.slice(1)}</h2>
                <p className="add-picture-note">Add <br></br>picture</p>
                
                <div className="image-container">
                    {images.map((imageUrl, index) => (
                        <img
                            key={index}
                            src={imageUrl.charAt(0) === '/' ? getUrl('s3imagePath') + imageUrl : imageUrl}
                            alt="Gallery"
                            className="image-con ic-voting"
                            style={{
                                display: imageUrl === images[currentIndex] ? "block" : "none",
                                objectFit: "cover",
                                borderRadius: "15px",
                                border: '5px solid white',
                                boxShadow: '0px 4px 3px 1px rgba(220, 154, 87, 0.6)',
                                marginBottom: '10px'
                            }}
                        />
                    ))}


                    {renderUploadButton ? (
                        <>
                            <label htmlFor="fileInput">
                                <button
                                    style={{
                                        height: "30vh",
                                        width: '65vw',
                                        cursor: "pointer",
                                        fontSize: '16px',
                                        border: 'none',
                                        borderRadius: '10px',
                                        // boxShadow: '0px -5px 0px 0px rgba(0, 0, 0, 0.2) inset',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%',
                                        zIndex: 10,
                                        // backgroundColor: 'rgb(135, 159, 207)',
                                        backgroundColor: "#fdf5ea",
                                        color: 'rgb(135, 159, 207)',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignContent: 'center',
                                        alignItems: 'center',
                                        paddingTop: '5vh'
                                    }}

                                    onClick={() => { document.getElementById('fileInput').click() }}
                                >
                                    <img src="/assets/picture.png" alt="picture-icon" style={{ width: '18vw' }} />
                                    <h3 style={{ fontSize: '5.5vw' }}>Add Pictures <br /> 1000 Points</h3>
                                </button>
                            </label>
                            <input
                                type="file"
                                id="fileInput"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={handleImageUpload}
                            />
                        </>
                    ) : (
                        <>
                            {isLastImage && (
                                <label htmlFor="fileInput">
                                        <button
                                            style={{
                                                height: "30vh",
                                                width: '65vw',
                                                cursor: "pointer",
                                                fontSize: '16px',
                                                border: 'none',
                                                borderRadius: '10px',
                                                // boxShadow: '0px -5px 0px 0px rgba(0, 0, 0, 0.2) inset',
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%',
                                                zIndex: 10,
                                                // backgroundColor: 'rgb(135, 159, 207)',
                                                backgroundColor: "#fdf5ea",
                                                color: 'rgb(135, 159, 207)',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignContent: 'center',
                                                alignItems: 'center',
                                                paddingTop: '5vh'
                                            }}

                                            onClick={() => navigate('/signin')}
                                        >
                                            <img src="/assets/picture.png" alt="picture-icon" style={{ width: '18vw' }} />
                                            <h3 style={{ fontSize: '5.5vw' }}>Add Pictures <br /> 1000 Points</h3>
                                        </button>
                                </label>)}
                            <input
                                type="file"
                                id="fileInput"
                                accept="image/*"
                                style={{ display: "none" }}
                            />
                        </>
                    )}
                </div>

                {/* handle clicking for pictures */}
                <button onClick={(handlePrev)} className="icons left" >
                    <ChevronLeft style={{ transform: 'scale(1.5)', marginTop: '8px', marginLeft: '-8px' }} />
                </button>
                <button onClick={handleNext} className="icons right" >
                    <ChevronRight style={{ transform: 'scale(1.5)', marginTop: '8px', marginLeft: '-8px' }} />
                </button>


                <div className="dots">
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "0.5rem", marginLeft: '0vw' }}>
                        {images.map((slide, image) => (
                            <div className="image-dots"
                                style={{
                                    transition: "all 0.2s",
                                    width: "0.75rem",
                                    height: "0.75rem",
                                    backgroundColor: currentIndex === image ? "white" : "#77320A",
                                    borderRadius: "50%",
                                    padding: currentIndex === image ? "0.25rem" : "0",
                                    opacity: currentIndex === image ? "1" : "0.5",
                                    cursor: 'pointer',
                                    marginBottom: '2vh',
                                    boxShadow: currentIndex === image ? "0px 3px 3px 1px rgba(198, 126, 40, 0.6)" : "none"
                                }}
                                onClick={() => setCurrentIndex(image)}
                            />
                        ))}

                    </div>
                    <h1 className="vote-now">Vote for 2000 PTS</h1>
                    <p className="vote-now-msg">Is this a Hidden Gem?</p>
                    {!isUserSignedIn && (<p className="vote-now-msg">Sign In to vote for 2000pts</p>)}

                    <div className="yes-no-opt">
                        <button className="yes-no-btn yes" onClick={handleYesVote}>Yes [{yesVotes}]</button>
                        <button className="yes-no-btn no" onClick={handleNoVote}>No [{noVotes}]</button>
                    </div>
                </div>
                <div>
                </div>
                <button className="directions" onClick={handleDirectionsClick} style={{ fontSize: '20px', fontWeight: 'bold' }}>
                    Directions
                </button>

            </div>
            {/* checkin popup */}
            {isPlaceModalOpen && isCheckInOpen && isOverlayVisible && (
                <div className="checkin">
                    {checkInStep === 'location-checkin' ? (
                        <div className="checkin-content location-checkin">
                            <div className="checkin-container">
                                <div className="close-icon">
                                    <CloseIcon onClick={handleModalClose} style={{ color: 'white' }} />
                                </div>
                                <h2 className="location-check" >Are you at <span style={{ textTransform: 'capitalize' }}>{cplace.placename}</span></h2>
                                <h2 className="checkin-points">Check in for 1000 points!</h2>
                                <button
                                    className="checkin-btn-PV"
                                    onClick={() => {
                                        setCheckInStep('image-upload');
                                    }}
                                >
                                    Check In
                                </button>
                                <div
                                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', fontSize: '18px', cursor: 'pointer' }}
                                    className="skipdiv"
                                    onClick={handleModalClose}
                                >
                                    Skip
                                    <KeyboardDoubleArrowRightIcon style={{ transform: 'scale(1.2)' }} />
                                </div>
                            </div>
                        </div>

                    ) : (
                        <div className="checkin-container image-upload">
                            <div className="close-icon">
                                <CloseIcon onClick={handleModalClose} style={{ color: 'white' }} />
                            </div>
                            <div>
                                <label htmlFor="fileInput">
                                    <button
                                        className="image-placehold"
                                        onClick={() => { document.getElementById('fileInput').click() }}
                                    >
                                        <img src="./assets/picture.png" alt="picture-icon" style={{ width: '18vw' }} />
                                        <div style={{ fontSize: '5.5vw', marginTop: '1vh' }}>Add Pictures <br /><div className="s-500-points"> 500 Points</div></div>
                                    </button>
                                </label>
                                <input
                                    type="file"
                                    id="fileInput"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={handleImageUpload}
                                />
                                <div
                                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', fontSize: '18px', cursor: 'pointer' }}
                                    className="skipdiv"
                                    onClick={handleModalClose}
                                >
                                    Skip
                                    <KeyboardDoubleArrowRightIcon style={{ transform: 'scale(1.2)' }} />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default VotingPlaceView;
