import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import './verification.css';
import { DesktopContainer } from './DesktopContainer';

const VerificationCode = ({ email, password }) => {
    const navigate = useNavigate();
    const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', '']);
    const [error, setError] = useState('');
    const [isVerifying, setIsVerifying] = useState(false);
    const inputRefs = useRef([]);


    const handleVerification = async () => {
        if (isVerifying) {
            return;
        }

        try {
            setIsVerifying(true);
            const code = verificationCode.join('');
            console.log('EMAIL', email);
            console.log('LOCALSTORAGE', localStorage.getItem('email'));
            await Auth.confirmSignUp(localStorage.getItem('email'), code);
            localStorage.removeItem(email);
            navigate('/signin');
        } catch (error) {
            setError('Verification code is invalid');
            setVerificationCode(['', '', '', '', '', '']);
        } finally {
            setIsVerifying(false);
        }
    };

    const handleResendCode = async () => {
        if (isVerifying) {
            return;
        }

        try {
            setIsVerifying(true);
            await Auth.resendSignUp(localStorage.getItem('email'));
            console.log('Verification code resent');
        } catch (error) {
            setError(error.message);
        } finally {
            setIsVerifying(false);
        }
    };

    const handleInputChange = (index, value) => {
        const updatedVerificationCode = [...verificationCode];
        updatedVerificationCode[index] = value;
        setVerificationCode(updatedVerificationCode);

        if (value && inputRefs.current[index + 1]) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handlePaste = (event) => {
        event.preventDefault();
        const pastedData = event.clipboardData.getData('text/plain');
        const pastedCode = pastedData.slice(0, 6);
        const updatedVerificationCode = [...verificationCode];

        for (let i = 0; i < pastedCode.length; i++) {
            if (inputRefs.current[i]) {
                updatedVerificationCode[i] = pastedCode[i];
            }
        }

        setVerificationCode(updatedVerificationCode);
    };

    return (
        <div>
            <DesktopContainer />
            <div className="verification-container mobile-container">
                <div className='top-field'>
                    <h2 className="verification-title">Enter your <br /> verification code</h2>
                    <p className="verification-email">A code was sent to <span className='email-span'>{localStorage.getItem('email')}</span></p>
                </div>
                <div className="verification-input-container">
                    {verificationCode.map((digit, index) => (
                        <input
                            key={index}
                            type="text"
                            className="verification-input"
                            maxLength={1}
                            value={digit}
                            onChange={(e) => handleInputChange(index, e.target.value)}
                            ref={(ref) => (inputRefs.current[index] = ref)}
                            onPaste={handlePaste}
                            inputMode="numeric" 
                        />
                    ))}
                </div>
                <div className="verification-buttons">
                    <button
                        className="verification-button"
                        onClick={handleVerification}
                        disabled={isVerifying}
                    >
                        {isVerifying ? 'Verifying...' : 'Verify'}
                    </button>
                    <button
                        className="resend-button"
                        onClick={handleResendCode}
                        disabled={isVerifying}
                    >
                        Resend Code
                    </button>
                </div>
                {error && <p className="verification-error">{error}</p>}
            </div>
        </div>
    );
};

export default VerificationCode;
