import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import './signup.css';
import { BackButton } from './Buttons/BackButton';
import { DesktopContainer } from './DesktopContainer';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const SignUpPage = (props) => {
    const setIsSignedUp = props.setIsSignedUp;
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isSigningUp, setIsSigningUp] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const validatePassword = (password) => {
        const regexLowercase = /^(?=.*[a-z])/;
        const regexUppercase = /^(?=.*[A-Z])/;
        const regexNumber = /^(?=.*[0-9])/;
        const regexSpecialChar = /^(?=.*[!@#$%^&*])/;

        let validationErrors = [];

        if (!regexLowercase.test(password)) {
            validationErrors.push('Password must contain at least one lowercase letter.');
        }

        if (!regexUppercase.test(password)) {
            validationErrors.push('Password must contain at least one uppercase letter.');
        }

        if (!regexNumber.test(password)) {
            validationErrors.push('Password must contain at least one number.');
        }

        if (!regexSpecialChar.test(password)) {
            validationErrors.push('Password must contain at least one special character.');
        }

        return validationErrors;
    };

    const handleSignUp = async () => {
        if (isSigningUp) {
            return; // prevent multiple sign-up attempts
        }

        const passwordErrors = validatePassword(password);
        if (passwordErrors.length > 0) {
            setError(passwordErrors.join(' '));
            return;
        }

        try {
            setIsSigningUp(true);
            await Auth.signUp({
                username: email,
                password: password,
            });
            localStorage.setItem('email', email);
            setIsSignedUp(true);
            navigate('/verification'); // redirect to VerificationCode page after successful sign-up
        } catch (error) {
            setError(error.message);
        } finally {
            setIsSigningUp(false);
        }
    };

    return (
        <div>
            <DesktopContainer />
            <div className="signup-container mobile-container">
                <BackButton onClick={() => navigate("/")} />
                <div className="top-container">
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: "column", marginTop: '-3vh' }}>
                        <div className="logo-container">
                            <img src="../assets/logo2.png" alt="logo" className='logopic' />
                        </div>
                        <h2 className="signup-heading" >Hidden Gemz</h2>
                    </div>
                </div>
                <label className='form-label'>Email</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="input-field"
                />
                <label className='form-label'>Password</label>
                <div className="password-field">
                    <div className="input-container">
                        <input
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="input-field"
                        />
                        <RemoveRedEyeIcon onClick={() => setShowPassword(!showPassword)} className="show-password-icon" />
                    </div>
                </div>

                {/* <p className='forgot-password'>Forget password</p> */}

                <button
                    onClick={handleSignUp}
                    disabled={isSigningUp}
                    className={`signup-button ${isSigningUp ? 'loading' : ''}`}
                >
                    {isSigningUp ? 'Signing Up...' : 'Sign Up'}
                </button>

                {error && <p className="error-message">{error}</p>}

            </div>
        </div>
    );
};

export default SignUpPage;
